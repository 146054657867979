import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Tooltip } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import LogoutIcon from '@mui/icons-material/Logout';
import { UseContextState } from "../../global/GlobalContext/GlobalContext";
import palette from 'src/theme/palette';
import SaveAndCancelModal from 'src/global/Modals/SaveAndCancelModal';
import imageImport from 'src/utils/imageImport';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Iconify from 'src/components/Iconify';
import { editable_config } from 'src/editable_config';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [ openDeleteConfimModal, setOpenDeleteConfimModal ] = useState(false)
  const [ buttonLoading, setButtonLoading ] = useState(false)
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const {authState,logoutAuthUser} = UseContextState()
  const navigate = useNavigate()
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

const handleLogout=async()=>{
  setButtonLoading(true)
  await logoutAuthUser()
  setButtonLoading(false)
}

  //############################# SAVE AND CANCEL MODAL FUNCTION #############################
const handleCloseSaveAndCancelModal=()=>{
  setOpenDeleteConfimModal(false); 
  setOpen(null)
  // setIsOpen2(false)
}

const handleOpentConfirmModal=()=>{
  setOpenDeleteConfimModal(true)
}

//############################# SAVE AND CANCEL MODAL FUNCTION #############################

  return (
    <>
     {/*=============== SAVE AND CANCEL MODAL============= */}
     <SaveAndCancelModal open={openDeleteConfimModal} title="Logout User" cancelBtnName='Cancel' saveBtnName='Logout' onYes={handleLogout} loading={buttonLoading} message="Are you sure you want to logout from adiogent?" handleClose={handleCloseSaveAndCancelModal}  />
     {/* <SaveAndCancelModal open={openDeleteConfimModal} title="Tussi Ja Rhe Ho?" cancelBtnName='Nhi, Jaunga' saveBtnName='Mai, Jaunga' onYes={handleLogout} loading={buttonLoading} message="Tussi Sachi Muchi Ja Rhe Ho?" handleClose={handleCloseSaveAndCancelModal}  /> */}

{/*=============== SAVE AND CANCEL MODAL============= */}
<Tooltip title='More'arrow >
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar style={{width:'40px',height:'40px'}}  src={imageImport.useravtarImg} alt="adiogent user" />
      </IconButton>
    </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography style={{textTransform:'capitalize'}} variant="subtitle2" noWrap>
            {authState?.user?.app_name}
          </Typography>
          <Typography variant="body2" sx={{ fontSize:12,color: 'text.secondary' }} noWrap>
            {authState?.user?.app_id}
          </Typography>
          <Typography variant="body2" sx={{ fontSize:12,color: 'text.secondary' }} noWrap>
            Reg.No.  -  +{authState?.user?.phone_number}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

          {/* <MenuItem sx={{color: 'text.secondary',my:1,mx:0.5 }}  >R-No. - +{authState?.user?.phone_number} </MenuItem>  */}
          {/* <MenuItem  onClick={()=>{navigate('/dashboard/account-details');handleClose()}} sx={{color: 'text.secondary',my:1,mx:0.5}} ><Iconify sx={{ width:20,height:20,marginRight:0.8}} icon="bx:message-square-detail" /> Account Details </MenuItem> */}
          <MenuItem  onClick={()=>{navigate('/dashboard/payment-history');handleClose()}} sx={{color: 'text.secondary',my:1,mx:0.5}} ><Iconify sx={{ width:20,height:20,marginRight:0.8}} icon="bx:message-square-detail" /> Payment History </MenuItem>
        <a href={editable_config.Contact_Support_Link} target='_blank' ><MenuItem   sx={{color: 'text.secondary',my:1,mx:0.5}} ><Iconify sx={{ width:20,height:20,marginRight:0.8}} icon="bx:support" /> Contact Support </MenuItem></a>


        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ fontSize:12,color: 'text.secondary',m:1 }} onClick={handleOpentConfirmModal} >
        <LogoutIcon className='logout_icon'/> Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
