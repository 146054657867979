import React,{useState,useEffect,useCallback} from "react";
import Radio from "@mui/material/Radio";
import { LoadingButton } from "@mui/lab";
import Typography from "@mui/material/Typography";
import imageImport from "src/utils/imageImport";
import LoadingSpinner from "src/components/Spinner";
import palette from "src/theme/palette";
import Iconify from "src/components/Iconify";
import { UseContextState } from "src/global/GlobalContext/GlobalContext";
import useRazorpay from "react-razorpay";
import axios from "axios";
import SuccessPayment from "./SuccessPayment";
import PopupModal from "src/global/Modals/PopupModal";
import { editable_config } from "src/editable_config";
import { IconButton } from "@mui/material";

function BuyCredit(props) {
    const {authState,fetchAuthuser} = UseContextState();
    const Razorpay = useRazorpay();
    const [ loading, setLoading ] = useState(false)
    const [ openPopupModal, setOpenPopupModal ] = useState(false)
    const [ render, setRender ] = useState(false)
    const [selectedPlan , setSelectedPlan] = useState(authState?.resubmittion_credit_plan_details?.plan_2)
    const [selectedRadioBtn, setSelectedRadioBtn] = React.useState('5');
    const credit_plan = authState?.resubmittion_credit_plan_details;

    console.log("selectedPlan--------",selectedPlan);
    // console.log("selectedRadioBtn--------",selectedRadioBtn);
    const handleChangeMonth = (event) => {
        // console.log(event);
        setSelectedRadioBtn(event.target.value);
        setSelectedPlan(event.target.value === '1' ?
        authState?.resubmittion_credit_plan_details?.plan_1
        :
        event.target.value === '5' ?
        authState?.resubmittion_credit_plan_details?.plan_2
        :0
        )
      };

  //############################# PAYMENT SUCCESS MODAL FUNCTION #############################
  const handleClosePopupModal=()=>{
    setOpenPopupModal(false); 
    // setIsOpen2(false)
    onYesFunction()
  }
const handleOpenPopupModal=()=>{
    setOpenPopupModal(true); 
    // setIsOpen2(false)
  }

const onYesFunction=()=>{
    fetchAuthuser()
}
  //############################# PAYMENT SUCCESS MODAL FUNCTION #############################
//========= HANDLE PAY NOW BUTTON CLICK ==========
const handleSubmitAndCheckout = useCallback(async(choosedPlan) => {
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/calulate/resubmittion/credit/plan/price/for/razorpay/payment`,{selected_plan:choosedPlan},{headers: {
          'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
        },withCredentials:true})
        .then(res=>{
            // console.log(res?.data)
            if(res?.data?.status == true){
            setLoading(false)
            const options = {
            key:`${res?.data?.razorpay_key_id}`,
            amount: res?.data?.response.amount,
            currency: res?.data?.response.currency,
            name: "Adiogent Pvt Ltd",
            description: "Adiogent Transaction",
            // image: "https://example.com/your_logo",
            order_id: res?.data?.response.id,
            handler: async(razorpayData) => {
            //   console.log("resresresresresresresresres",razorpayData);
            setLoading(true)
              await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/razorpay/payment/for/resubmittion/credit/plan/purchased/from/adiogent/success/${authState?.user?.app_id}`,{...razorpayData,...res?.data?.buying_plan},{headers: {
                'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
              },withCredentials:true})
              .then(res=>{
                console.log(res?.data)
                if(res?.data?.status ===true){
                    setLoading(false)
                    fetchAuthuser()
                    props.handleClose()
                    props.showConfirmPaymentModal()
                    // handleOpenPopupModal()
                    // setRender(prev=>!prev)
                }
              })
              .catch(err=>{
                console.log(err)
                setLoading(false)
              })
            },
            prefill: {
              name: "",
              email: authState?.user?.email,
              contact: authState?.user?.phone_number,
            },
            theme: {
              color: palette.primary.main,
            },
          };
      
          const rzpay = new Razorpay(options);
          rzpay.open()
            }
        })
        .catch(err=>{
            console.log(err);
            setLoading(false)
        })
    
    
      }, [Razorpay]);
//========= HANDLE PAY NOW BUTTON CLICK ==========


  return (
    <div>
      {/* <div className="text-algin-center">
        <Typography
          id="modal-modal-title"
          style={{ fontSize: 20 }}
          variant="h6"
          component="h2"
        >
          Buy More Credit
        </Typography>
      </div> */}
         {/*===== LOADING ====== */}
         <LoadingSpinner loading={loading} />
        {/*===== LOADING ====== */}
        {/*===== Popup Modal ====== */}
        {/* <PopupModal handleClose={handleClosePopupModal}open={openPopupModal} data={<SuccessPayment onYes={onYesFunction} confirmBtnName='Go To Dashboard' title='Congratulations!! ' message='Thanks, your plan has been upgraded succesfully!!'  />} /> */}
        {/*===== Popup Modal ====== */}
        <IconButton onClick={props.handleClose} style={{color:'text.secondary'}} className='close-upgrade-icon' >
          <Iconify icon="material-symbols:close" />
          </IconButton>
      <div className="choose_plan_features">
        <h4 className="buymore_credit_heading">Your Credit Has Been Expire,<br /> Please  Buy Now </h4>
      </div>
      <div className="buy_credit_period_option">
        <div className="choose_plan_detail">
          <div className="radio_btn_and_plan">
            {/* <input type="radio" /> */}
            <Radio
              size="small"
              checked={selectedRadioBtn === '1'}
              onChange={handleChangeMonth}
              value="1"
              name="radio-buttons"
              inputProps={{ "aria-label": "1" }}
            />
            <p>1  Credit</p>
          </div>
        </div>
        <div className="choose_plan_price">
          <p>
            <sup>₹</sup>{credit_plan?.plan_1?.price}
            <span className="choose_plan_month_number_text"></span>
          </p>
        </div>
      </div>
      <div className="buy_credit_period_option">
        <div className="choose_plan_detail">
          <div className="radio_btn_and_plan">
            {/* <input type="radio" /> */}
            <Radio
              size="small"
              checked={selectedRadioBtn === '5'}
              onChange={handleChangeMonth}
              value="5"
              name="radio-buttons"
              inputProps={{ "aria-label": "5" }}
            />
            <p>5 Credit </p>
          </div>
        </div>
        <div className="choose_plan_price">
          <p>
            <sup>₹</sup>{credit_plan?.plan_2?.price}
            <span className="choose_plan_month_number_text"></span>
          </p>
        </div>
      </div>
      <div className="buy_more_credit_total_price">
      <div className='prices_and_plan_detail plan_discount'>
                                <p>Sub Total</p>
                                <p><sup>₹</sup>
                                {selectedPlan?.price}
                                
                             </p>
                            </div>
      <div className='prices_and_plan_detail plan_discount'>
                                <p>Taxes & Fees </p>
                                <p><sup>₹</sup>
                                { selectedRadioBtn === '1' ? 
                                    Math.round((parseInt(selectedPlan?.price))  *  (credit_plan?.gst / 100) )
                                    :
                                    selectedRadioBtn === '5' ?
                                    Math.round((parseInt(selectedPlan?.price))  * (credit_plan?.gst / 100))
                                    : 0}
                                </p>
                            </div>
        <div className='prices_and_plan_detail plan_price_with_month final_total_price'>
                                <p>Total</p>
                                <p><sup>₹</sup>
                                {/*============ TOTAL PRICE WITH GST============ */}
                                {
                                selectedRadioBtn === '1' ? 
                                parseInt(selectedPlan?.price)  +  Math.round((parseInt(selectedPlan?.price))  *  (credit_plan?.gst / 100) )
                                :
                                selectedRadioBtn === '5' ?
                                parseInt(selectedPlan?.price) +  Math.round((parseInt(selectedPlan?.price))  * (credit_plan?.gst / 100))
                                : 0
                                
                               }
                                {/*============ TOTAL PRICE WITH GST============ */}
                                
                                </p>
                     </div>
                     <div className='read_term_and_condition_para'>
                                <div className='term_and_condition_checkbox'>
                                {/* <input type="checkbox" /> */}
                                </div>
                                {/* <p>By checking out you agree with our <span style={{color:palette.primary.main}} >Terms of Service</span>. We will process your personal data for the fulfillment of your order and other purposes as per our <span style={{color:palette.primary.main}} >Privacy Policy</span>.</p> */}
                                <p>By checking out you agree with our <a href={editable_config.TermAndServiceLink} target='_blank' ><span style={{color:palette.primary.main}} >Terms of Service</span></a>. We will process your personal data for the fulfillment of your order and other purposes as per our <a href={editable_config.Privacy_Policy_Link} target='_blank' ><span style={{color:palette.primary.main}} >Privacy Policy</span>.</a></p>

                            </div>
      </div>
      
      <div className="text-algin-center confirm-button-popup ">
        {/* <Button variant='text' onClick={props.handleClose}  >{props.cancelBtnName}</Button> */}
        <LoadingButton
        fullWidth
          onClick={()=>handleSubmitAndCheckout(selectedPlan)}
          loading={props.loading}
          loadingPosition="start"
          startIcon={<Iconify icon="wpf:security-checked" />}
          variant="contained"
          style={{ padding: "13px 20px" }}
        >
          <span>Submit Secure Payment</span>
        </LoadingButton>
      </div>
    </div>
  );
}

export default BuyCredit;
