import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, IconButton } from '@mui/material';
import { Card,Box, CardHeader,FormControl,TextField,InputAdornment,Menu,Button,ClickAwayListener } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { fShortenNumber } from 'src/utils/formatNumber';
// components
import Page from '../components/Page';
import {Paper} from '@mui/material';
import Iconify from '../components/Iconify';
// sections
import palette from 'src/theme/palette';
import { editable_config } from 'src/editable_config';
import VideoModal from 'src/global/Modals/VideoModal';
import LoadingSpinner from 'src/components/Spinner';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import SaveAndCancelModal from 'src/global/Modals/SaveAndCancelModal';
import PopupModal from 'src/global/Modals/PopupModal';
import SuccessPayment from './modal_components/SuccessPayment';
import imageImport from 'src/utils/imageImport';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

// ----------------------------------------------------------------------

export default function Plugins() {
  const [loading,setLoading]=useState(false)
  const [render,setRender]=useState(false)
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [ openDeleteConfimModal, setOpenDeleteConfimModal ] = useState(false)
  const [ openSuccessContactSupportModal, setopenSuccessContactSupportModal ] = useState(false)
  const [ buttonLoading, setButtonLoading ] = useState(false)
  const [ selectService, setselectService ] = useState('')
  const [valueRadio, setValueRadio] = React.useState('all');
  const [showInstalledPlugin, setShowInstalledPlugin] = useState(false)
  const [usersPluginsData, setUsersPluginsData] = useState(false)
  const {authState} = UseContextState()
  const navigate = useNavigate()



  useEffect(()=>{
    setLoading(true)
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/get/all/plugins/details/${authState?.user?.app_id}`,{headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log('all plugins->',res?.data)
      setUsersPluginsData(res?.data?.all_plugins)
    setLoading(false)

    })
    .catch(err=>{
      console.log(err)
    })
  },[render])

  const handleChangeRadioBtn = (event) => {
    setValueRadio(event.target.value);
  };
 
const pluginData = [
  {_id:1,comingsoon:false,route:'/dashboard/plugins/razorpay-payments',category:'payments',is_installed:usersPluginsData?.razorpay_is_installed ,name:'Razorpay Payments',small_desc:'Razorpay is the top payments solution in India that allows businesses to accept online payments.',logo:imageImport?.plugin_2},
  {_id:2,comingsoon:false,route:'/dashboard/plugins/shiprocket',category:'shipping',is_installed:usersPluginsData?.shiprocket_plugin?.is_installed ,name:'shiprocket',small_desc:'Ship your orders faster and cheaper with the best ecommerce shipping solution in India.',logo:imageImport?.plugin_1},
  {_id:3,comingsoon:true,route:'/dashboard/plugins/details',category:'store management',is_installed:false ,name:"Shopify Importer",small_desc:"Shopify importer plugin helps you to import your Shopify store products to Adiogent.",logo:imageImport.plugin_3},
  {_id:4,comingsoon:true,route:'/dashboard/plugins/details',category:'store management',is_installed:false ,name:'WooCommerce Importer',small_desc:'WooCommerce importer plugin helps you to Migrate your products from WooCommerce to Adiogent.',logo:imageImport.plugin_4},
  {_id:5,comingsoon:true,route:'/dashboard/plugins/details',category:'store management',is_installed:false ,name:'Gumroad Importer',small_desc:'Gumroad Importer plugin helps you to import your Gumroad store to Adiogent.',logo:imageImport?.plugin_5},
  {_id:6,comingsoon:true,route:'/dashboard/plugins/details',category:'analytics',is_installed:false ,name:'Google analytics',small_desc:'Measure your advertising ROI in real time. And see real time app visitors.',logo:imageImport?.plugin_6},
  {_id:7,comingsoon:true,route:'/dashboard/plugins/details',category:'analytics',is_installed:false ,name:'Facebook Pixels',small_desc:'Measure the results & Better understand the impact of your ads by measuring what happens when people see them.',logo:imageImport?.plugin_7},
  {_id:8,comingsoon:true,route:'/dashboard/plugins/details',category:'shipping',is_installed:false ,name:'Pincode/Distance Based Delivery',small_desc:'Create zones based on Pincode or Distance and set different delivery fees.',logo:imageImport?.plugin_8},
]



  // handle open video modal
  function handleOpenVideoModal(){
    setOpenVideoModal(true)
  }

    // handle close video modal
    function handleCloseVideoModal(){
      setOpenVideoModal(false)
    }
    

 function navigateToSinglePluginPage(path){
  navigate(path)
 } 

  return (
    <Page title='Adiogent Plugins'>

<VideoModal title='Learn how to add your products?' video_url="https://www.youtube.com/embed/IvKHkM17rvQ" isOpen={openVideoModal} handleClose={handleCloseVideoModal} /> 


       <LoadingSpinner loading={loading} />
   <div className='dashboard_heading_box' >
   <Typography variant="h5" className='font-capitalize-case' >
   Usefull Plugins
        </Typography>
        {/* <div className="banner-image-guide-box order_page_video order_view_page_video">
    <Button onClick={handleOpenVideoModal} variant="text" className='image-guide-btn-text' startIcon={<Iconify icon="logos:youtube-icon" />}>Learn How it Works</Button>
   </div> */}
   </div>
   <div className='plugin_page_box' >
<div style={{width:'25%'}} >
<Grid item xs={12} sm={6} md={12}    >
           <Paper elevation={4} >
           <div className='all_installed_plugins_box' >
<p onClick={()=>setShowInstalledPlugin(false)}  className='installed_plugin_tab' style={ showInstalledPlugin ?{borderRadius: '8px 0px 0px 0px',border:`0.5px solid ${palette.primary.main}`,color:palette.primary.main}:  {borderRadius: '8px 0px 0px 0px',border:`0.5px solid ${palette.primary.main}`,backgroundColor:palette.primary.main}  } >All Plugins</p>
            <p onClick={()=>setShowInstalledPlugin(true)}  className='all_plugin_tab'style={showInstalledPlugin ?{borderRadius: '0px 8px 0px 0px',border:`0.5px solid ${palette.primary.main}`,color:'white',backgroundColor:palette.primary.main}:  {borderRadius: '0px 8px 0px 0px',border:`0.5px solid ${palette.primary.main}`,color:palette.primary.main}} >Installed Plugins</p>
</div>
        <div className="plugin_card_main_box">
        <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={valueRadio}
        onChange={handleChangeRadioBtn}
      >
        <FormControlLabel disabled={showInstalledPlugin ? true : false} value="all" control={<Radio />} label="All" />
        <FormControlLabel disabled={showInstalledPlugin ? true : false} value="payments" control={<Radio />} label="Payments" />
        <FormControlLabel disabled={showInstalledPlugin ? true : false} value="shipping" control={<Radio />} label="Shipping" />
        <FormControlLabel disabled={showInstalledPlugin ? true : false} value="analytics" control={<Radio />} label="Analytics" />
        <FormControlLabel disabled={showInstalledPlugin ? true : false} value="store management" control={<Radio />} label="Store Management" />
      </RadioGroup>
    </FormControl>
        </div>
          </Paper>
          </Grid>
</div>

       <div style={{width:'100%'}} >
       <Grid container spacing={2}  >
          
          {pluginData
           ?.filter((value) => {
            if (value?.category.includes(valueRadio) && showInstalledPlugin === false) {
              return value;
            }
            else if (value?.category.includes(valueRadio) && showInstalledPlugin === true) {
              return value;
            }
            else if (valueRadio == 'all'  && value?.is_installed == true) {
              return value;
            }
            else if (valueRadio == 'all' && showInstalledPlugin === false ) {
              return value;
            }
          })
        
          ?.map((value,index)=>(
             <Grid item xs={12} sm={6} md={6} key={index}  >
             <Paper elevation={4} >
          <div className="plugin_card_main_box">
          <div className='plugin_card_box' >
              <img src={value?.logo}
              className='plugin_icon'
              />
             <div className='flex-columns' style={{position:'relative'}} >
             {value?.comingsoon && <div className='plugin_coming_soon_box' >
              <span>Coming Soon</span>
             </div> }
             <Typography variant="subtitle1" sx={{paddingBottom:0.5,textTransform:'capitalize'}} >{value?.name}</Typography>
              <Typography variant="body2"  sx={{ color: 'text.secondary' }} >
              {value?.small_desc}
              </Typography>
             <div className='install_plugin_btn' >
              {value?.is_installed ?

            <Button  onClick={()=>navigateToSinglePluginPage(value?.route)} sx={{px:3.7}}  endIcon={<Iconify icon="ant-design:setting-outlined" />}  variant='outlined' > Settings</Button>
              :
              
            <Button disabled={value?.comingsoon ? true :false }  onClick={()=>navigateToSinglePluginPage(value?.route)}  endIcon={<Iconify icon="material-symbols:download" />}  variant='contained' > Install Plugin</Button>
              }
          </div>
            
             </div>
            </div>
          </div>
            </Paper>
            </Grid>
          ))}
          
          </Grid>

       </div>

   </div>
    </Page>
  );
}
