import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardApp from "../../pages/DashboardApp"
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import UpgradeAccount from 'src/global/Modals/UpgradeAccount';
import RenewPlan from 'src/global/Modals/RenewPlan';
import CustomerLimitExpire from 'src/global/Modals/CustomerLimit';
import MobileViewPopup from 'src/global/Modals/MobileViewPopup';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({Component}) {
  const [open, setOpen] = useState(false);
  const [openUpgradeFromFreePlanModal, setOpenUpgradeFromFreePlanModal] = useState(false);
  const [openRenewPlanModal, setOpenRenewPlanModal] = useState(false);
  const [openCustomerLimitModal, setOpenCustomerLimitModal] = useState(false);
  const {authState} = UseContextState()
  // ======== customers limit var ===========
  const customerCount = authState?.users_customers_count
  const customerLimit = authState?.user?.user_plan_limits?.user_limit
  const customerLeft = customerLimit - customerCount
  // ======== customers limit var ===========
  console.log("customerLimitcustomerLimitcustomerLimitcustomerLimitcustomerLimit",customerCount,customerLimit)


//====== renew plan account var =======
  const planName = authState?.user?.plan_details?.plan_name 
  const date1 = new Date()
  const date2 = new Date(authState?.user?.plan_details?.renew_date)
  const daysLeft = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10) ; 
  // const daysLeft = parseInt(authState?.user?.plan_details?.renew_date) - parseInt(authState?.user?.plan_details?.purchased_date) 
  console.log("daysLeft=>>>>",daysLeft,'planName=>',planName)
//====== renew plan account var =======

  // ------- upgrade from free account modal functions --------
  useEffect(()=>{
    setOpenUpgradeFromFreePlanModal(true)
  },[])
  useEffect(()=>{
    startUpgradeFromFreeTimer()
  },[])
  let timer;
  function startUpgradeFromFreeTimer(){
    if(authState?.user?.plan_details?.plan_name ==='free'){
      timer = setInterval(async()=>{
        console.log("TIME FOR PLAN POP UP")
        await setOpenUpgradeFromFreePlanModal(true)
      },600000) // 5 mins
    }
  }
  // ------- upgrade from free account modal functions --------

// ------- upgrade from free account modal close function --------
function handleClosePlanModal(){
  clearInterval(timer)
  setOpenUpgradeFromFreePlanModal(false)
}
// ------- upgrade account modal close function --------



// ------- renew plan account modal functions --------
useEffect(()=>{
  setOpenRenewPlanModal(true)
},[])
useEffect(()=>{
  startRenewPlanTimer()
},[])
let renewTimer;
function startRenewPlanTimer(){
  if(planName !='free' && daysLeft < 6){
    renewTimer = setInterval(async()=>{
      console.log("TIME FOR RENEW PLAN POP UP")
      await setOpenRenewPlanModal(true)
    },900000) // 5 mins
  }
}
// ------- renew plan account modal functions --------
// ------- renew account modal close function --------
function handleCloseRenewPlanModal(){
  clearInterval(renewTimer)
  setOpenRenewPlanModal(false)
}
// ------- renew account modal close function --------

// ------- customer limit modal functions --------
useEffect(()=>{
  setOpenCustomerLimitModal(true)
},[])
useEffect(()=>{
  startCustomerLimitTimer()
},[])
let customerlimitTimer;
function startCustomerLimitTimer(){
  // if(planName =='free' && customerLimit < customerCount){
  if(planName =='free' && customerCount > customerLimit - 10){
    customerlimitTimer = setInterval(async()=>{
      console.log("TIME FOR CUSTOMER LIMIT POP UP")
      await setOpenCustomerLimitModal(true)
    },300000) // 5 mins
  }
}
// ------- customer limit modal functions --------
// ------- customer limit modal close function --------
function handleCloseCustomerLimitModal(){
  clearInterval(customerlimitTimer)
  setOpenCustomerLimitModal(false)
}
// ------- customer limit modal close function --------


  return (<>
    <RootStyle>
     
      <DashboardNavbar onOpenSidebar={() => setOpen(true)}  authState={authState} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
     
      <MainStyle>
        {/* <Outlet /> */}
      {/* <DashboardApp /> */}
     {/* ======= UPGRADE PLAN FROM FREE MODAL ==== */}
     {/* { <UpgradeAccount isOpen={openUpgradeFromFreePlanModal} handleClose={handleClosePlanModal} />} */}
     {authState?.user?.plan_details?.plan_name ==='free' &&  <UpgradeAccount isOpen={openUpgradeFromFreePlanModal} handleClose={handleClosePlanModal} />}
     {/* ======= UPGRADE PLAN FROM FREE MODAL ==== */}

     {/* ======= PLAN RENEW MODAL ==== */}
     { (planName !='free' && daysLeft < 6) &&  <RenewPlan daysLeft={daysLeft} isOpen={openRenewPlanModal} handleClose={handleCloseRenewPlanModal} /> }
     {/* ======= PLAN RENEW MODAL ==== */}

     {/* ======= CUSTOMER LIMIT EXPIRE FOR FREE ACCOUNT MODAL ==== */}
     {/* { (planName =='free' && customerLimit < customerCount) &&  <CustomerLimitExpire  isOpen={openCustomerLimitModal} handleClose={handleCloseCustomerLimitModal} /> } */}
     { (planName =='free' && customerCount > customerLimit - 10 ) &&  <CustomerLimitExpire customerCount={customerLeft} isOpen={openCustomerLimitModal} handleClose={handleCloseCustomerLimitModal} /> }
     {/* ======= CUSTOMER LIMIT EXPIRE FOR FREE ACCOUNT MODAL ==== */}

{/* mobile view info popup  */}
     <MobileViewPopup  /> 
{/* mobile view info popup  */}


      {Component}
      </MainStyle>
    </RootStyle>
    </>
  );
}
