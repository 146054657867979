import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, IconButton } from '@mui/material';
import { Card,Box, CardHeader,FormControl,TextField,InputAdornment,Menu,Button,ClickAwayListener } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { fShortenNumber } from 'src/utils/formatNumber';
// components
import Page from '../components/Page';
import {Paper} from '@mui/material';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { editable_config } from 'src/editable_config';
import { getGapBetweenDates } from 'src/global/globalFunctions';
import VideoModal from 'src/global/Modals/VideoModal';
import LoadingSpinner from 'src/components/Spinner';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';


// ----------------------------------------------------------------------

export default function Announcements() {
  const [allCounts,setAllCounts]=useState()
  const [loading,setLoading]=useState(false)
  const [render,setRender]=useState(false)
  const [activeUsers,setActiveUsers]=useState([])
  const [activeOrders,setActiveOrders]=useState([])
  const [announcements,setAnnouncements]=useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [filters , setFilters ] = useState({by_status:'all',recentDays:'All'})
  const {authState} = UseContextState()



  useEffect(()=>{
    setLoading(true)
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/all/app/announcements`, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data);
      setAnnouncements([...res?.data?.result]);
    setLoading(false)


    })
    .catch(err=>{
      console.log(err)
    })
  },[render])

  // handle close video modal
  function handleCloseVideoModal(){
    setOpenVideoModal(false)
  }
  

  // handle open video modal
  function handleOpenVideoModal(){
    setOpenVideoModal(true)
  }
  

  return (
    <Page title={editable_config.Admin_Name}>
       <LoadingSpinner loading={loading} />
      <VideoModal title='How to use Dasboard?' video_url="https://www.youtube.com/embed/USccSZnS8MQ" isOpen={openVideoModal} handleClose={handleCloseVideoModal} /> 
      {/* <Container maxWidth="xl"> */}

   <div className='dashboard_heading_box' >
   <Typography variant="h5" className='font-capitalize-case' >
   Announcements & Latest Release's 🎉
        </Typography>
   </div>
        <Grid container spacing={3}>
          {announcements?.map(value=>(
            <Grid item xs={12} sm={6} md={3} key={value?._id} >
            {/* <AppWidgetSummary title="Total Sale" total={allCounts?.total_sales ? allCounts?.total_sales : 0}   icon={'ic:round-currency-rupee'} /> */}
           <Paper elevation={4} >
            <div className='announcement_card_box' >
            <img src={value?.image_url} />
            <Typography variant="subtitle1" sx={{paddingTop:2,paddingBottom:0.5}} >{value?.title?.slice(0,70)}{value?.title?.length > 70 && '...'}</Typography>
            <Typography variant="body2"  sx={{ color: 'text.secondary' }} >{value?.message?.slice(0,80)}{value?.message?.length > 80 && '...'} </Typography>
            <a href={value?.readmore_link} target='_blank' ><Button sx={{ marginTop: 1 }} endIcon={<Iconify icon="solar:arrow-right-linear" />} variant='contained' >Read more</Button></a>
          </div>
          </Paper>
          </Grid>
          ))}

         
          </Grid>

    </Page>
  );
}
