import {
  Button,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import noImage from "../../assests/No_image.svg";

import {
  Add,
  Minimize,
  PlusOne,
  Remove,
  ViewAgenda,
} from "@mui/icons-material";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Iconify from "src/components/Iconify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  // bgcolor: 'white',
  border: "none",
  boxShadow: 4,
  borderRadius: 1,
  p: 3,
};

const Productcard = ({ data, setAddedProd, addedProduct }) => {
  const [open, setOpen] = useState(false);

  const [forVarient, setForVarient] = useState({ ...data });

  const setVarientSelect = (ind, value) => {
    console.log("addedProduct", addedProduct);

    const dataMain = {
      ...forVarient,
      product_quantity: 1,
      selected_variation:
        forVarient?.selected_variation?.length > 0
          ? [...forVarient?.selected_variation]
          : [],
    };

    console.log("dataMain?.available_variants", dataMain?.available_variants);

    dataMain.selected_variation[ind] = value;

    let findVariation = dataMain?.available_variants?.find((ele) => {
      return (
        ele?.attributes[0]?.toLowerCase() ==
          dataMain?.selected_variation[0]?.toLowerCase() &&
        ele?.attributes[1]?.toLowerCase() ==
          dataMain?.selected_variation[1]?.toLowerCase()
      );
    });

    console.log("findVariation", findVariation);

    if (findVariation) {
      dataMain.product_regular_price = findVariation?.product_regular_price;
      dataMain.product_sale_price = findVariation?.product_sale_price;
    }

    setForVarient(dataMain);
  };

  const addProducts = () => {
    console.log("forVarient", forVarient);

    if(forVarient?.variant_option?.length !== forVarient?.selected_variation?.length){
      return toast.error("Please fill the Varients !!")
    }
    
    setAddedProd([...addedProduct, forVarient]);
    setOpen(false);
    toast.success("Product Added Successfull !!")
  };

  console.log("forVarient ===================>>>>>>>>>>>>>>", forVarient);

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell component="th" scope="row" align="center">
          {data?.product_images[0]?.image_url ? (
            <img
              className="product-table-image"
              alt="product"
              src={data?.product_images[0]?.image_url}
            />
          ) : (
            <img className="product-table-image" alt="product" src={noImage} />
          )}
        </TableCell>

        <TableCell align="center">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontWeight: "500", width: "100%" }}>
              <b>{data?.product_name}</b>
            </p>
            <div>{data?.product_code}</div>
          </div>
        </TableCell>
        <TableCell align="center">₹{data?.product_sale_price}</TableCell>

        <TableCell align="right">
          {data?.is_variant_true ? (
            <Button
              startIcon={<Add />}
              onClick={() => {
                for (let prod of addedProduct) {
                  if (prod?._id == data._id) {
                    return toast.error("Already Added !!");
                  }
                }

                setOpen(true);
              }}
            >
              Select Varient
            </Button>
          ) : (
            <Button
              startIcon={<Add />}
              onClick={() => {
                for (let prod of addedProduct) {
                  if (prod?._id == data._id) {
                    return toast.error("Already Added !!");
                  }
                }

                setAddedProd([
                  ...addedProduct,
                  {
                    ...data,
                    product_quantity: 1,
                    selected_variation: [],
                  },
                ]);

                toast.success("Product Added Successfull !!")
              }}
            >
              Add Product
            </Button>
          )}
        </TableCell>
      </TableRow>

      {/* Select Varient =====================>>>>>>>>>>>>>>>>>>>>>>> */}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={style}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            style={{ color: "text.secondary" }}
            className="close-upgrade-icon"
          >
            <Iconify icon="material-symbols:close" />
          </IconButton>

          <Typography
            id="modal-modal-title"
            style={{ fontSize: 20 }}
            variant="h6"
            component="h2"
          >
            Select Varient
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ fontSize: 16, fontWeight: "500", mt: 2 }}
          >
            <div className="varient">
              {data?.variant_option?.map((ele, ind) => (
                <div
                  key={ind}
                  className="varients"
                  style={{ marginTop: "10px" }}
                >
                  <h4 style={{ textTransform: "capitalize" }}>
                    {ele?.option_name}
                  </h4>
                  <div
                    className="varients"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {ele?.option_values?.map((value, i) => (
                      <div className="value_size" key={i}>
                        <Button
                          variant={
                            forVarient?.selected_variation?.length > 0
                              ? value == forVarient?.selected_variation[ind]
                                ? "contained"
                                : "outlined"
                              : "outlined"
                          }
                          onClick={() => {
                            setVarientSelect(ind, value);
                          }}
                        >
                          <p style={{ textTransform: "capitalize" }}>{value}</p>
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Typography>
          <div
            style={{ paddingTop: 26, display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                

                addProducts();
              }}
              startIcon={<Iconify icon="check" />}
            >
              Add Product
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Productcard;
