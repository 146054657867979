// component
import Iconify from '../../components/Iconify';
import imageImport from 'src/utils/imageImport';
// ----------------------------------------------------------------------

// const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const getIcon = (name) => <img src={name} className='nav_icon'  />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'orders',
  //   path: '/dashboard/orders',
  //   icon: getIcon('bxs:box'),
  // },
  // {
  //   title: 'Users',
  //   path: '/dashboard/users',
  //   icon: getIcon('eva:people-fill'),
  // },
  // {
  //   title: 'vendors',
  //   path: '/dashboard/vendor',
  //   icon: getIcon('entypo:shop'),
  // },
 
  // {
  //   title: 'Categories',
  //   path: '/dashboard/categories',
  //   icon: getIcon('material-symbols:category-rounded'),
  // },
  // {
  //   title: 'products',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'banners',
  //   path: '/dashboard/banners',
  //   icon: getIcon('eva:image-fill'),
  // },
  // {
  //   title: 'Enquiries',
  //   path: '/dashboard/enquiries',
  //   icon: getIcon('mdi:message-bookmark'),
  // },

  {
    title: 'Dashboard',
    path: '/dashboard/manage',
    icon: getIcon(imageImport.icon_sidebar10),
  },

  {
    title: 'App Setting',
    path: '/dashboard/appsetting',
    icon:getIcon(imageImport.icon_sidebar17),
    // icon:<Iconify icon='flat-color-icons:android-os' width={30} height={30} />
  },
  {
    title: 'App Publish',
    path: '/dashboard/apppublish',
    icon: getIcon(imageImport.icon_sidebar15),
    // icon: <Iconify icon='logos:google-play-icon' width={20} height={20} />
    // icon: getIcon('logos:google-play-icon'),
  },
  {
    title: 'Your Plan',
    path: '/dashboard/plandetails',
    icon:  getIcon(imageImport.icon_sidebar19),
    // icon:  getIcon('noto:credit-card'),
  },
  // {
  //   title: 'Payment History',
  //   path: '/dashboard/payment-history',
  //   icon: getIcon('fluent-emoji-flat:repeat-button'),
  //   // icon: getIcon('icon-park:paper-money'),
  // },
  {
    title: 'Analytics',
    path: '/dashboard/analytics',
    icon: getIcon(imageImport.icon_sidebar3),
    // icon: getIcon('logos:google-analytics'),
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon(imageImport.icon_sidebar7),
    // icon: getIcon('emojione-v1:shopping-bags'),
    // icon: getIcon('bxs:box'),
  },
  // {
  //   title: "Offline Billing",
  //   path: "/dashboard/offline/billing",
  //   icon: getIcon(imageImport.icon_sidebar28),
  //   // icon: getIcon('logos:google-analytics'),
  // },
  {
    title: 'products',
    path: '/dashboard/products',
    icon: getIcon(imageImport.icon_sidebar16),
    // icon: getIcon('bxs:box'),
    // icon: getIcon(imageImport.icon_dashboard),
  },
  {
    title: 'Categories',
    path: '/dashboard/categories',
    icon: getIcon(imageImport.icon_sidebar5),
    // icon: getIcon('material-symbols:category-rounded'),
  },
  {
    title: 'Customers',
    path: '/dashboard/customers',
    // icon: getIcon('eva:people-fill'),
    icon: getIcon(imageImport.icon_sidebar9),
    // icon: getIcon('fluent-emoji:man-feeding-baby-light'),
  },
  {
    title: 'banners',
    path: '/dashboard/banners',
    icon: getIcon(imageImport.icon_sidebar12),
    // icon: getIcon('vscode-icons:file-type-image'),
  },
  {
    title: 'Web Banners',
    path: '/dashboard/web/banners',
    icon: getIcon(imageImport.icon_sidebar12),
    // icon: getIcon('vscode-icons:file-type-image'),
  },

  {
    title: 'Enquiries',
    path: '/dashboard/enquiries',
    icon:getIcon(imageImport.icon_sidebar22),
    // icon: getIcon('cryptocurrency-color:chat'),
  },
  {
    title: "Bulk Enquiries",
    path: "/dashboard/bulk/enquiry",
    icon: getIcon(imageImport.icon_sidebar22),
    // icon: getIcon('cryptocurrency-color:chat'),
  },
  {
    title: "Coupons",
    path: "/dashboard/coupons",
    // icon: getIcon(imageImport.icon_sidebar27),
    icon: getIcon(imageImport.icon_sidebar27),
    // icon: getIcon('flat-color-icons:settings'),
  },
  {
    title: "Loyalty Program",
    path: "/dashboard/loyalty-program",
    // icon: getIcon(imageImport.icon_sidebar26),
    icon: getIcon(imageImport.icon_sidebar26),
  },
  
  {
    title: 'Push Notification',
    path: '/dashboard/app-push-notification',
    icon: getIcon(imageImport.icon_sidebar25),
  },
  // {
  //   title: 'Payments',
  //   path: '/dashboard/adiogent-pay',
  //   icon: getIcon(imageImport.icon_sidebar8),
  // },
  {
    title: 'Plugins',
    path: '/dashboard/plugins',
    icon: getIcon(imageImport.icon_sidebar24),
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: getIcon(imageImport.icon_sidebar20),
    // icon: getIcon('flat-color-icons:settings'),
  },
  {
    title: 'Marketing',
    path: '/dashboard/marketing',
    icon: getIcon(imageImport.icon_sidebar23),
  },
  {
    title: 'Tools & Services',
    path: '/dashboard/tools-and-services',
    icon: getIcon(imageImport.icon_sidebar1),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  

  
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
