import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useNavigate,useLocation} from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from 'src/components/Spinner';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

import 'react-phone-input-2/lib/material.css'
import OTPInput, { ResendOTP } from "otp-input-react";
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { UseContextState } from '../../../global/GlobalContext/GlobalContext'
import {config} from "../../../global/globalConfig"
import Paper from '@mui/material/Paper';
import {Typography} from '@mui/material';
import { editable_config } from 'src/editable_config';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast } from 'react-toastify';
import imageImport from 'src/utils/imageImport';
// import adiogentlogo from "../../../assests/favlogo.jpg"
// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [error , setError ] = useState("")
  const navigate = useNavigate()
  const location = useLocation()

  const [ sendOtpLoading , setsendOtpLoading ] = useState(false)
  const [ verifyOtpLoading , setverifyOtpLoading ] = useState(false)
  const [ phoneNumber, setPhoneNumber ] = useState('')
  const [OTP, setOTP] = useState("");
  const [showOtpModal , setShowOtpModal] = useState(false)
  const [user, setUser] = useState(null);
  const {authState,fetchAuthuser} = UseContextState();

  useEffect(()=>{
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      }
    }, auth);
  },[])

    //  sign up function
  // const onSignUpFunction=async(e)=>{
  //   e.preventDefault()
  //   console.log("OUTER phoneNumber",phoneNumber?.length)
  //   if(phoneNumber?.length !=12 ){
  //     // console.log("innner phoneNumber",phoneNumber?.length)
  //   setError("Provide a Valid Phone Number")
  //   toast.error("Provide a Valid Phone Number")
  //   return
  //   }
  //   if(phoneNumber?.length == 12){
  //     setsendOtpLoading(true)
  //     setError("")
  //   const auth = getAuth();
  //   const formatNumber = '+'+phoneNumber
  //   const appVerifier = window.recaptchaVerifier;
  //   signInWithPhoneNumber(auth, formatNumber, appVerifier)
  //       .then((confirmationResult) => {
  //         window.confirmationResult = confirmationResult;
  //         // ...
  //         setsendOtpLoading(false)
  //         toast.success("OTP Sended Successfully!!",)
  //         setShowOtpModal(true)
  //       }).catch((error) => {
  //         // Error; SMS not sent
  //         console.log(error)
  //         setShowOtpModal(false)
  //         setsendOtpLoading(false)
  //         toast.error("Please Verify Phone Number!!",)
  //       });
  //   }
  // }

  const onSignUpFunction=async(e)=>{
    e.preventDefault()
        if(phoneNumber?.length !=12 ){
      // console.log("innner phoneNumber",phoneNumber?.length)
    setError("Provide a Valid Phone Number")
    toast.error("Provide a Valid Phone Number")
    return
    }

    setsendOtpLoading(true)
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/send/adiogent/user/otp/to/number/${phoneNumber}`,{headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data)
      if(res?.data?.status == true){
        toast.success("OTP Sended Successfully!!",)
        setShowOtpModal(true)
      }
      if(res?.data?.status == false){
        toast.error("Something went wrong!!")
      }
      setsendOtpLoading(false)
      
    })
    .catch(err=>{
      console.log(err)
      toast.error("Something went wrong!!")
    })
  }

  const handleSetOtp=async(userOtp)=>{
    setOTP(userOtp)
    // console.log("OTP?.length",OTP?.length)
    // if(OTP?.length +1 == 6){
    //   await verifyOtp()
    // }

  }

  // ======= VERIFY OTP =========
  const verifyOtp = async()=>{
    if(OTP?.length != 4){
      toast.error("Invalid OTP!!",)
      setverifyOtpLoading(false)
      return
    }
    setverifyOtpLoading(true)
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/verify/adiogent/user/otp`,{phone_number:phoneNumber,otp:OTP},{headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(async(res)=>{
      console.log("res?.data OTP VERIFY--",res?.data)
      if(res?.data?.status === false){
        toast.error("Invalid OTP!!",)
        setOTP('')
        setverifyOtpLoading(false)

      }
      if(res?.data?.status === true){
        setUser(res.user)
        setverifyOtpLoading(false)
        setShowOtpModal(false)
        console.log(res)
        await loginUserFunction() 
      }
      setverifyOtpLoading(false)
    }).catch(err=>{
      console.log(err)
      toast.error("Invalid OTP!!",)
      setverifyOtpLoading(false)
    })
  }
  // const verifyOtp = async()=>{
  //   setverifyOtpLoading(true)
  //   await window.confirmationResult.confirm(OTP).then(async(res)=>{
  //     setUser(res.user)
  //     setverifyOtpLoading(false)
  //     console.log(res)
  //     await loginUserFunction() 
  //   }).catch(err=>{
  //     console.log(err)
  //     toast.error("Invalid OTP!!",)
  //     setverifyOtpLoading(false)
  //   })
  // }
  // ======= VERIFY OTP =========


  //====== LOGIN USER FUNCTION ======
  const loginUserFunction=async()=>{
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/login/user`,{phone_number:phoneNumber},{withCredentials:true})
    .then(async res=>{
      console.log(res)
      toast.success("Login Successfully!!",)
      setShowOtpModal(false)
     await localStorage.setItem('token',JSON.stringify(res?.data?.token))
      fetchAuthuser()
    })
    .catch(err=>{
      console.log(err)
    })
  }
  //====== LOGIN USER FUNCTION ======


  const from = location.state?.from?.pathname || "/";

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true)
  //   await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/login`,{email,password},{withCredentials:true})
  //   .then(res=>{
  //     console.log(res)
  //     if(res?.data?.status === true){
  //       console.log("CALLED")
  //       setLoading(false)
  //       fetchAuthuser()
  //       navigate(from,{replace:true});
  //       setError("")
  //     }else{
  //       setError("Invalid email or password !!")
  //       setLoading(false)
  //     }

  //   })
  //   .catch(err=>{
  //     console.log(err)
  //   })
  // };

 

console.log(phoneNumber);
console.log(OTP);

  return (
    <div className='login_form_with_number' >
       <LoadingSpinner loading={loading} />
      <Paper className='login_box' elevation={3} >
    <div className='login-form-main-container' >
      <div className='login-icon' >
      {/* <img src={imageImport?.loginIcon}/> */}
      <img src={imageImport?.adiogent_letter_Logo}/>
      </div>
      
      <div className='continue-with-phone-heading' ><Typography className='phone_heading_text' variant="h5" gutterBottom>
          Continue With Phone Number
            </Typography>
            </div>
            {error && (<p className='show-error-login' >{error}</p>)}
            <FormProvider  onSubmit={onSignUpFunction}>
<PhoneInput
country={'in'}
onlyCountries={['in']}
value={phoneNumber}
onChange={phone => setPhoneNumber( phone )}
inputClass="phone-number-field"
inputProps={{required:true}}
specialLabel=" Enter Phone Number"
autoFormat={true}
countryCodeEditable={false}
/>

<div className='otp-login-btn' >
<LoadingButton
         loading={sendOtpLoading}
         loadingPosition="start"
         startIcon={<Iconify icon="ic:baseline-lock" />}
         variant="contained"
         style={{padding:'15px 10px'}}
         fullWidth
         type='submit'
       >
         <span>Generate OTP</span>
       </LoadingButton>
</div>
</FormProvider>
</div>
{/* <p className='or-betweenlogin-sign' >or</p> */}

{/* <div className='dont-have-account' >
<p >Don’t have account? <span style={{color:editable_config.Main_Color,cursor:'pointer'}} >Signup</span></p>
</div> */}

    {/* otp verification form */}
   {showOtpModal &&  <div className='login-form-main-container' >
      {error && (<p className='show-error-login' >{error}</p>)}
      <div className='verify-otp-heading' ><Typography variant="h5" gutterBottom>
          Verify OTP 
            </Typography>
            </div>
      <FormProvider >
            <OTPInput className="otp_all_input"
            value={OTP} 
            onChange={(e)=>handleSetOtp(e)}
             autoFocus 
             OTPLength={4}
              otpType="number"
               disabled={false} 
               inputClassName="otp-input-style"
              //  placeholder={["0","0","0","0","0","0"]}
               />

<div className='otp-login-btn' >
<LoadingButton
         loading={verifyOtpLoading}
         loadingPosition="start"
         startIcon={<Iconify icon="material-symbols:lock" />}
         variant="contained"
         style={{padding:'15px 10px'}}
         fullWidth
         onClick={verifyOtp}
       >
         <span>Verify & Login</span>
       </LoadingButton>
</div>
</FormProvider>
</div>}
    {/* otp verification form */}
<div className='term-policy-text' >
  <p >By continuing, you agree to our <a href={editable_config.TermAndServiceLink} target='_blank' ><span style={{color:editable_config.Main_Color,cursor:'pointer'}} >Terms of Service</span></a> and <a href={editable_config.Privacy_Policy_Link} target='_blank' ><span style={{color:editable_config.Main_Color,cursor:'pointer'}} >Privacy Policy</span></a>.</p>
</div>
      </Paper>


       {/* <FormProvider methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <RHFTextField
        value={email}
        onChange={(e)=>setEmail(e.target.value)}
        required
         name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          required
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" >
        Login
      </LoadingButton>
    </FormProvider> */}
    </div>
  );
}
