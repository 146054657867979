// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import axios from 'axios';
import { useEffect } from 'react';

export default function App() {



  // async function getToken(){
  //   const userTok = await localStorage.getItem('token')
  //   console.log("LOG TOKEN=========,,,", userTok);
  //    axios.defaults.headers.common['admin_header'] = JSON.parse(userTok) // for all requests
  
  // }
  // useEffect(()=>{
  //   getToken()
  // },[])



  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      {/* FOR REACT TOAST NOTIFICATION */}
      {/* FOR WEB */}
      <ToastContainer
        className='toast-notification-class-web'
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="colored"
          />
          {/* FOR WEB */}
          {/* FOR MOBILE */}
          <ToastContainer
          className='toast-notification-class-mobile'
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="colored"
          />
          {/* FOR MOBILE */}

      {/* FOR REACT TOAST NOTIFICATION */}
    </ThemeProvider>
  );
}
