import React from 'react'
import { InputAdornment,Container,TextField,Button } from '@mui/material';
// import mobile_banner from "../assests/bannernew.jpg";
import { Icon } from '@iconify/react';
// import fashioncat2 from "../assests/t1.jpg"
// import fashioncat3 from "../assests/w1.jpg"
// import fashioncat4 from "../assests/j1.jpg"
// import managedas from "../assests/manage.png";
// import tableimg from "../assests/table.png";
// import sofaimg from "../assests/sofa.png";
// import chart from "../assests/chart.png";
// import rocket from "../assests/rocket.png";
// import history from "../assests/history.png";
// import useravtar from "../assests/useravtar.png";
// import idcard from "../assests/id-card.png";
// import subscription from "../assests/subscription.png";
// import planduration from "../assests/duration.png";
import imageImport from 'src/utils/imageImport';
import { useNavigate } from 'react-router-dom';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import { copyContentFunc } from 'src/global/globalFunctions';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import AppStepper from '../layouts/dashboard/AppStepper';

function ManageAll() {
    const {authState} = UseContextState();
    const navigate = useNavigate()
    let planDetails = authState?.user?.plan_details
  return (
    <>
    <AppStepper/>
        <div className='manage_all_main_box' >
        <div className='price_plan_and_checkout'>

<div className='currentplan_and_allplan store_setting_box manage_all_things'>
    {/* <div className='manage_all_things_heading'>
        <h4>Manage Your Dashboard</h4>
    </div> */}
    <div className='manage_two_boxes'>
        <div className='manage_item_box first_item_box'>
            <div className='manage_item_img'>
                <img src={imageImport?.managedasImg} alt="" />
            </div>
            <div className='manage_item_text'>
            <div className='box_name_and_status side_glow_btn'>
                <span>App Setting</span>
                <span className='glow'><dot>•</dot> Live</span>
                </div>
                <h4>Setup Your App</h4>
                <h6 style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard/appsetting')} >Click To Setting</h6>
            </div>
        </div>

        <div className='manage_item_box second_item_box'>
            <div className='manage_item_img'>
                <img src={imageImport?.rocketImg} alt="" />
            </div>
            <div className='manage_item_text'>
                <div className='box_name_and_status'>
                <span>App Launch</span>
                  <div>
                  {authState?.user?.app_published 
                   ?
                   <span className='glow'><dot>•</dot> Live</span>
                    :

                    <span className='glow_in_review'><dot>•</dot> In Review</span>

                   }
                  </div>

            </div>
                <h4>Publish Your App</h4>
                <h6 style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard/apppublish')} >Click To Launch</h6>
            </div>
        </div>
    </div>

    <div className='manage_two_boxes second_box'>
        <div className='manage_item_box third_item_box'>
            <div className='manage_item_img'>
                <img src={imageImport?.historyImg} alt="" />
            </div>
            <div className='manage_item_text'>
            <div className='box_name_and_status side_glow_btn'>
                <span>Transaction</span>
                <span className='glow'><dot>•</dot> Live</span>
                </div>
                <h4>Payment History</h4>
                <h6 style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard/payment-history')} >Click To Check Transaction History</h6>
            </div>
        </div>

        <div className='manage_item_box fourth_item_box'>
            <div className='manage_item_img'>
                <img src={imageImport?.chartImg} alt="" />
            </div>
            <div className='manage_item_text '>
            <div className='box_name_and_status'>
                <span>Manage</span> 
                {/* {authState?.user?.app_dashboard_link && <span style={{color:'#0FE125',fontSize:18,fontWeight:600,backgroundColor:'white'}} > Live</span> }  */}
                {authState?.user?.app_dashboard_status 
                
                ?
                <span className='glow'><dot>•</dot> Live</span>
                :
                <span className='glow_in_review'><dot>•</dot> In Review</span>
                }
               
               
                </div>

                <h4>Manage App Dashboard</h4>
                <div className='id_and_password'>
                {authState?.user?.app_dashboard_status  ?    
                 <h6 style={{cursor:'pointer'}} onClick={()=>navigate("/dashboard/products")} >Click To Manage</h6>
                :
                 
                 <h6 style={{cursor:'not-allowed'}} >Click To Manage</h6> }
                    {/* <div className='dashboard-credential-box' >
                    {authState?.user?.dashboard_user_email && <p className='dashboard-credentials' >Id : <p className='dashboard-credentials-span' >{authState?.user?.dashboard_user_email}</p>
                    <p onClick={()=>copyContentFunc(authState?.user?.dashboard_user_email)} className='copy_app_id_style' style={{color:palette.primary.main}} ><Iconify className='copy-icon' icon="mingcute:copy-fill" /></p>
                    </p> }
                    {authState?.user?.dashboard_user_password && <p className='dashboard-credentials' >Password : <p className='dashboard-credentials-span' >{authState?.user?.dashboard_user_password}</p>
                    <p onClick={()=>copyContentFunc(authState?.user?.dashboard_user_password)} className='copy_app_id_style' style={{color:palette.primary.main}} ><Iconify className='copy-icon' icon="mingcute:copy-fill" /></p>
                    </p> }
                    </div> */}
                </div>
                 
                 
            </div>
        </div>
    </div>
</div>

<div className='plan_choose_and_checkout status_checker_box'>
    <div className='status_of_app_and_dashboard'>
        <div className='white_box_of_user_Profile'>
            <div className='user_profile_and_user_details'>
                <h4>Your Profile !!</h4>
                <div className='user_profile_img'>
                    <img src={imageImport?.useravtarImg} alt="" />
                </div>
                <div className='three_box_details'>
                <div className='user_profile_details'>
                    <div className='details_box_of_profile_with_icon app_id'>
                        <div className='icon_of_detail'>
                            <img src={imageImport?.idcardImg} alt="" />
                        </div>
                        <div className='detail_with_heading_and_content'>
                            <span className='detail_heading'>App ID</span>
                            {/* <p>AppID89554521445</p> */}
                            <p className='font-capitalize-case' >{authState?.user?.app_id}</p>
                        </div>
                    </div>
                </div>

                <div className='user_profile_details'>
                    <div className='details_box_of_profile_with_icon subscription_box'>
                        <div className='icon_of_detail'>
                            <img src={imageImport?.subscriptionImg} alt="" />
                        </div>
                        <div className='detail_with_heading_and_content'>
                            <span className='detail_heading'>Your Plan</span>
                            {/* <p>Startup Plan * 3 Months</p> */}
                            {/* <p>{planDetails?.plan_name} Plan * {planDetails?.duration?.name}{planDetails?.duration?.name[0] == 1 ? '': 's' } </p> */}
                           {planDetails?.purchased_date ?
                            <p className='font-capitalize-case' > {planDetails?.plan_name} Plan * {planDetails?.duration?.name} </p>
                            :
                            <p>Free Plan  </p>
                             }
                        </div>
                    </div>
                </div>

                <div className='user_profile_details app_id'>
                    <div className='details_box_of_profile_with_icon time_duration'>
                        <div className='icon_of_detail'>
                            <img src={imageImport?.plandurationImg} alt="" />
                        </div>
                        <div className='detail_with_heading_and_content'>
                            <span className='detail_heading'>Plan Duration</span>
                            {/* <p>1 Jan 2023 to 3 May 2023</p> */}
                            {/* <p>{planDetails?.purchased_date} to {planDetails?.renew_date}</p> */}
                            {planDetails?.purchased_date ?  <p className='font-capitalize-case' >{planDetails?.purchased_date?.slice(4)} to {planDetails?.renew_date?.slice(4)}</p> : <p>Forever</p>  }
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>

    
    
</div>

</div>
    </div>
    </>
  )
}

export default ManageAll