import {
  Button,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import noImage from "../../assests/No_image.svg";

import {
  Add,
  Close,
  Minimize,
  PlusOne,
  Remove,
  ViewAgenda,
} from "@mui/icons-material";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Iconify from "src/components/Iconify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  // bgcolor: 'white',
  border: "none",
  boxShadow: 4,
  borderRadius: 1,
  p: 3,
};

const ManageProductCard = ({ data, setAddedProd, addedProduct }) => {
  const [open, setOpen] = useState(false);
  const [data_main, setData] = useState([...data?.available_variants]);

  // console.log("data_main", data_main);

  const removeItem = () => {
    let newProd = [];

    for (let prod of addedProduct) {
      if (prod._id != data._id) {
        newProd.push(prod);
      }
    }

    setAddedProd(newProd);
  };

  const incQty = () => {
    const dataMain = [...addedProduct];

    for (let prod of dataMain) {
      if (prod._id == data._id) {
        prod.product_quantity += 1;
      }
    }

    setAddedProd(dataMain);
  };

  const decQty = () => {
    const dataMain = [...addedProduct];

    for (let prod of dataMain) {
      if (prod._id == data._id) {
        prod.product_quantity -= 1;
      }
    }

    setAddedProd(dataMain);
  };

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell component="th" scope="row" align="center">
          {data?.product_images[0]?.image_url ? (
            <img
              className="product-table-image"
              alt="product"
              src={data?.product_images[0]?.image_url}
            />
          ) : (
            <img className="product-table-image" alt="product" src={noImage} />
          )}
        </TableCell>

        <TableCell align="center">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontWeight: "500", width: "100%" }}>
              <b>{data?.product_name}</b>
            </p>
            <div>{data?.product_code}</div>
            {data?.selected_variation && data?.selected_variation[0]
              ? ` ${data?.selected_variation[0]}`
              : ""}
            {data?.selected_variation && data?.selected_variation[1]
              ? ` | ${data?.selected_variation[1]}`
              : ""}
          </div>
        </TableCell>

        <TableCell align="center">₹{data?.product_sale_price}</TableCell>

        <TableCell align="left">
          <div className="quantityOfflineBilling">
            <IconButton
              onClick={() => {
                if (data?.product_quantity <= 1) {
                  return;
                }
                decQty();
              }}
            >
              <Remove />
            </IconButton>
            <h4>{data?.product_quantity}</h4>
            <IconButton
              onClick={() => {
                incQty();
              }}
            >
              <Add />
            </IconButton>
          </div>
        </TableCell>
        <TableCell align="center">
          {/* {data?.is_variant_true ? (
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              Select Varient
            </Button>
          ) : (
            ""
          )} */}

          <IconButton
            color="error"
            onClick={() => {
              removeItem();
            }}
          >
            <Close />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Select Varient =====================>>>>>>>>>>>>>>>>>>>>>>> */}

      {/* <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={style}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            style={{ color: "text.secondary" }}
            className="close-upgrade-icon"
          >
            <Iconify icon="material-symbols:close" />
          </IconButton>

          <Typography
            id="modal-modal-title"
            style={{ fontSize: 20 }}
            variant="h6"
            component="h2"
          >
            Select Varient
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ fontSize: 16, fontWeight: "500", mt: 2 }}
          >
            <div className="varient">
              {data?.variant_option?.map((ele, ind) => (
                <div
                  key={ind}
                  className="varients"
                  style={{ marginTop: "10px" }}
                >
                  <h4 style={{ textTransform: "capitalize" }}>
                    {ele?.option_name}
                  </h4>
                  <div
                    className="varients"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {ele?.option_values?.map((value, i) => (
                      <div className="value_size" key={i}>
                        <Button
                          variant={
                            value == data.selected_variation[ind]
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            setVarientSelect(ind, value);
                          }}
                        >
                          <p style={{ textTransform: "capitalize" }}>{value}</p>
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Typography>
          <div
            style={{ paddingTop: 26, display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setOpen(false);
              }}
              startIcon={<Iconify icon="check" />}
            >
              OK
            </Button>
          </div>
        </Box>
      </Modal> */}
    </>
  );
};

export default ManageProductCard;
