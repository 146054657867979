import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import imageImport from 'src/utils/imageImport';

function AppBannerSlider(props) {
  return (
    <Carousel autoPlay={true}
     interval={3000} 
    stopOnHover={false}
     showThumbs={false} 
     infiniteLoop showArrows={false}
      showStatus={false} swipeable={true} >
        {props?.bannerImg?.map(image=>(
    <div>
        {/* <img src={imageImport?.banner_c_1} /> */}
        <img className='app-banner-img' src={image} />
    </div>

        ))}
    {/* <div>
        <img src={imageImport?.banner_c_2} />
    </div>
    <div>
        <img src={imageImport?.banner_c_3} />
    </div> */}
</Carousel>
  )
}

export default AppBannerSlider