import React from 'react'
// import fashion_product1 from "../../assests/k5.jpeg"
import imageImport from 'src/utils/imageImport'

function AppProductCard(props) {
  return (
    <>
        <div onClick={props?.goToProductScreen} className='mobileapp_productcard'>
            <div className='product_card_img'>
                <img src={props.image ? `${props.image}` : imageImport?.fashion_product1} alt="" />
            </div>
            <div className='discount_text'>
                <span>13% OFF</span>
            </div>

            <div className='product_name'>
                <h5 className='font-capitalize-case' >{props?.name}</h5>
            </div>
            <div className='product_card_category'>
                <h6 className='font-capitalize-case' >{props?.category}</h6>
            </div>
            <div className='product_price_and_addtocard_btn'>
                <div className='product_both_price'>
                    <span className='sale_Price'>₹1599</span>
                    <h6 style={{color:props.app_color}} >₹1399</h6>
                </div>
                <div style={{backgroundColor:props.app_color}} className='addtocard_btn'>
                    <span>+</span>
                </div>
            </div>
        </div>
    </>
  )
}

export default AppProductCard