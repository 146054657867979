export const editable_config = {
    Admin_Name : "Adiogent Pvt Ltd",
    Logo : require("../src/assests/adiogent-logo.png"),
    Main_Color : "#0072FF" , // 007FFF #28A75A
    Hover_Color:"#0061da",  // #06973f
    ImageUploadSize:400000, // 400kb 
    CategoryImageUploadSize:200000, // 200kb 
    BannerUploadSize:1000000, // 1 mb 
    // UpgradePlanLink:'https://blackhatcode.in/',
    Privacy_Policy_Link:'https://adiogent.in/privacy-policy/',
    TermAndServiceLink:'https://adiogent.in/term-and-conditions/',
    Contact_Support_Link:'https://adiogent.in/support-for-dash/',
    App_Publish_Support_Link:' https://adiogent.in/support-for-publish-app/',
    Tutorials_Link:'https://adiogent.in/video-training-and-tutorials/',
    ImageCompressorLink:'https://imagecompressor.in/',
    // Manage_App_Link:'https://app.adiogent.in/dashboard/appsetting',
    FRONTEND_VALIDATOR:'iwerbxinuwfxawuxdubfjsaknbfkjsgfabewubinkjbmayankandsanjeevbdhiyahingeuinhrcuiashuichraweiubrcuiwaenhfiucnbwaeiufnciuwefbnciusbonficsnabifxkmasfacfnbsakxfbmascaca',
}