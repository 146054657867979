import { useCallback, useState } from "react";
import FileUploadCss from "./fileUpload.module.css";
import imageCompression from "browser-image-compression";
// import Loading from "./Loading";
import {Button,IconButton} from '@mui/material';
import Iconify from "src/components/Iconify";

const Imagecompressor = () => {
  const [images, setImages] = useState([]);
  const [compressImages, setCompressImg] = useState([]);
  const [maxSize, setMaxSize] = useState(50);
  const [prevInd, setPrevInd] = useState(0);

  const [loading, setLoading] = useState(false);

  const [render, setRender] = useState(false);


  const allClear = () => {
    setImages([]);
    setMaxSize(50);
    setCompressImg([]);
    setPrevInd(0);
  };

  const imageCompressHandel = async (fi, maxSizeMb) => {
    console.log("in 0 to 1 val => ", maxSizeMb / 100, fi);

    const compressedFile = await imageCompression(fi, {
      maxSizeMB: 350,
      // maxWidthOrHeight: maxSizeMb,
      initialQuality: maxSizeMb / 100,
      useWebWorker: true,
    });

    return compressedFile;
  };
  // const imageCompressHandel = async (fi, maxSizeMb) => {
  //   const files = await imagemin(fi, {
  //     destination: 'build/images',
  //     plugins: [
  //       imageminJpegtran(),
  //       imageminPngquant({
  //         quality: [0.6, 0.8]
  //       })
  //     ]
  //   });

  //   return files;
  // };

  // console.log(imageCompressHandel);

  const compressSelectedImg = async (ele, maxSize) => {
    console.log("Element Size", ele, maxSize);
    setLoading(true);
    const response = await imageCompressHandel(ele, maxSize);
    // await allClear();
    setCompressImg([]);
    setImages([]);
    let arr = compressImages;
    arr.push({ oldFile: ele, compressedFile: response });
    console.log(arr);
    setCompressImg([...arr]);
    setLoading(false);
  };

  console.log("Loading ", loading);

  const selectFile = async (e) => {
    e.preventDefault();

    // setLoading(true);

    let arrFileLength = e.target.files.length;
    if (arrFileLength > 20) {
      return alert("Upload up to 20 images");
    }
    await allClear();
    let arr = [...images];
    arr.push(...e.target.files);
    await setImages(arr);

    // console.log("Arr => ", arr);
    // console.log("images", images);

    arr.forEach((ele) => {
      compressSelectedImg(ele, maxSize);
    });
    // setLoading(false);
  };

  const removeElement = async (e, index) => {
    e.preventDefault();

    let compressImg = compressImages;
    const img = images;
    const removedimg = compressImg.filter((ele, i) => i !== index);
    const removedPr = img.filter((ele, i) => i !== index);

    // console.log(removedimg, index, compressImg);
    setCompressImg(removedimg);
    setImages(removedPr);
    setPrevInd(0);

    console.log("comp Img Remove =>", compressImg, img);
  };

  const setqualityImages = useCallback(
    (compressImg) => {
      setCompressImg(compressImg);
    },
    [render]
  );

  const setqualityImage = async (e, oldFile) => {
    e.preventDefault();
    let compressImg = compressImages;
    const img = images;

    console.log("comp and images", img, compressImg);

    let res = await imageCompressHandel(
      compressImg[prevInd]?.oldFile,
      Number(maxSize)
    );
    // let removedimg = compressImg.filter((ele, i) => i !== prevInd);
    // let removedPr = img.filter((ele, i) => i !== prevInd);
    // removedPr.push(oldFile);
    // removedimg.push({ compressedFile: res, oldFile: oldFile });
    compressImg[prevInd].compressedFile = res;

    await setqualityImages(compressImg);

    setRender(!render);
    // setImages(removedPr);

    // console.log(removedimg);

    // setPrevInd(compressImages.length - 1);
  };

  console.log(
    "Compressed File =>",
    compressImages,
    compressImages[0]?.compressedFile
  );
  console.log("Image File =>", images);
  return (
    <>
      <div className={`${FileUploadCss.select_file}`}>
        <div className={`container ${FileUploadCss.mainBox}`}>
          <div className="buttons flex justifyCenter">
            {/* <button>
              <label
                htmlFor="imageUpload"
                className="flex alignCenter justifyCenter gap_10"
              >
                <AiOutlineCloudUpload /> Upload Files
              </label>
            </button> */}
           <Button  variant="contained"   className="flex alignCenter justifyCenter gap_10"    startIcon={<Iconify icon="eva:image-fill" />}>
           <label
                htmlFor="imageUpload"
                className="flex alignCenter justifyCenter gap_10"
              >Upload Images </label>
            </Button>

            {/* <button
              onClick={allClear}
              className="danger flex alignCenter justifyCenter gap_10"
            >
              <AiOutlineCloseCircle />
              Clear Images
            </button> */}
           <Button  variant="outlined"    onClick={allClear}
              className="danger flex alignCenter justifyCenter gap_10"   startIcon={<Iconify icon="mi:delete" />}> Clear Images</Button>

          </div>

          <form>
            <div
              className={`${FileUploadCss.imageUpload} justifyCenter flex alignCenter flexDir`}
            >
              {loading ? (
                // <Loading />
                <div></div>
              ) : (
                <input
                  onChange={selectFile}
                  title="Drop Your Files Here"
                  className={`${FileUploadCss.imageUploadInput}`}
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  multiple={true}
                  name="ch"
                  style={
                    compressImages.length <= 0
                      ? {}
                      : { height: "0px", border: "none" }
                  }
                />
              )}

              {compressImages.length > 0 ? (
                <>
                  <div
                    className={`${FileUploadCss.listOfImg} flex flex_wrap justifyCenter`}
                  >
                    {compressImages?.map((ele, i) => {
                      console.log("EleMent", ele);
                      return (
                        <div
                          key={i}
                          className={`${FileUploadCss.mainCardComp}`}
                        >
                          {/* <button
                            onClick={(e) => removeElement(e, i)}
                            className={`${FileUploadCss.remove_card}`}
                          >
                            <MdClose />
                          </button> */}
           <Button  variant="contained"  className={`${FileUploadCss.remove_card}`}  onClick={(e) => removeElement(e, i)}  startIcon={<Iconify icon="material-symbols:download" />}>Download QR</Button>

                          <div
                            // onClick={() => setPrevInd(i)}
                            onClick={() => setPrevInd(i)}
                            className={`${FileUploadCss.containImg} ${
                              prevInd === i ? FileUploadCss.card_active : ""
                            }`}
                          >
                            <span className={`${FileUploadCss.fileNameCard}`}>
                              <p>{ele.compressedFile.name.slice(-15)}</p>
                            </span>

                            <img
                              src={URL.createObjectURL(ele.oldFile)}
                              alt=""
                            />
                            <div
                              className={`flex alignCenter justifyCenter flexDir ${FileUploadCss.containButton}`}
                            >
                              <p className={`flex alignCenter`}>
                                {Math.round(Number(ele.oldFile.size / 1024))}KB{" "}
                                {/* <TbArrowRightTail /> */}
                                <Iconify icon="material-symbols:download" />
                                {" "}
                                {Math.round(
                                  Number(ele.compressedFile.size / 1024)
                                )}
                                KB
                              </p>
                              <button>
                                 
                                <a
                                  target="_blank"
                                  href={URL.createObjectURL(ele.compressedFile)}
                                  download={ele.oldFile.name}
                                  style={{display:'block'}}
                                >
                                  Download
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>

      {/* {compressImages && compressImages.length > 0 ? (
        <div className={`${FileUploadCss.afterCompress}`}>
          <div className="container">
            <>
              <p className={`textCenter ${FileUploadCss.fileName}`}>
                {compressImages[prevInd]?.compressedFile?.name}
              </p>
              <form
                className={`flex alignCenter justifyCenter ${FileUploadCss.imageSizeControll}`}
                onSubmit={(e) =>
                  setqualityImage(e, compressImages[prevInd].oldFile)
                }
              >
                <input
                  onChange={(e) => setMaxSize(e.target.value)}
                  max={100}
                  min={1}
                  value={maxSize}
                  type="range"
                />
                <p>{maxSize}% Quality</p>
                <button type="submit">Apply</button>
              </form>
              <div className={`${FileUploadCss.preview}`}>
                <div className={`${FileUploadCss.image}`}>
                  <img
                    src={URL.createObjectURL(
                      compressImages[prevInd]?.compressedFile
                    )}
                    alt=""
                  />
                </div>
                <div
                  className={`${FileUploadCss.image} ${FileUploadCss.resizeAbleImage}`}
                >
                </div>
              </div>
            </>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default Imagecompressor;
