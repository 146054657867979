import React,{useReducer,useEffect,useContext} from "react";
import axios from "axios";
import {config} from "../globalConfig"
import { AuthReducer } from "./reducer/AuthReducer";
import { editable_config } from "src/editable_config";

const initialState = {
    user:null,
    users_customers_count:null,
    announcements_count:null,
    dashboard_notify:null,
    plan_details:null,
    stepper_details:null,
    resubmittion_credit_plan_details:null,
    error:null,
    isAuthenticated:false,
    loading:false
}

// creating global context 
const Global = React.createContext(initialState);
export const UseContextState = ()=>useContext(Global);

function GlobalContext({children}) {
    const [authState , dispatch ] = useReducer(AuthReducer,initialState)

// --===========================
    // console.log("singh")

// --===========================

    // console.log("AuthState ",authState)


    // getting authenticated user
    const fetchAuthuser = async()=>{
        // await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/get/user`,{withCredentials:true})
        await getToken()
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/auth/adiogent/user`,{headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
          },withCredentials:true})
        .then(res=>{
            // console.log(res)
            if(res?.data?.status === true){
                dispatch({type:"LOG_IN",payload:res.data?.result})
                // dispatch({type:"USER_CUSTOMERS_COUNT",payload:res.data?.users_customers_count})
                dispatch({type:"ANNOUNCEMENT_COUNT",payload:res.data?.announcements_count})
                dispatch({type:"DASHBOARD_NOTIFY",payload:res.data?.dashboard_notify})
            }
            else{
                dispatch({type:"ERROR",payload:res?.data?.message})
            }
            
        })
        .catch(err=>{
            console.log(err)
            dispatch({type:"ERROR",payload:"Not Authenticated"})
        })
    }

    //========= GET DASHBOARD NOTIFY ===========
    // const getDashboardNotify = async()=>{
    //     await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/dashboard/notify`,{headers: {
    //         'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    //       },withCredentials:true})
    //     .then(res=>{
    //         console.log("dashboard_notify=======>>>>",res.data?.dashboard_notify)
    //         if(res?.data?.status === true){
    //             dispatch({type:"DASHBOARD_NOTIFY",payload:res.data?.dashboard_notify[0]})
    //         }
    //         else{
    //             dispatch({type:"ERROR",payload:res?.data?.message})
    //         }
            
    //     })
    //     .catch(err=>{
    //         console.log(err)
    //         dispatch({type:"ERROR",payload:"Not Authenticated"})
    //     })
    // }
    //========= GET DASHBOARD NOTIFY ===========

    // GET ADIOGENT PLAN DETAILS
    const getPlanDetails = async()=>{
        // await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/get/user`,{withCredentials:true})
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/adiogents/plan/details`,{headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
          },withCredentials:true})
        .then(res=>{
            console.log(res)
            if(res?.data?.status === true){
                dispatch({type:"PLAN_DETAILS",payload:res.data?.plan_details})
                dispatch({type:"RESUBMITTION_CREDIT_PLAN_DETAILS",payload:res.data?.resubmittion_credit_plans})
            }
            else{
                dispatch({type:"ERROR",payload:res?.data?.message})
            }
            
        })
        .catch(err=>{
            console.log(err)
            dispatch({type:"ERROR",payload:"Not Authenticated"})
        })
    }
    const logoutAuthUser = async()=>{
        // await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/admin/logout`,{withCredentials:true})
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/logout/adiogent/user`,{headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
        },withCredentials:true})
        .then(res=>{
            console.log(res)
            dispatch({type:"LOG_OUT"})
            // document.getElementById("recaptcha-container").remove();
        })
        .catch(err=>{
            console.log(err)
            dispatch({type:"ERROR",error:err})
        })
        
    }
    
    // get stepper details for user
    //================ GET APP DETAILS= =============
    const getStepperDetails= async()=>{
       await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/adiogent/users/steps/details/for/information`, {headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
        },withCredentials:true})
        .then(res=>{
            // console.log("STEPPER DETIALS",res)
            dispatch({type:"STEPPER_DETAILS",payload:res.data?.response})
        })
        .catch(err=>{
            console.log(err);
        })
    }


    async function getToken(){
        const userTok = await localStorage.getItem('token')
        console.log("LOG TOKEN=========,,,", userTok);
         axios.defaults.headers.common['admin_header'] = JSON.parse(userTok) // for all requests
      
      }
    //   useEffect(()=>{
    //     getToken()
    //   },[])


    useEffect(()=>{
        
        fetchAuthuser();
        getPlanDetails();
        getStepperDetails();
        // getDashboardNotify();
    },[])

    const value = {authState,dispatch,fetchAuthuser,getPlanDetails,getStepperDetails,logoutAuthUser}

  return <Global.Provider value={value} >{children}</Global.Provider>
  
  
}

export default GlobalContext

