
import React,{useState,useEffect,useRef} from 'react'
import {MenuItem,OutlinedInput,Chip,InputLabel,Checkbox,ListItemText,FormControl,Select, InputAdornment, Tooltip, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from '../global/Snackbar/CustomSnackbar';
import Iconify from 'src/components/Iconify';
import {Button,IconButton} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import { editable_config } from 'src/editable_config';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import imageImport from 'src/utils/imageImport';
import AppPushNotifiTemplate from './app_templates/AppPushNotifiTemplate';
import VideoModal from 'src/global/Modals/VideoModal';
import LoadingSpinner from 'src/components/Spinner';
import DeactivateAdiogentPayModal from 'src/global/Modals/DeactivateAdiogentPayModal';

function Payments() {
    const [ openDeleteConfimModal, setOpenDeleteConfimModal ] = useState(false)
    const [message ,setMessage] = useState({type:"",message:""})
    const [ isInstalled, setIsInstalled  ] =useState(false)
    const [ upi_id, setUpiId  ] =useState('')
    const [ notificationMessage, setnotificationMessage  ] = useState('')
    const [ loading, setLoading  ] =useState(false)
    const [ loadingBackground, setLoadingBackground  ] =useState(false)
    const [ loadingButton, setLoadingButton  ] =useState(false)
    const [ btnUninstallLoading, setbtnUninstallLoading  ] =useState(false)
    const [snackbarOpen,setSnackbarOpen ] = useState(false)
    const [ render, setRender ] = useState(false)
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const {authState} = UseContextState()
    const navigate = useNavigate()

    // console.log("notification->>>>",upi_id,notificationMessage)

    useEffect(()=>{
      setLoadingBackground(true)
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/get/adiogent/pay/detail/${authState?.user?.app_id}`,{headers: {
        'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
      },withCredentials:true})
      .then(res=>{
        console.log('plugin deatils',res?.data)
        setIsInstalled(res?.data?.adiogent_pay_detail?.adiogent_pay?.is_installed)
        setUpiId(res?.data?.adiogent_pay_detail?.adiogent_pay?.upi_id)
        setLoadingBackground(false)
      })
      .catch(err=>{
        console.log(err)
      })
    },[render])




   const saveAndActivateAdiogentPay = async(e)=>{
    e.preventDefault()
    const adiogentpay_detail={
      is_installed:isInstalled,
      upi_id:upi_id?.trim(),
    }
    await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/add/payments/adiogent/pay/detail/${authState?.user?.app_id}`
    ,{adiogentpay_detail}
    ,{headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log("Plugin Detail=>",res?.data)
      setSnackbarOpen(true);
      setMessage((prev)=>({...prev,type:'success',message:'Payment Updated Successfully !!'}))
      setRender(prev=>!prev)
    })
    .catch(err=>{
      console.log(err)
      setSnackbarOpen(true);
      setMessage((prev)=>({...prev,type:'error',message:'Unknown error occurred !!'}))
    })
   }

   const handleInstallPluginBtn = ()=>{
    setLoading(true)
    setTimeout(async() => {
      const adiogentpay_detail={
        is_installed:true
      }
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/active/and/deactive/adiogent/pay/${authState?.user?.app_id}`
      ,{adiogentpay_detail}
      ,{headers: {
        'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
      },withCredentials:true})
      .then(res=>{
        console.log("Plugin Detail=>",res?.data)
        setLoading(false)
        setSnackbarOpen(true);
        setMessage((prev)=>({...prev,type:'success',message:'Adiogent Pay Activated Successfully !!'}))
        setRender(prev=>!prev)
      })
      .catch(err=>{
        console.log(err)
        setSnackbarOpen(true);
        setMessage((prev)=>({...prev,type:'error',message:'Unknown error occurred !!'}))
      })

      setIsInstalled(true)
    }, 3000);
  }

  const handleUninstallPlugin=()=>{
    setbtnUninstallLoading(true)
    setTimeout(async() => {
      const adiogentpay_detail={
        is_installed:false,
        upi_id:''
      }
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/active/and/deactive/adiogent/pay/${authState?.user?.app_id}`
      ,{adiogentpay_detail}
      ,{headers: {
        'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
      },withCredentials:true})
      .then(res=>{
        console.log("Plugin Detail=>",res?.data)
        setbtnUninstallLoading(false)
        setSnackbarOpen(true);
        setMessage((prev)=>({...prev,type:'success',message:'Adiogent Pay Deactivated Successfully !!'}))
        setOpenDeleteConfimModal(false)
        setIsInstalled(false)
      })
      .catch(err=>{
        console.log(err)
        setSnackbarOpen(true);
        setMessage((prev)=>({...prev,type:'error',message:'Unknown error occurred !!'}))
      })

    }, 3000);
    }




    // ##################### SNACK BAR FUNCTIONs ##################
const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };
  // ##################### SNACK BAR FUNCTIONs ##################

    // handle close video modal
    function handleCloseVideoModal(){
        setOpenVideoModal(false)
      }
      
    
      // handle open video modal
      function handleOpenVideoModal(){
        setOpenVideoModal(true)
      }
  
      const handleCloseSaveAndCancelModal=()=>{
        setOpenDeleteConfimModal(false); 
        // setIsOpen2(false)
      }



     

  return (
    <div className='custom-conatiner'>
<LoadingSpinner loading={loadingBackground} />
<VideoModal title='Learn How To Add Adiogent Pay in Your App?' video_url="https://www.youtube.com/embed/ykOErWNr0kQ" isOpen={openVideoModal} handleClose={handleCloseVideoModal} /> 


  {/*=============== SAVE AND CANCEL MODAL============= */}
  <DeactivateAdiogentPayModal open={openDeleteConfimModal}  onYes={handleUninstallPlugin} loading={btnUninstallLoading}  handleClose={handleCloseSaveAndCancelModal}  />

{/*=============== SAVE AND CANCEL MODAL============= */}

        {/* #################### SANCKBAR MESSAGE ######################## */}
        <CustomizedSnackbars onOpen={snackbarOpen} type={message?.type} handleClose={handleCloseSnackbar}  message={message?.message} />
 
 {/* #################### SANCKBAR MESSAGE ######################## */}
 <div className="banner-image-guide-box order_page_video order_view_page_video">
 <Button  onClick={handleOpenVideoModal} variant="text" className='image-guide-btn-text' startIcon={<Iconify icon="logos:youtube-icon" />}>Learn How it Works</Button>
</div>
<Paper elevation={4} >
<div className='product-conatiner'>
<div className='inner-page-heading-box' >
        <IconButton sx={{color:'black'}}  onClick={()=>navigate(-1)} >
        <Iconify icon="material-symbols:arrow-back-rounded" />
        </IconButton>
     <div>
     <h2>Payments </h2>
       
     </div>
      </div>
     <div className="plugin_detail_main_container">
     <div className='plugin-details-box-style plugin-video-main-box' >
     <div className="plugin_detail_card_main_box">
          <div className='plugin_card_box' >
              <img src={imageImport.icon_sidebar19}
              className='plugin_icon'
              />
             <div className='flex-columns' >
            <Typography variant="h4" sx={{paddingBottom:0.5,textTransform:'capitalize'}} >Adiogent Pay</Typography>
              <Typography variant="body2"  sx={{ color: 'text.secondary' }} >
              Adiogent Pay allows you to add your UPI payments with instant settelments, 0% transctation fees, no hidden charges. Simple to integrate with your app.

              </Typography>
            
            
             </div>
            </div>
            <div className='install_plugin_detail_btn' >
              {/* <Button  endIcon={<Iconify icon="ant-design:setting-outlined" />}  variant='contained' > Settings</Button> */}
         {isInstalled ? 
              <LoadingButton
              loading={loading}
              loadingPosition="end"
              endIcon={<Iconify icon="material-symbols:download" />} 
              variant="contained"
              disabled
            >
              <span>Activate Payments</span>
            </LoadingButton>
         
        :
        <LoadingButton
        loading={loading}
        loadingPosition="end"
        endIcon={<Iconify icon="material-symbols:download" />} 
        variant="contained"
        onClick={handleInstallPluginBtn}
      >
        <span>Activate Payments</span>
      </LoadingButton>
        }

         {isInstalled && 
          <Button onClick={()=>setOpenDeleteConfimModal(true)} className='plugin_uninstall_btn'  variant='text' > Deactivate</Button>
         }
          </div>

              {/* <div className='key_feature_box' >
              <Typography variant="h6"  >Key Features</Typography>
                <ul className='key_feature_list' >
                  <li> <Typography variant="body2"  sx={{ color: 'text.secondary' }} >
                  Increased engagement with users.
                  </Typography>
                    </li>
                  <li> <Typography variant="body2"  sx={{ color: 'text.secondary' }} >
                 Personalized offers and triggered messages to end user.

                  </Typography>
                    </li>
                    <li> <Typography variant="body2"  sx={{ color: 'text.secondary' }} >
                    Efficient upselling and cross-selling strategies.
                  </Typography>
                    </li>
          
                </ul>
              </div> */}

            
{isInstalled && 
  <form onSubmit={saveAndActivateAdiogentPay} className='plugin_config_box' >
{/* <Typography variant="h4" sx={{paddingBottom:0.5,textTransform:'capitalize'}} > Notification Content </Typography> */}

     <div className='add_product_label_input' style={{width:'100%'}} >
      <label htmlFor="" className='flex' >Your UPI ID <Tooltip title="Please make sure to enter a valid UPI ID" arrow placement='right' >
                                        <IconButton size='small' >
                                        <Iconify icon="ion:help-circle-outline" />
                                        </IconButton>
                                        </Tooltip>  </label>
      <TextField required fullWidth
      value={upi_id}
      onChange={(e)=>setUpiId(e.target.value)}
       className='product_form_input' id="outlined-basic" 
       type='text'
       name="notification_title"  placeholder="Enter Your UPI ID " variant="outlined" />
      </div>

      <div className='plugin_config_active_btn' >
      <Button  variant='text' style={{marginRight:"10px"}} onClick={()=>navigate(-1)}  startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />} > Go Back  </Button>
      {/* <Button  type='submit' endIcon={<Iconify icon="carbon:send-filled" />}  variant='contained' > Send Notification </Button> */}
      <LoadingButton
        loading={loadingButton}
        loadingPosition="end"
        endIcon={<Iconify icon="material-symbols:check-circle" />}
        variant="contained"
        type='submit'
      >
        <span>Save & Update</span>
      </LoadingButton>
      </div>

      <div className='app_signing_note' ><strong>Note :</strong> Please make sure to provide a valid UPI ID.</div>

</form>
}

             
             
          </div>
            </div>
     <div className='plugin-details-box-style plugin-video-main-box' >
     {/* <AppPushNotifiTemplate 
     upi_id={upi_id ? upi_id : 'Sale!! Get 50% Off on Every Purchase. 🥳'}
     notificationMessage={notificationMessage ?  notificationMessage : 'Get exclusive discount on mens fashion & baby care at every first purchase.'}
     /> */}
                <div className='plugin-video-box' >
            <iframe className='app_plugin_video' width="100%" height="517" style={{borderRadius:'20px'}}  src="https://www.youtube.com/embed/7dqAWtvcxk8" allowFullScreen  title="YouTube video player" frameborder="0" ></iframe>
                </div>
            </div>
     </div>
</div>
</Paper>
        
    </div>
  )
}

export default Payments