import React, { useEffect, useState,useCallback } from 'react'
import Iconify from 'src/components/Iconify';
import AppBannerSlider from '../app_screen_components/AppBannerSlider';
import { Button } from '@mui/material';
import imageImport from 'src/utils/imageImport';
import AppProductCard from '../app_screen_components/AppProductCard';

function AppTemplate2({setAppScreen,appScreen,time,appHomeScreenCategoryData,appproductData,appDetails,appOrdersData,appBannerData,appCartData,appCategoryScreenData,appSingleProductScreen,handleGoToAppSingleProduct}) {
  return (
    <div>
          <div className='status_of_app_and_dashboard'>
        <div class="smartphone">
            <div class="content">
                {/*=========== APP HOME SCREEN ============ */}
               {appScreen == 'HOME' &&
                 <div className='app-home-screen-bottom-payment' >
                <div className='top_phone_things_baterry_network'>
                    <div className='left_items'>
                        <span>{time}</span>
                        <Iconify className='top_basic_item_icon' icon="carbon:logo-instagram" />
                        <Iconify className='top_basic_item_icon' icon="ic:baseline-whatsapp" />
                        <Iconify className='top_basic_item_icon' icon="mingcute:notification-fill" />
                    </div>
                    <div className='right_items'>
                    <Iconify className='top_basic_item_icon' icon="material-symbols:alarm-outline" />
                    <Iconify className='top_basic_item_icon' icon="mdi:network-favorite" />
                    <Iconify className='top_basic_item_icon' icon="game-icons:network-bars" />
                    <Iconify className='top_basic_item_icon' icon="ion:battery-half-outline" />

                    </div>
                </div>
                <div className='app_name_and_share_icon'>
                    <div className='app_name'>
                        <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} >{appDetails?.app_name?.length ? appDetails?.app_name : 'Your App Name' }</h4>
                        {/* <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} >Template 2</h4> */}
                    </div>
                   <div className='app-top-share-chat-icon-box' >
                   <div className='share_btn'>
                    <Iconify style={{color:appDetails?.app_color,}} className='share_app_icon'  icon="ic:baseline-search" />
                    </div>
                    <div className='share_btn'>
                    <Iconify style={{color:appDetails?.app_color}} className='share_app_icon' icon="material-symbols:share" width={20} height={20} />
                    </div>
                   </div>
                </div>
                {/* <div className='mobile_app_Search'>
                    <input type="text"  placeholder='What are you looking for?'/>
                    <Iconify style={{color:appDetails?.app_color}} className='search_box_icon' icon="ic:baseline-search" />
                </div> */}
                <div className='mobile_app_banner'>
                {appBannerData?.map(category=> category?.category_name === appDetails?.app_type && (<AppBannerSlider bannerImg={category?.banner} />) )}
                </div>

                <div className='app_all_heading category_title_And_view_all'>
                    <h4 style={{color:'black'}}  >Top Categories</h4>
                    <span style={{color:appDetails?.app_color,cursor:'pointer'}} onClick={()=>setAppScreen('CATEGORY')} >View All</span>
                </div>
                <div className='mobile_app_category'>
                    {appHomeScreenCategoryData?.map(category=> category?.category_name === appDetails?.app_type && category?.category_image?.map(cate_image=>(
                            <div className='category_images'>
                            <img src={cate_image?.image} alt="" />
                            <h5 style={{textTransform:'capitalize'}} >{cate_image?.name}</h5>
                        </div>
                    )) 
                  
                     )}

                </div>

                <div className='app_all_heading category_title_And_view_all'>
                    <h4 style={{color:'black'}} >Showing All Products</h4>
                </div>
                <div className='mobile_app_all_products'>
                    {appproductData?.map(category  =>category?.category_name === appDetails?.app_type && category?.products?.map(p=> <AppProductCard goToProductScreen={()=>handleGoToAppSingleProduct(p)} app_color={appDetails?.app_color} category={p?.category} name={p.name} image={p.image} />))}
                </div>

                <div className='bottom_navbar'>
                    <div className='navbar_icons'>
                    <div onClick={()=>setAppScreen('HOME')}  style={appScreen == 'HOME' ? {color:appDetails?.app_color}:{}} className='navbar_basic_icon'>
                        <Iconify className="navicon" icon="ion:home" />
                    </div>
                    <div onClick={()=>setAppScreen('CATEGORY')}   className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="material-symbols:category" />
                    </div>
                    <div onClick={()=>setAppScreen('ORDER')} style={{backgroundColor:appDetails?.app_color ? appDetails?.app_color : 'gray' }} className='main_navicon'>
                        <Iconify className="navicon" id='main_icon' icon="heroicons:shopping-bag-20-solid" />
                    </div>
                    <div onClick={()=>setAppScreen('CART')} className='navbar_basic_icon'>
                    <span className='cart-icon-product-count' >2</span>
                    <Iconify className="navicon" icon="entypo:shopping-cart" />
                    </div>
                    <div onClick={()=>setAppScreen('ACCOUNT')} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="ic:sharp-account-circle" width={28} height={28} />
                    </div>
                    </div>
                </div>
            </div>
               }
               {/*======= APP HOME SCREEN ==========*/}

                {/*=========== APP CATEGORY SCREEN ============ */}
               {appScreen == 'CATEGORY' &&
                 <div>
                <div className='top_phone_things_baterry_network'>
                    <div className='left_items'>
                    <span>{time}</span>
                        <Iconify className='top_basic_item_icon' icon="carbon:logo-instagram" />
                        <Iconify className='top_basic_item_icon' icon="ic:baseline-whatsapp" />
                        <Iconify className='top_basic_item_icon' icon="mingcute:notification-fill" />
                    </div>
                    <div className='right_items'>
                    <Iconify className='top_basic_item_icon' icon="material-symbols:alarm-outline" />
                    <Iconify className='top_basic_item_icon' icon="mdi:network-favorite" />
                    <Iconify className='top_basic_item_icon' icon="game-icons:network-bars" />
                    <Iconify className='top_basic_item_icon' icon="ion:battery-half-outline" />

                    </div>
                </div>
                <div className='app_name_and_share_icon all-category-header'>
                    <div className='app_name'>
                        <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} >All Categories</h4>
                    </div>
                   <div className='app-top-share-chat-icon-box' >
                   <div className='share_btn'>
                    <Iconify style={{color:appDetails?.app_color,}} className='share_app_icon'  icon="material-symbols:support-agent" />
                    </div>
                    <div className='share_btn'>
                    <Iconify style={{color:appDetails?.app_color}} className='share_app_icon' icon="material-symbols:share" width={20} height={20} />
                    </div>
                   </div>
                </div>
                {/* <hr  /> */}

                <div className='mobile_app_category all-category-main-box'>
                    {/* {appCategoryScreenData} */}
                    {appCategoryScreenData?.map(category=> category?.category_name === appDetails?.app_type && category?.category_image?.map(cate_image=>(
                             <div className='category_images all-category-image'>
                             <img src={cate_image.image} alt="" />
                             <h5 className='font-capitalize-case' >{cate_image.name}</h5>
                         </div>
                    )) 
                  
                     )}
                    
                </div>


                <div className='bottom_navbar'>
                    <div className='navbar_icons'>
                    <div onClick={()=>setAppScreen('HOME')}  className='navbar_basic_icon'>
                        <Iconify className="navicon" icon="ion:home" />
                    </div>
                    <div onClick={()=>setAppScreen('CATEGORY')} style={appScreen == 'CATEGORY' ? {color:appDetails?.app_color}:{}} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="material-symbols:category" />
                    </div>
                    <div onClick={()=>setAppScreen('ORDER')} style={{backgroundColor:appDetails?.app_color ? appDetails?.app_color : 'gray' }} className='main_navicon'>
                        <Iconify className="navicon" id='main_icon' icon="heroicons:shopping-bag-20-solid" />
                    </div>
                    <div onClick={()=>setAppScreen('CART')} className='navbar_basic_icon'>
                    <span className='cart-icon-product-count' >2</span>
                    <Iconify className="navicon" icon="entypo:shopping-cart" />
                    </div>
                    <div onClick={()=>setAppScreen('ACCOUNT')} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="ic:sharp-account-circle" width={28} height={28} />
                    </div>
                    </div>
                </div>
            </div>
               }
               {/*======= APP CATEGORY SCREEN ==========*/}

                {/*=========== APP ORDER SCREEN ============ */}
               {appScreen == 'ORDER' &&
                 <div className='app-checkout-screen-container' >
                <div className='top_phone_things_baterry_network'>
                    <div className='left_items'>
                    <span>{time}</span>
                        <Iconify className='top_basic_item_icon' icon="carbon:logo-instagram" />
                        <Iconify className='top_basic_item_icon' icon="ic:baseline-whatsapp" />
                        <Iconify className='top_basic_item_icon' icon="mingcute:notification-fill" />
                    </div>
                    <div className='right_items'>
                    <Iconify className='top_basic_item_icon' icon="material-symbols:alarm-outline" />
                    <Iconify className='top_basic_item_icon' icon="mdi:network-favorite" />
                    <Iconify className='top_basic_item_icon' icon="game-icons:network-bars" />
                    <Iconify className='top_basic_item_icon' icon="ion:battery-half-outline" />

                    </div>
                </div>
                <div className='app_name_and_share_icon all-category-header'>
                    <div className='app_name'>
                        <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} >All Orders</h4>
                    </div>
                   <div className='app-top-share-chat-icon-box' >
                   <div className='share_btn'>
                    <Iconify style={{color:appDetails?.app_color,}} className='share_app_icon'  icon="material-symbols:support-agent" />
                    </div>
                    <div className='share_btn'>
                    <Iconify style={{color:appDetails?.app_color}} className='share_app_icon' icon="material-symbols:share" width={20} height={20} />
                    </div>
                   </div>
                </div>
                <div className='app-order-inner-main' >
                    <div className='app-order-box' >
                        <div className='app-order-detail-style' >
                            <div>
                                <p className='app-your-order' >#Your Order</p>
                                <p className='app-order-id' >ID : Order-id-7899-239-242</p>
                                <p className='app-order-id' >3 Products</p>
                            </div>
                            <div>
                                <p className='app-order-send-enquiry' >Send Enquiry</p>
                                <p className='app-order-cancel-btn' >Cancel Order</p>
                            </div>
                        </div>
                        <div>
                       <div className="app-order-image">
                        {appOrdersData?.map(category=> category?.category_name == appDetails?.app_type && category.order_1?.map(orderImg=> <img src={orderImg }  /> ))}
                       </div>
                       <div className='app-order-detail-style' style={{paddingTop:5}} >
                                <p className='app-order-id' >Order Status : </p>
                            <p className='app-order-id' style={{color:appDetails?.app_color}} >Shipped </p>
                        </div>
                       <div className='app-order-detail-style' >
                                <p className='app-order-id' >Order Date : </p>
                            <p className='app-order-id' >Wed, 11-02-2023</p>
                        </div>
                       <div className='app-order-detail-style' >
                                <p className='app-order-id' >Order Total : </p>
                            <p className='app-order-id' style={{color:appDetails?.app_color}} >₹13999 </p>
                        </div>
                        </div>
                    </div>
                    <div className='app-order-box' >
                        <div className='app-order-detail-style' >
                            <div>
                                <p className='app-your-order' >#Your Order</p>
                                <p className='app-order-id' >ID : Order-id-7219-239-409</p>
                                <p className='app-order-id' >3 Products</p>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div>
                       <div className="app-order-image">
                       {appOrdersData?.map(category=> category?.category_name == appDetails?.app_type && category.order_2?.map(orderImg=> <img src={orderImg }  /> ))}
                       </div>
                       <div className='app-order-detail-style' style={{paddingTop:5}} >
                                <p className='app-order-id' >Order Status : </p>
                            <p className='app-order-id' style={{color:appDetails?.app_color}} >Delivered </p>
                        </div>
                       <div className='app-order-detail-style' >
                                <p className='app-order-id' >Order Date : </p>
                            <p className='app-order-id' > Sun, 01-01-2023</p>
                        </div>
                       <div className='app-order-detail-style' >
                                <p className='app-order-id' >Order Total : </p>
                            <p className='app-order-id' style={{color:appDetails?.app_color}} >₹2999 </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='bottom_navbar'>
                    <div className='navbar_icons'>
                    <div onClick={()=>setAppScreen('HOME')}  className='navbar_basic_icon'>
                        <Iconify className="navicon" icon="ion:home" />
                    </div>
                    <div onClick={()=>setAppScreen('CATEGORY')} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="material-symbols:category" />
                    </div>
                    <div onClick={()=>setAppScreen('ORDER')} style={{backgroundColor:appDetails?.app_color ? appDetails?.app_color : 'gray' }} className='main_navicon'>
                        <Iconify className="navicon" id='main_icon' icon="heroicons:shopping-bag-20-solid" />
                    </div>
                    <div onClick={()=>setAppScreen('CART')} className='navbar_basic_icon'>
                    <span className='cart-icon-product-count' >2</span>
                    <Iconify className="navicon" icon="entypo:shopping-cart" />
                    </div>
                    <div onClick={()=>setAppScreen('ACCOUNT')} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="ic:sharp-account-circle" width={28} height={28} />
                    </div>
                    </div>
                </div>
            </div>
               }
               {/*======= APP ORDER SCREEN ==========*/}

                {/*=========== APP CART SCREEN ============ */}
               {appScreen == 'CART' &&
                 <div>
    
                <div className='top_phone_things_baterry_network'>
                    <div className='left_items'>
                    <span>{time}</span>
                        <Iconify className='top_basic_item_icon' icon="carbon:logo-instagram" />
                        <Iconify className='top_basic_item_icon' icon="ic:baseline-whatsapp" />
                        <Iconify className='top_basic_item_icon' icon="mingcute:notification-fill" />
                    </div>
                    <div className='right_items'>
                    <Iconify className='top_basic_item_icon' icon="material-symbols:alarm-outline" />
                    <Iconify className='top_basic_item_icon' icon="mdi:network-favorite" />
                    <Iconify className='top_basic_item_icon' icon="game-icons:network-bars" />
                    <Iconify className='top_basic_item_icon' icon="ion:battery-half-outline" />

                    </div>
                </div>
                <div className='app_name_and_share_icon my-cart-header '>
                    <div className='app_name'>
                        <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} >My Cart</h4>
                    </div>
    <Button 
     onClick={()=>setAppScreen('CHECKOUT')}
     style={{backgroundColor:appDetails?.app_color,boxShadow: `0px 4px 19px -6px ${appDetails?.app_color}`,}}
      variant="contained" endIcon={<Iconify icon="ic:baseline-arrow-right-alt" />}>Checkout</Button>
                </div>
               
              <div className='my-cart-inner-box' >
              {appCartData?.map(category  =>category?.category_name === appDetails?.app_type && category?.products?.map((p,index)=>(
                  <div className='my-cart-products-box' style={index == 1 ? {border:'none'} : {}}  >
                  <div className='my-cart-product-image' >
                 <img src={p?.image }  />
                 <div className='my-cart-product-detail-box' >
                  <p className='cart-product-name font-capitalize-case ' >{p?.name}</p>
                  <p className='cart-product-discount' >20% OFF</p>
                 <div className='cart-product-qty-box' > 
                 <p className='cart-product-quantity-minus' >-</p>
                  <span className='cart-qty' >1</span>
                  <p className='cart-product-quantity-plus' >+</p>
                 </div>
                 </div>
                 </div>
                 <div>
                 <p className='cart-product-regular-price' >₹6999</p>
                 <p className='cart-product-sale-price' style={{color:appDetails?.app_color}}  >₹3999</p>
                 <span className='cart-product-remove' >Remove</span>
                 </div>
                  </div>)
                    ))}
              <div className='my-cart-sub-total-box' >
                <div className='my-cart-sub-total' >
                    <p>Subtotal</p>
                    <p>₹7998</p>
                </div>
                <div className='my-cart-sub-total' >
                    <p>Delivery & Shipping</p>
                    <p>Free</p>
                </div>
                <div className='my-cart-payable-total' >
                    <p>Payable Total</p>
                    <p style={{color:appDetails?.app_color}} >₹7998</p>
                </div>
                <div className='my-cart-sub-btn' >
                <Button 
                onClick={()=>setAppScreen('CHECKOUT')}
                fullWidth  
                style={{backgroundColor:appDetails?.app_color,boxShadow: `0px 4px 19px -6px ${appDetails?.app_color}`,padding:'10px 0px'}} 
                variant="contained" endIcon={<Iconify icon="ic:baseline-arrow-right-alt" />}>Procced To Checkout</Button>
                </div>
               </div>
              </div>


                <div className='bottom_navbar'>
                    <div className='navbar_icons'>
                    <div onClick={()=>setAppScreen('HOME')}  className='navbar_basic_icon'>
                        <Iconify className="navicon" icon="ion:home" />
                    </div>
                    <div onClick={()=>setAppScreen('CATEGORY')} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="material-symbols:category" />
                    </div>
                    <div onClick={()=>setAppScreen('ORDER')} style={{backgroundColor:appDetails?.app_color ? appDetails?.app_color : 'gray' }} className='main_navicon'>
                        <Iconify className="navicon" id='main_icon' icon="heroicons:shopping-bag-20-solid" />
                    </div>
                    <div onClick={()=>setAppScreen('CART')} style={appScreen == 'CART' ? {color:appDetails?.app_color}:{}} className='navbar_basic_icon'>
                    <span className='cart-icon-product-count' >2</span>
                    <Iconify className="navicon" icon="entypo:shopping-cart" />
                    </div>
                    <div onClick={()=>setAppScreen('ACCOUNT')} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="ic:sharp-account-circle" width={28} height={28} />
                    </div>
                    </div>
                </div>
            </div>
               }
               {/*======= APP CART SCREEN ==========*/}

                {/*=========== APP ACCOUNT SCREEN ============ */}
               {appScreen == 'ACCOUNT' &&
                 <div>
                <div className='top_phone_things_baterry_network'>
                    <div className='left_items'>
                    <span>{time}</span>
                        <Iconify className='top_basic_item_icon' icon="carbon:logo-instagram" />
                        <Iconify className='top_basic_item_icon' icon="ic:baseline-whatsapp" />
                        <Iconify className='top_basic_item_icon' icon="mingcute:notification-fill" />
                    </div>
                    <div className='right_items'>
                    <Iconify className='top_basic_item_icon' icon="material-symbols:alarm-outline" />
                    <Iconify className='top_basic_item_icon' icon="mdi:network-favorite" />
                    <Iconify className='top_basic_item_icon' icon="game-icons:network-bars" />
                    <Iconify className='top_basic_item_icon' icon="ion:battery-half-outline" />

                    </div>
                </div>
                <div className='app_name_and_share_icon my-account-header'>
                    <div className='app_name'>
                        <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} > My Account</h4>
                    </div>
                   <div className='app-top-share-chat-icon-box' >
                   <div className='share_btn'>
                    <Iconify style={{color:appDetails?.app_color,}} className='share_app_icon'  icon="ic:baseline-search" />
                    </div>
                   </div>
                </div>

              <div className='my-account-user-profile-box' >
              <div className='my-account-profile-list' >
                <img src={imageImport?.myaccountprofile} width={52}  height={52} alt="" />
                    <p >Mayank Singh <p style={{color:appDetails?.app_color}} className='my-account-user-phone' >+91-8700880027</p> </p>
               </div>
               <Iconify style={{color:appDetails?.app_color}} className='my-account-user-arrow' icon="material-symbols:arrow-forward-ios-rounded"  />

              </div>
               <div className='my-account-list' >
                    <Iconify style={{color:appDetails?.app_color}} className='my-account-list-icon' icon="ion:information-circle-outline"  />
                    <p >About Us</p>
               </div>
               <div className='my-account-list' >
                    <Iconify style={{color:appDetails?.app_color}} className='my-account-list-icon' icon="ph:phone-call"  />
                    <p >Contact Us</p>
               </div>
               <div className='my-account-list' >
                    <Iconify style={{color:appDetails?.app_color}} className='my-account-list-icon' icon="mdi:security"  />
                    <p >Term & Conditions</p>
               </div>
               <div className='my-account-list' >
                    <Iconify style={{color:appDetails?.app_color}} className='my-account-list-icon' icon="mdi:security"  />
                    <p >Privacy Policy</p>
               </div>
               <div className='my-account-list' >
                    <Iconify style={{color:appDetails?.app_color}} className='my-account-list-icon' icon="ph:share-network"  />
                    <p >Share App</p>
               </div>
               <div className='my-account-list' style={{border:'none'}} >
                    <Iconify style={{color:appDetails?.app_color}} className='my-account-list-icon' icon="material-symbols:logout-rounded"  />
                    <p >Logout</p>
               </div>
               {/* <div className='my-account-logo-box' >
               <img src={ file && !file?.image_url ?  URL?.createObjectURL(file) :
                    appDetails?.app_logo?.image_url ? appDetails?.app_logo?.image_url :
                   storelogo2 } width={70}  height={70} />
               </div> */}
               {/* <p className='my-account-version' >Version : 1.1.0</p> */}

                <div className='bottom_navbar'>
                    <div className='navbar_icons'>
                    <div onClick={()=>setAppScreen('HOME')}  className='navbar_basic_icon'>
                        <Iconify className="navicon" icon="ion:home" />
                    </div>
                    <div onClick={()=>setAppScreen('CATEGORY')} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="material-symbols:category" />
                    </div>
                    <div onClick={()=>setAppScreen('ORDER')} style={{backgroundColor:appDetails?.app_color ? appDetails?.app_color : 'gray' }} className='main_navicon'>
                        <Iconify className="navicon" id='main_icon' icon="heroicons:shopping-bag-20-solid" />
                    </div>
                    <div onClick={()=>setAppScreen('CART')} className='navbar_basic_icon'>
                    <span className='cart-icon-product-count' >2</span>
                    <Iconify className="navicon" icon="entypo:shopping-cart" />
                    </div>
                    <div onClick={()=>setAppScreen('ACCOUNT')} style={appScreen == 'ACCOUNT' ? {color:appDetails?.app_color}:{}} className='navbar_basic_icon'>
                    <Iconify className="navicon" icon="ic:sharp-account-circle" width={28} height={28} />
                    </div>
                    </div>
                </div>
            </div>
               }
               {/*======= APP ACCOUNT SCREEN ==========*/}

                {/*=========== APP CHECKOUT SCREEN ============ */}
                {appScreen == 'CHECKOUT' &&
                 <div className='app-checkout-screen-container' >
                <div className='top_phone_things_baterry_network'>
                    <div className='left_items'>
                    <span>{time}</span>
                        <Iconify className='top_basic_item_icon' icon="carbon:logo-instagram" />
                        <Iconify className='top_basic_item_icon' icon="ic:baseline-whatsapp" />
                        <Iconify className='top_basic_item_icon' icon="mingcute:notification-fill" />
                    </div>
                    <div className='right_items'>
                    <Iconify className='top_basic_item_icon' icon="material-symbols:alarm-outline" />
                    <Iconify className='top_basic_item_icon' icon="mdi:network-favorite" />
                    <Iconify className='top_basic_item_icon' icon="game-icons:network-bars" />
                    <Iconify className='top_basic_item_icon' icon="ion:battery-half-outline" />

                    </div>
                </div>
                <div className='app_name_and_share_icon ' style={{display:'block'}} >
                    <div className='app-checkout-header'>
                    <p onClick={()=>setAppScreen('CART')} style={{cursor:'pointer',color:appDetails?.app_color}} ><Iconify  icon="ic:outline-arrow-back-ios-new" /></p>
                        <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} >Checkout</h4>
                        <h4 style={{color:'#fff'}} >asd</h4>
                    </div>
                </div>
              <div className='my-cart-inner-box' >
              {appCartData?.map(category  =>category?.category_name === appDetails?.app_type && category?.products?.map((p,index)=>(
                //   <div className='my-cart-products-box'  >
                  <div className='my-cart-products-box' style={index == 1 ? {border:'none'} : {}}  >
                  <div className='my-cart-product-image' >
                 <img src={p?.image }  />
                 <div className='my-cart-product-detail-box' >
                  <p className='cart-product-name font-capitalize-case' >{p?.name}</p>
                  <p className='cart-product-discount' >20% OFF</p>
                 <div className='cart-product-qty-box' > 
                 <p className='cart-product-quantity-minus' >-</p>
                  <span className='cart-qty' >1</span>
                  <p className='cart-product-quantity-plus' >+</p>
                 </div>
                 </div>
                 </div>
                 <div>
                 <p className='cart-product-regular-price' >₹6999</p>
                 <p className='cart-product-sale-price' style={{color:appDetails?.app_color}}  >₹3999</p>
                 {/* <span className='cart-product-remove' >Remove</span> */}
                 </div>
                  </div>)
                    ))}
                {/* <div className='my-cart-products-box' style={{border:'none'}}  >
                <div className='my-cart-product-image' >
               <img src={imageImport?.fashion_p_1 }  />
               <div className='my-cart-product-detail-box' >
                <p className='cart-product-name' >Mens Kurta</p>
                <p className='cart-product-discount' >30% OFF</p>
               <div className='cart-product-qty-box' > 
               <p className='cart-product-quantity-minus' >-</p>
                <span className='cart-qty' >1</span>
                <p className='cart-product-quantity-plus' >+</p>
               </div>
               </div>
               </div>
               <div>
               <p className='cart-product-regular-price' >₹6999</p>
               <p className='cart-product-sale-price' style={{color:appDetails?.app_color}}  >₹3999</p>
               </div>
                </div> */}
               <h4 style={{paddingBottom:5}} >Delivery Location</h4>
              <div className='my-checkout-delivery-box'  >
                <div className='checkout-delivery-your-address-box' >
                <Iconify sx={{color:appDetails?.app_color}} width={20} height={20} icon="ic:baseline-location-on" />
                <p>Your Address</p>
                </div>
                <div className='my-delivery-address-box'  >
                <div className='my-cart-product-image' >
                <Iconify sx={{color:appDetails?.app_color}} icon="ic:outline-radio-button-checked" />
               <div className='my-checkout-address-detail' >
                <p className='address-detail' >Mayank singh</p>
                <p className='address-detail' >+91-8700880027</p>
                <p className='address-detail' >singhmayank@gmail.com</p>
                <p className='address-detail' >Main Market, Delhi, 110020</p>
               </div>
               </div>
               <div className='delivery-address-edit-icon'  style={{color:appDetails?.app_color}} >
                <Iconify  icon="bxs:edit" /> <span style={{fontSize:12,fontWeight:600}} >Edit</span>
               </div>
                </div>
                
               </div>
               <h4 style={{paddingBottom:5,paddingTop:10}} >Payment Method</h4>
              <div className='my-checkout-sub-total-box'  >
                <div className='my-checkout-payment-method' >
                <Iconify sx={{color:appDetails?.app_color}} icon="ic:outline-radio-button-checked" />
                    <p>Cash on Delivery</p>
                </div>
               </div>
              <div className='my-checkout-sub-total-box' style={{marginTop:8}}  >
                <div className='my-checkout-payment-method' >
                <Iconify sx={{color:appDetails?.app_color}} icon="ic:outline-radio-button-checked" />
                    <p>Pay Online</p>
                </div>
               </div>
               <h4 style={{paddingBottom:5,paddingTop:10}} >Payment Summary</h4>
              <div className='my-checkout-sub-total-box'  >
                <div className='my-checkout-sub-total' >
                    <p>Subtotal</p>
                    <p>₹7998</p>
                </div>
                <div className='my-checkout-sub-total' >
                    <p>Delivery & Shipping</p>
                    <p>Free</p>
                </div>
                <div className='my-checkout-payable-total' >
                    <p>Payable Total</p>
                    <p style={{color:appDetails?.app_color}} >₹7998</p>
                </div>
               </div>
              </div>


                <div className='bottom_navbar'>
                <div className='my-cart-sub-btn' >
                <Button  fullWidth  style={{backgroundColor:appDetails?.app_color,boxShadow: `0px 4px 19px -6px ${appDetails?.app_color}`,padding:'10px 0px',marginBottom:-7}} variant="contained" startIcon={<Iconify icon="material-symbols:check-circle-rounded" />}>Pay Now{" "} ₹7998</Button>
                </div>

                </div>
            </div>
               }
               {/*======= APP CHECKOUT SCREEN ==========*/}

                {/*=========== APP SINGLE PRODUCT SCREEN ============ */}
                {appScreen == 'SINGLE_PRODUCT' &&
                 <div className='app-checkout-screen-container' >
                <div className='top_phone_things_baterry_network'>
                    <div className='left_items'>
                    <span>{time}</span>
                        <Iconify className='top_basic_item_icon' icon="carbon:logo-instagram" />
                        <Iconify className='top_basic_item_icon' icon="ic:baseline-whatsapp" />
                        <Iconify className='top_basic_item_icon' icon="mingcute:notification-fill" />
                    </div>
                    <div className='right_items'>
                    <Iconify className='top_basic_item_icon' icon="material-symbols:alarm-outline" />
                    <Iconify className='top_basic_item_icon' icon="mdi:network-favorite" />
                    <Iconify className='top_basic_item_icon' icon="game-icons:network-bars" />
                    <Iconify className='top_basic_item_icon' icon="ion:battery-half-outline" />

                    </div>
                </div>
                <div className='app_name_and_share_icon ' style={{display:'block'}} >
                    <div className='app-single-product-header'>
                    <div className='app-single-header-inner' >
                    <p onClick={()=>setAppScreen('HOME')} className='app-product-back-btn' style={{cursor:'pointer',color:appDetails?.app_color}} ><Iconify  icon="ic:outline-arrow-back-ios-new" /></p>
                        <h4 style={{color:appDetails?.app_color,textTransform:'capitalize'}} ></h4>
                        <div className='app-product-search-cart-icon' >
                        <p onClick={()=>setAppScreen('HOME')} className='app-product-back-btn' style={{cursor:'pointer',color:appDetails?.app_color}} ><Iconify width={20} height={20}   icon="ic:baseline-search" /></p>
                        <p onClick={()=>setAppScreen('CART')} className='app-product-cart-btn' style={{cursor:'pointer',color:appDetails?.app_color}} >
                            <Iconify width={20} height={20}  icon="mdi:cart-variant" />
                            <span className='app-product-icon-product-count' >2</span>
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
              <div className='app-product-screen-image' >
              <img src={appSingleProductScreen?.image }  />
              <span className='app-product-hottest-tag' >New Arrival</span>
              </div>
              <div className='app-product-detail-box' >
              <p className='cart-product-discount' style={{fontWeight:600}} >20% OFF</p>
              <p className='cart-product-name' style={{fontWeight:600}} >{appSingleProductScreen?.name}</p>
    
               <div className='app-product-qty-price-box' >
               <div className='app-product-qty-box' > 
               <p className='app-product-quantity-minus' >-</p>
                <span className='app-qty' >1</span>
                <p className='app-product-quantity-plus' >+</p>
               </div>
               <div>
               <p className='cart-product-regular-price' >₹6999</p>
               <p className='cart-product-sale-price' style={{color:appDetails?.app_color}}  >₹3999</p>
               </div>
               </div>
               <div className='app-color-choose-box' >
                <p style={{fontWeight:500,fontSize:14,paddingRight:8}} >Colors : </p>
               <p className='app-product-choose-color-outer' > <span className='app-choose-color' style={{backgroundColor:'red'}} ></span></p>
               <p className='app-product-choose-color-outer' > <span className='app-choose-color' style={{backgroundColor:'blue'}} ></span></p>
                  <p className='app-product-choose-color-outer' style={{border:`1px solid ${appDetails?.app_color} `}} ><span className='app-choose-color' style={{backgroundColor:'green'}} ></span></p>
                  <p className='app-product-choose-color-outer' ><span className='app-choose-color' style={{backgroundColor:'yellow'}} ></span></p>
                  <p className='app-product-choose-color-outer' ><span className='app-choose-color' style={{backgroundColor:'orange'}} ></span></p>
                  <p className='app-product-choose-color-outer' ><span className='app-choose-color' style={{backgroundColor:'black'}} ></span></p>
               </div>
               <div className='app-color-choose-box' >
                <p style={{fontWeight:500,fontSize:14,paddingRight:8}} >Sizes : </p>
                <p className='app-choose-size' >S</p>
                <p className='app-choose-size'style={{border:`1px solid ${appDetails?.app_color} `,color:appDetails?.app_color}} >M</p>
                <p className='app-choose-size' >L</p>
                <p className='app-choose-size' >XL</p>
                {/* <p className='app-choose-size' >XXL</p> */}
               </div>
               <div className='app-product-desc-box' >
                <p style={{fontWeight:600,fontSize:14}} >Product Description</p>
                <p style={{fontSize:12,color:'gray'}} >After your submition of form your app will be take upto 3-4 days for publishing.</p>
               </div>
                <div className='app-product-add-to-cart-btn' >
                <Button onClick={()=>setAppScreen('CART')}  fullWidth  style={{backgroundColor:appDetails?.app_color,boxShadow: `0px 4px 19px -6px ${appDetails?.app_color}`,padding:'12px 0px',marginBottom:-11}} variant="contained" startIcon={<Iconify icon="mdi:cart-variant" />}>Add To Cart</Button>

                </div>
              </div>
            </div>
               }
               {/*======= APP SINGLE PRODUCT SCREEN ==========*/}
            </div>
        </div>
    </div>

    </div>
  )
}

export default AppTemplate2