
import React,{useState,useRef} from 'react'
import {MenuItem,OutlinedInput,Chip,InputLabel,Checkbox,ListItemText,FormControl,Select, InputAdornment, Tooltip, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from 'src/components/Iconify';
import {Button,IconButton} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import VideoModal from 'src/global/Modals/VideoModal';
import Imagecompressor from './Imagecompressor';


function ImageCompressorPage() {
    const [ loading, setLoading  ] =useState(false)
    const [snackbarOpen,setSnackbarOpen ] = useState(false)
    const [ render, setRender ] = useState(false)
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const {authState} = UseContextState()
    const navigate = useNavigate()
    const pdfExportComponent = useRef(null);

    const handleExportWithComponent = event => {
      pdfExportComponent.current.save();
    };

    // ##################### SNACK BAR FUNCTIONs ##################
const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };
  // ##################### SNACK BAR FUNCTIONs ##################

    // handle close video modal
    function handleCloseVideoModal(){
        setOpenVideoModal(false)
      }
      
    
      // handle open video modal
      function handleOpenVideoModal(){
        setOpenVideoModal(true)
      }

  return (
    <div className='custom-conatiner'>
    <VideoModal title='Learn How To Compress Images?' video_url="https://www.youtube.com/embed/IvKHkM17rvQ" isOpen={openVideoModal} handleClose={handleCloseVideoModal} /> 

          {/* #################### LOADING SPINNER ######################## */}
      <Backdrop
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    {/* #################### LOADING SPINNER ######################## */}

 
 <div className="banner-image-guide-box order_page_video order_view_page_video">
 <Button onClick={handleOpenVideoModal} variant="text" className='image-guide-btn-text' startIcon={<Iconify icon="logos:youtube-icon" />}>Learn How it Works</Button>
</div>
<Paper elevation={4} >
<div className='product-conatiner'>
<div className='inner-page-heading-box' >
        <IconButton sx={{color:'black'}}  onClick={()=>navigate(-1)} >
        <Iconify icon="material-symbols:arrow-back-rounded" />
        </IconButton>
     <div>
     <h2>Image Compressor</h2>
      {/* <p>  Add your products for your app</p> */}
     </div>
      </div>

      <Imagecompressor />
</div>
</Paper>
        
    </div>
  )
}

export default ImageCompressorPage