import React, { useEffect, useState,useCallback } from 'react'
import Iconify from 'src/components/Iconify';
import planimg from '../assests/shapes.png';
import storelogo from "../assests/uploadimg.png";
import storelogo2 from "../assests/uploadlogo.png";
import Setting_icon from "../assests/settings icon.png"
import LoadingSpinner from 'src/components/Spinner';
import Setting_icon2 from "../assests/settings.png"
import { FileUploader } from "react-drag-drop-files";
import { InputAdornment,Container,TextField,Button, MenuItem, ListItemText, Select, Tooltip } from '@mui/material';
import { editable_config } from 'src/editable_config';
import SaveAndCancelModal from 'src/global/Modals/SaveAndCancelModal';
import LogoFileUploadDesgin from 'src/components/common/LogoFileUploadDesgin';
import { toast, ToastContainer } from 'react-toastify';
import { deleteImageFromFirebase, uploadFileToFirebase } from 'src/global/globalFunctions';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import CategoryIcon from '@mui/icons-material/Category';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AppProductCard from './app_screen_components/AppProductCard';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import axios from 'axios';
import imageImport from 'src/utils/imageImport';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import AppBannerSlider from './app_screen_components/AppBannerSlider';
import PopupModal from 'src/global/Modals/PopupModal';
import PublishingError from './modal_components/PublishingError';
import BuyCredit from './modal_components/BuyCredit';
import SuccessPayment from './modal_components/SuccessPayment';
import BuildSuccess from './modal_components/BuildSuccess';
import { useNavigate } from 'react-router-dom';
import VideoModal from './modal_components/VideoModal';
import AppStepper from '../layouts/dashboard/AppStepper';
import AppTemplate1 from './app_templates/AppTemplate1';
import AppTemplate2 from './app_templates/AppTemplate2';
import AppTemplate3 from './app_templates/AppTemplate3';
import AppTemplate4 from './app_templates/AppTemplate4';
import AppTemplate5 from './app_templates/AppTemplate5';
import CustomizedSnackbars from 'src/global/Snackbar/CustomSnackbar';

function StoreSetting() {
  const [ openDeleteConfimModal, setOpenDeleteConfimModal ] = useState(false)
  const [ openCreditExpireErrorModal, setOpenCreditExpireErrorModal ] = useState(false)
  const [ openPaymentSuccessPopupModal, setOpenPaymentSuccessPopupModal ] = useState(false)
  const [ openSubmitDetailSuccessPopupModal, setOpenSubmitDetailSuccessPopupModal ] = useState(false)
  const [ buttonLoading, setButtonLoading ] = useState(false)
  const [message ,setMessage] = useState({type:"",message:""})
  const [snackbarOpen,setSnackbarOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false)
  const [ time, setTime ] = useState('')
  const [ render, setRender ] = useState(false)
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [ file, setFile ] = useState(null);
  const [appDetails , setAppDetails ] = useState({app_name:'',email:'',app_type:'',app_color:'',app_template:'',address:''})
  const [appScreen , setAppScreen ] = useState('HOME')
  const [appSingleProductScreen , setAppSingleProductScreen ] = useState()
  const {authState,fetchAuthuser,getStepperDetails} = UseContextState()
  const navigate = useNavigate()

  const fileTypes = ["JPG", "JPEG","PNG"];
  
  console.log("appScreenappScreenappScreenappScreenappScreenappScreen",appScreen);

//======= app screen data  ========
// [imageImport.fashion_p_1,imageImport.fashion_p_2,imageImport.fashion_p_3,imageImport.fashion_p_4,imageImport.fashion_p_5,imageImport.fashion_p_6,]

// app home screen products data
const appproductData = [
    {category_name:'garments store',products:[{name:'New Mens Shirt',category:'top wear',image:imageImport.mens_p_1},{name:'women blue jacket',category:'casual wear',image:imageImport.women_p_6},{name:'blue jeans',category:'bottom wear',image:imageImport.women_p_4},{name:'sweat shirt',category:'winter wear',image:imageImport.mens_p_5},{name:'yellow short jacket',category:'winter wear',image:imageImport.women_p_5},{name:'white lingri',category:'hot wear',image:imageImport.hot_p_2},{name:'full sleeve t-shirt',category:'party wear',image:imageImport.women_p_7},{name:'Mens blue Coat',category:'party wear',image:imageImport.mens_p_7},{name:'women red top',category:'top wear',image:imageImport.women_p_11},{name:'Sports bra',category:'sports wear',image:imageImport.hot_p_1}]},
    {category_name:'mens fashion',products:[{name:'New Mens Shirt',category:'top wear',image:imageImport.mens_p_1},{name:'New mens jacket',category:'party wear',image:imageImport.mens_p_11},{name:'Mens hoodie',category:'top wear',image:imageImport.mens_p_3},{name:'Mens coat',category:'party wear',image:imageImport.mens_p_4},{name:'sweat shirt',category:'winter wear',image:imageImport.mens_p_5},{name:'Mens Coat',category:'office wear',image:imageImport.mens_p_6},{name:'Mens blue Coat',category:'party wear',image:imageImport.mens_p_7},{name:'Mens long coat',category:'casual wear',image:imageImport.mens_p_8},,{name:'Mens green shirt',category:'party wear',image:imageImport.mens_p_9},{name:'Mens winter coat',category:'party wear',image:imageImport.mens_p_10}]},
    {category_name:'womens fashion',products:[{name:'women red top',category:'top wear',image:imageImport.women_p_11},{name:'women blue jacket',category:'casual wear',image:imageImport.women_p_6},{name:'casual coat pant',category:'casual wear',image:imageImport.women_p_3},{name:'blue jeans',category:'bottom wear',image:imageImport.women_p_4},{name:'yellow short jacket',category:'winter wear',image:imageImport.women_p_5},{name:'white t-shirt',category:'casual wear',image:imageImport.women_p_16},{name:'full sleeve t-shirt',category:'party wear',image:imageImport.women_p_7},{name:'women black shirt',category:'party wear',image:imageImport.women_p_8},{name:'white t-shirt',category:'casual wear',image:imageImport.women_p_15},{name:'yellow shirt',category:'party wear',image:imageImport.women_p_10}]},
    {category_name:'womens innerwear',products:[{name:'Sports bra',category:'sports wear',image:imageImport.hot_p_1},{name:'New pink Bikini set',category:'inner wear',image:imageImport.hot_p_11},{name:'red swim suit',category:'swim wear',image:imageImport.hot_p_3},{name:'orange swim suit',category:'swim wear',image:imageImport.hot_p_4},{name:'white lingri',category:'hot wear',image:imageImport.hot_p_2},{name:'white bikini set',category:'couple wear',image:imageImport.hot_p_6},{name:'red night bikini',category:'trending',image:imageImport.hot_p_7},{name:'beach bikini',category:'hottest',image:imageImport.hot_p_8},,{name:'beach hot bikini',category:'swim wear',image:imageImport.hot_p_9},{name:'couple night bikini',category:'party wear',image:imageImport.hot_p_10}]},
    {category_name:'jewellery',products:[{name:'Kundan set',category:'party wear',image:imageImport.jewellery_p_3},{name:'New kundan top',category:'party wear',image:imageImport.jewellery_p_2},{name:'long kundan set',category:'daily wear',image:imageImport.jewellery_p_1},{name:'Ring & top set',category:'all in one',image:imageImport.jewellery_p_4},{name:'chain with pendal',category:'classic',image:imageImport.jewellery_p_5},{name:'new earings',category:'party wear',image:imageImport.jewellery_p_6},{name:'Moti mala',category:'daily wear',image:imageImport.jewellery_p_7},{name:'diamond ring',category:'couple ring',image:imageImport.jewellery_p_8},{name:'chokar moti set',category:'royal set',image:imageImport.jewellery_p_9},{name:'Silver ring',category:'party wear',image:imageImport.jewellery_p_11}]},
    {category_name:'footwear',products:[{name:'red sneakers',category:'casual wear',image:imageImport.shoes_p_3},{name:'New formal shoes',category:'office wear',image:imageImport.shoes_p_4},{name:'sports shoes',category:'sports wear',image:imageImport.shoes_p_9},{name:'blue sneakers',category:'casual wear',image:imageImport.shoes_p_10},{name:'white sneakers',category:'classic',image:imageImport.shoes_p_5},{name:'black sneakers',category:'casual wear',image:imageImport.shoes_p_6},{name:'brown formal shoes',category:'party wear',image:imageImport.shoes_p_7},{name:'formal shoes',category:'office wear',image:imageImport.shoes_p_8},{name:'high soal sneakers',category:'royal set',image:imageImport.shoes_p_2},{name:'sports shoes',category:'running shoes',image:imageImport.shoes_p_1}]},
    {category_name:'beauty & care',products:[{name:'red lip balm',category:'casual',image:imageImport.beauty_p_1},{name:'peach color lipstick',category:'office',image:imageImport.beauty_p_2},{name:'peach lip balm',category:'party',image:imageImport.beauty_p_3},{name:'kajal',category:'eye care',image:imageImport.beauty_p_4},{name:'maskaara',category:'classic',image:imageImport.beauty_p_5},{name:'lakme lipstick',category:'for the bold',image:imageImport.beauty_p_6},{name:'4 lipstick combo',category:'party wear',image:imageImport.beauty_p_7},{name:'magic maroon',category:'party',image:imageImport.beauty_p_8},{name:'foundation',category:'for royals',image:imageImport.beauty_p_9},{name:'red velvet lipstick',category:'for the bold',image:imageImport.beauty_p_10}]},
    {category_name:'furniture',products:[{name:'2 chair set',category:'dinning',image:imageImport.furniture_p_1},{name:'luxary chair',category:'for royals',image:imageImport.furniture_p_2},{name:'wooden chair',category:'garden chair',image:imageImport.furniture_p_3},{name:'dinning chairs',category:'kitchen',image:imageImport.furniture_p_4},{name:'pink velvet chair',category:'classic',image:imageImport.furniture_p_5},{name:'matte blue chair',category:'for the bold',image:imageImport.furniture_p_6},{name:'double bed',category:'bedroom',image:imageImport.furniture_p_7},{name:'pinkis chair',category:'party',image:imageImport.furniture_p_8},{name:'wooden chair',category:'for garden',image:imageImport.furniture_p_9},{name:'gray wooden chair',category:'for office',image:imageImport.furniture_p_10}]},
    {category_name:'home decors',products:[{name:'twinkle lighting',category:'decoration',image:imageImport.decoration_p_10},{name:'wooden lights',category:'for royals',image:imageImport.decoration_p_9},{name:'outer lights',category:'home',image:imageImport.decoration_p_8},{name:'outer lamps',category:'home decoration',image:imageImport.decoration_p_7},{name:'new chair',category:'classic',image:imageImport.decoration_p_5},{name:'decorative stand',category:'for the bold',image:imageImport.decoration_p_6},{name:'lamp for table',category:'bedroom',image:imageImport.decoration_p_4},{name:'decorative pots',category:'party',image:imageImport.decoration_p_3},{name:'white vas',category:'for living room',image:imageImport.decoration_p_2},{name:'3 wall frames',category:'for office',image:imageImport.decoration_p_1}]},
    {category_name:'electronics',products:[{name:'black headphones',category:'with high bass',image:imageImport.electronics_p_1},{name:'new ear pods',category:'for rocking',image:imageImport.electronics_p_2},{name:'new Air pods',category:'luxary',image:imageImport.electronics_p_3},{name:'honor ear pods',category:'with 2x sounds',image:imageImport.electronics_p_4},{name:'wired earphones',category:'2x length',image:imageImport.electronics_p_5},{name:'new vr box',category:'for experince better',image:imageImport.electronics_p_6},{name:'waterproof watch',category:'hand',image:imageImport.electronics_p_7},{name:'led watch',category:'casual',image:imageImport.electronics_p_8},{name:'smart watch',category:'for living',image:imageImport.electronics_p_9},{name:'digital watch',category:'office wear',image:imageImport.electronics_p_10}]},
    {category_name:'fruits & veggies',products:[{name:'1 dozen banana',category:'healty',image:imageImport.fruits_p_1},{name:'fresh watermelon',category:'fruit',image:imageImport.fruits_p_2},{name:'pinneapple',category:'fruit',image:imageImport.fruits_p_3},{name:'potatos',category:'vegitable',image:imageImport.fruits_p_4},{name:'tomatos',category:'veggies',image:imageImport.fruits_p_5},{name:'strawberry',category:'fruits',image:imageImport.fruits_p_6},{name:'apples',category:'fruits',image:imageImport.fruits_p_7},{name:'avocados',category:'fruits',image:imageImport.fruits_p_8},{name:'cabbages',category:'for living',image:imageImport.fruits_p_11},{name:'broccli',category:'veggies',image:imageImport.fruits_p_10}]},
    {category_name:'stationery',products:[{name:'blue ball pen',category:'writting',image:imageImport.stationery_p_1},{name:'sprill notebook',category:'notebook',image:imageImport.stationery_p_2},{name:'3 blue pen set',category:'wrtting',image:imageImport.stationery_p_3},{name:'gray polished holder',category:'arranging',image:imageImport.stationery_p_4},{name:'net pen holder',category:'arranging',image:imageImport.stationery_p_5},{name:'printed pen holder',category:'classic',image:imageImport.stationery_p_6},{name:'wooden pen holder',category:'arranging',image:imageImport.stationery_p_7},{name:'fine pages notebook',category:'writting',image:imageImport.stationery_p_8},{name:'pencil colors',category:'drawing',image:imageImport.stationery_p_9},{name:'notebook set',category:'writitng',image:imageImport.stationery_p_10}]},
    {category_name:'baby & care',products:[{name:'baby blush shoes',category:'footwear',image:imageImport.baby_p1},{name:'blue baby shoes',category:'footwaer',image:imageImport.baby_p2},{name:'baby sneakers',category:'footwear',image:imageImport.baby_p3},{name:'teddy bear',category:'playing',image:imageImport.baby_p4},{name:'l size teddy bear',category:'playing',image:imageImport.baby_p5},{name:'baby cycle',category:'moving around',image:imageImport.baby_p6},{name:'baby monkey set',category:'soft toys',image:imageImport.baby_p7},{name:'toy car',category:'baby toys',image:imageImport.baby_p8},{name:'baby care set',category:'dress & care',image:imageImport.baby_p9},{name:'baby pyjamas',category:'bottom wear',image:imageImport.baby_p10}]},
    {category_name:'gym accessories',products:[{name:'blue Shaker bottle',category:'shakers',image:imageImport.gym_p9},{name:'5.5 lbs dumbbles',category:'gym dumbbles',image:imageImport.gym_p10},{name:'7 lbs dumbbles',category:'gym dumbbles',image:imageImport.gym_p3},{name:'2.2 lbs dumbbles',category:'gym dumbbles',image:imageImport.gym_p4},{name:'12 lbs weight plate',category:'weights',image:imageImport.gym_p5},{name:'gym accessories set',category:'combo',image:imageImport.gym_p6},{name:'3 lbs dumbbles',category:'gym dumbbles',image:imageImport.gym_p7},{name:'water bottle',category:'accessories',image:imageImport.gym_p8},{name:'5 lbs dumbbles',category:'gym dumbbles',image:imageImport.gym_p2},{name:'10 lbs weights',category:'powerlifting',image:imageImport.gym_p1}]},
    {category_name:'grocery',products:[{name:'mixed Pulses',category:'pulses',image:imageImport.grocery_p1},{name:'milk',category:'food',image:imageImport.grocery_p12},{name:'rosted coffee',category:'food',image:imageImport.grocery_p3},{name:'dawat rice',category:'food',image:imageImport.grocery_p4},{name:'eggs',category:'breakfast',image:imageImport.grocery_p5},{name:'cookiees',category:'classic',image:imageImport.grocery_p6},{name:'chicken peas',category:'food',image:imageImport.grocery_p7},{name:'1 ltr milk',category:'drinking',image:imageImport.grocery_p8},{name:'walnuts',category:'dry fruits',image:imageImport.grocery_p9},{name:'badam',category:'dry fruits',image:imageImport.grocery_p10}]},
    {category_name:'sports',products:[{name:'cosco ball',category:'outdoor',image:imageImport.sports_p12},{name:'cricket ball',category:'outdoor',image:imageImport.sports_p2},{name:'white table tennies',category:'indoor',image:imageImport.sports_p3},{name:'shuttle cock',category:'accessories',image:imageImport.sports_p4},{name:'table tennies',category:'indoor',image:imageImport.sports_p5},{name:'Rackets pair',category:'classic',image:imageImport.sports_p6},{name:'baseball ball ',category:'outdoor',image:imageImport.sports_p11},{name:'basket ball',category:'outdoor',image:imageImport.sports_p8},{name:'football',category:'outdoor',image:imageImport.sports_p9},{name:'golf ball',category:'classic game',image:imageImport.sports_p10}]},
    {category_name:'healthcare',products:[{name:'Glucometers',category:'health tracking',image:imageImport.health_p1},{name:'sphygmomanometer',category:'health tracking',image:imageImport.health_p2},{name:'face serum',category:'beauty',image:imageImport.health_p3},{name:'multivitamins',category:'health',image:imageImport.health_p4},{name:'gastro plus',category:'stomach',image:imageImport.health_p5},{name:'multivitamin cap',category:'daily routine',image:imageImport.health_p6},{name:'asthalin cap',category:'asthma',image:imageImport.health_p7},{name:'multivitamins',category:'health',image:imageImport.health_p8},{name:'pulmoclear',category:'lungs care',image:imageImport.health_p9},{name:'omega 3',category:'daily routine',image:imageImport.health_p10}]},
    {category_name:'other',products:[{name:'honor ear pods',category:'with 2x sounds',image:imageImport.electronics_p_4},{name:'twinkle lighting',category:'decoration',image:imageImport.decoration_p_10},{name:'women blue jacket',category:'casual wear',image:imageImport.women_p_6},{name:'blue jeans',category:'bottom wear',image:imageImport.women_p_4},{name:'red swim suit',category:'swim wear',image:imageImport.hot_p_3},{name:'Silver ring',category:'party wear',image:imageImport.jewellery_p_11},{name:'black sneakers',category:'casual wear',image:imageImport.shoes_p_6},{name:'pinkis chair',category:'party',image:imageImport.furniture_p_8}]},
]

// app banners Data
const appBannerData = [
    {category_name:'garments store',banner:[imageImport.menfashion_banner1,imageImport.hot_banner2,imageImport.women_banner1]},
    {category_name:'mens fashion',banner:[imageImport.menfashion_banner2,imageImport.menfashion_banner1,imageImport.menfashion_banner3]},
    // {category_name:'womens fashion',banner:[imageImport.newbanner1]},
    {category_name:'womens fashion',banner:[imageImport.women_banner1,imageImport.women_banner2,imageImport.women_banner3,imageImport.women_banner4,]},
    {category_name:'womens innerwear',banner:[imageImport.hot_banner2,imageImport.hot_banner3,imageImport.hot_banner1]},
    {category_name:'jewellery',banner:[imageImport.jewellery_banner1,imageImport.jewellery_banner2,imageImport.jewellery_banner3]},
    {category_name:'footwear',banner:[imageImport.shoes_banner1,imageImport.shoes_banner2,imageImport.shoes_banner3]},
    {category_name:'beauty & care',banner:[imageImport.beauty_banner1,imageImport.beauty_banner2,imageImport.beauty_banner3]},
    {category_name:'furniture',banner:[imageImport.furniture_banner1,imageImport.furniture_banner2,imageImport.furniture_banner3]},
    {category_name:'home decors',banner:[imageImport.homedecor_banner1,imageImport.homedecor_banner2,imageImport.homedecor_banner3]},
    {category_name:'electronics',banner:[imageImport.electronic_banner1,imageImport.electronic_banner2,imageImport.electronic_banner3]},
    {category_name:'fruits & veggies',banner:[imageImport.grocery_banner2,imageImport.grocery_banner3,imageImport.grocery_banner1]},
    {category_name:'stationery',banner:[imageImport.stationary_banner1,imageImport.stationary_banner2,imageImport.stationary_banner3]},
    {category_name:'baby & care',banner:[imageImport.baby_banner1,imageImport.baby_banner2,imageImport.baby_banner3]},
    {category_name:'gym accessories',banner:[imageImport.gym_banner1,imageImport.gym_banner2,imageImport.gym_banner3]},
    {category_name:'grocery',banner:[imageImport.grocery_banner4,imageImport.grocery_banner5,imageImport.grocery_banner6]},
    {category_name:'sports',banner:[imageImport.sports_banner1,imageImport.sports_banner2,imageImport.sports_banner3]},
    {category_name:'healthcare',banner:[imageImport.medical_banner1,imageImport.medical_banner2]},
    {category_name:'other',banner:[imageImport.shoes_banner3,imageImport.sports_banner2,imageImport.jewellery_banner3]},
]
// app HOME SCREEN CATEGORIES Data (4 categories for home screen)
const appHomeScreenCategoryData = [
    {category_name:'garments store',category_image:[{name:'sweaters',image:imageImport.cate_women_p_9},{name:'jacket',image:imageImport.cate_mens_p_12},{name:'sweaters',image:imageImport.cate_women_p_10},{name:'polo shirt',image:imageImport.cate_mens_p_7}]},
    {category_name:'mens fashion',category_image:[{name:'hoodie',image:imageImport.cate_mens_p_11},{name:'jacket',image:imageImport.cate_mens_p_12},{name:'jeans',image:imageImport.cate_mens_p_2},{name:'polo shirt',image:imageImport.cate_mens_p_7}]},
    {category_name:'womens fashion',category_image:[{name:'sweaters',image:imageImport.cate_women_p_9},{name:'coats',image:imageImport.cate_women_p_10},{name:'t-shirts',image:imageImport.cate_women_p_3},{name:'sweatshirt',image:imageImport.cate_women_p_7}]},
    {category_name:'womens innerwear',category_image:[{name:'nighty',image:imageImport.cate_hot_p_7},{name:'panty',image:imageImport.cate_hot_p_3},{name:'lingri',image:imageImport.cate_hot_p_5},{name:'bikini',image:imageImport.cate_hot_p_4}]},
    {category_name:'jewellery',category_image:[{name:'earings',image:imageImport.cate_jewellery_p_1},{name:'silver rings',image:imageImport.cate_jewellery_p_3},{name:'pendant',image:imageImport.cate_jewellery_p_8},{name:'chain',image:imageImport.cate_jewellery_p_6}]},
    {category_name:'footwear',category_image:[{name:'formals',image:imageImport.cate_shoes_p_5},{name:'sports',image:imageImport.cate_shoes_p_2},{name:'casual',image:imageImport.cate_shoes_p_3},{name:'sneakers',image:imageImport.cate_shoes_p_4}]},
    {category_name:'beauty & care',category_image:[{name:'facepacks',image:imageImport.cate_beauty_p_5},{name:'foundation',image:imageImport.cate_beauty_p_2},{name:'maskaara',image:imageImport.cate_beauty_p_3},{name:'shampoo',image:imageImport.cate_beauty_p_9}]},
    {category_name:'furniture',category_image:[{name:'sofas',image:imageImport.cate_furniture_p_1},{name:'chair',image:imageImport.cate_furniture_p_2},{name:'stool',image:imageImport.cate_furniture_p_11},{name:'bed',image:imageImport.cate_furniture_p_4}]},
    {category_name:'home decors',category_image:[{name:'frames',image:imageImport.cate_decoration_p_1},{name:'flowers',image:imageImport.cate_decoration_p_5},{name:'pots',image:imageImport.cate_decoration_p_3},{name:'lights',image:imageImport.cate_decoration_p_4}]},
    {category_name:'electronics',category_image:[{name:'coolers',image:imageImport.cate_electronics_p_6},{name:'earbuds',image:imageImport.cate_electronics_p_2},{name:'mobiles',image:imageImport.cate_electronics_p_3},{name:'laptops',image:imageImport.cate_electronics_p_4}]},
    {category_name:'fruits & veggies',category_image:[{name:'cabbage',image:imageImport.cate_fruits_p_1},{name:'avocado',image:imageImport.cate_fruits_p_8},{name:'fruits',image:imageImport.cate_fruits_p_6},{name:'broccoli',image:imageImport.cate_fruits_p_4}]},
    {category_name:'stationery',category_image:[{name:'notebook',image:imageImport.cate_stationery_p_1},{name:'pen holder',image:imageImport.cate_stationery_p_2},{name:'colors',image:imageImport.cate_stationery_p_3},{name:'drawing',image:imageImport.cate_stationery_p_4}]},
    {category_name:'baby & care',category_image:[{name:'baby cart',image:imageImport.cate_babycare_p_5},{name:'baby bowls',image:imageImport.cate_babycare_p_2},{name:'baby clothes',image:imageImport.cate_babycare_p_3},{name:'baby shoes',image:imageImport.cate_babycare_p_4}]},
    {category_name:'gym accessories',category_image:[{name:'dumbbles',image:imageImport.cate_gym_p_1},{name:'accessories',image:imageImport.cate_gym_p_2},{name:'machines',image:imageImport.cate_gym_p_9},{name:'weights',image:imageImport.cate_gym_p_5}]},
    {category_name:'grocery',category_image:[{name:'dairy',image:imageImport.cate_grocery_p_1},{name:'dryfruits',image:imageImport.cate_grocery_p_2},{name:'pulses',image:imageImport.cate_grocery_p_3},{name:'essentials',image:imageImport.cate_grocery_p_7}]},
    {category_name:'sports',category_image:[{name:'accessories',image:imageImport.cate_sports_p_1},{name:'football',image:imageImport.cate_sports_p_5},{name:'indoor',image:imageImport.cate_sports_p_3},{name:'outdoor',image:imageImport.cate_sports_p_8}]},
    {category_name:'healthcare',category_image:[{name:'capsules',image:imageImport.cate_health_p_1},{name:'multivitamin',image:imageImport.cate_health_p_2},{name:'tablets',image:imageImport.cate_health_p_3},{name:'syringe',image:imageImport.cate_health_p_4}]},
    {category_name:'other',category_image:[{name:'earings',image:imageImport.cate_jewellery_p_1},{name:'lights',image:imageImport.cate_decoration_p_4},{name:'earbuds',image:imageImport.cate_electronics_p_2},{name:'polo shirt',image:imageImport.cate_mens_p_7}]},
]
// app CATEGORIES SCREEN Data (12 categories for categories screen)
const appCategoryScreenData = [
    {category_name:'garments store',category_image:[{name:'hoodie',image:imageImport.cate_mens_p_11},{name:'jeans',image:imageImport.cate_mens_p_2},{name:'shirts',image:imageImport.cate_women_p_2},{name:'polo shirt',image:imageImport.cate_mens_p_5},{name:'bikini set',image:imageImport.cate_hot_p_4},{name:'t-shirts',image:imageImport.cate_women_p_3},{name:'shirts',image:imageImport.cate_mens_p_7},{name:'longline',image:imageImport.cate_hot_p_7},{name:'full shirt',image:imageImport.cate_mens_p_9},{name:'half sleeve',image:imageImport.cate_mens_p_10},{name:'jogger',image:imageImport.cate_mens_p_1},,{name:'jacket',image:imageImport.cate_mens_p_12},]},
    {category_name:'mens fashion',category_image:[{name:'hoodie',image:imageImport.cate_mens_p_11},{name:'jeans',image:imageImport.cate_mens_p_2},{name:'T-shirts',image:imageImport.cate_mens_p_4},{name:'polo shirt',image:imageImport.cate_mens_p_5},{name:'pants',image:imageImport.cate_mens_p_3},{name:'sweat shirt',image:imageImport.cate_mens_p_6},{name:'shirts',image:imageImport.cate_mens_p_7},{name:'plan shirt',image:imageImport.cate_mens_p_8},{name:'full shirt',image:imageImport.cate_mens_p_9},{name:'half sleeve',image:imageImport.cate_mens_p_10},{name:'jogger',image:imageImport.cate_mens_p_1},,{name:'jacket',image:imageImport.cate_mens_p_12},]},
    {category_name:'womens fashion',category_image:[{name:'shorts',image:imageImport.cate_women_p_1},{name:'shirts',image:imageImport.cate_women_p_2},{name:'t-shirts',image:imageImport.cate_women_p_3},{name:'pants',image:imageImport.cate_women_p_4},{name:'bikini',image:imageImport.cate_women_p_5},{name:'jackets',image:imageImport.cate_women_p_6},{name:'sweatshirt',image:imageImport.cate_women_p_7},{name:'jeans',image:imageImport.cate_women_p_8},{name:'sweaters',image:imageImport.cate_women_p_9},{name:'coats',image:imageImport.cate_women_p_10},{name:'party wear',image:imageImport.cate_women_p_11},{name:'suits',image:imageImport.cate_women_p_12}]},
    {category_name:'womens innerwear',category_image:[{name:'panty',image:imageImport.cate_hot_p_3},{name:'sports bra',image:imageImport.cate_hot_p_2},{name:'yoga bra',image:imageImport.cate_hot_p_1},{name:'bikini set',image:imageImport.cate_hot_p_4},{name:'lingri set',image:imageImport.cate_hot_p_5},{name:'cotton panty',image:imageImport.cate_hot_p_6},{name:'longline',image:imageImport.cate_hot_p_7},{name:'mexi',image:imageImport.cate_hot_p_8},{name:'nighty',image:imageImport.cate_hot_p_9},{name:'hipster',image:imageImport.cate_hot_p_10},{name:'nightwear',image:imageImport.cate_hot_p_11},{name:'lingri',image:imageImport.cate_hot_p_12}]},
    {category_name:'jewellery',category_image:[{name:'earings',image:imageImport.cate_jewellery_p_1},{name:'bangals',image:imageImport.cate_jewellery_p_12},{name:'silver ring',image:imageImport.cate_jewellery_p_3},{name:'gold ring',image:imageImport.cate_jewellery_p_4},{name:'pendant',image:imageImport.cate_jewellery_p_5},{name:'chains',image:imageImport.cate_jewellery_p_6},{name:'crystal',image:imageImport.cate_jewellery_p_7},{name:'silver chain',image:imageImport.cate_jewellery_p_8},{name:'gold chain',image:imageImport.cate_jewellery_p_9},{name:'neckless',image:imageImport.cate_jewellery_p_10},{name:'kaanchains',image:imageImport.cate_jewellery_p_11},{name:'jhumka',image:imageImport.cate_jewellery_p_2}]},
    {category_name:'footwear',category_image:[{name:'boot',image:imageImport.cate_shoes_p_1},{name:'sports',image:imageImport.cate_shoes_p_2},{name:'casual',image:imageImport.cate_shoes_p_3},{name:'sneakers',image:imageImport.cate_shoes_p_4},{name:'formals',image:imageImport.cate_shoes_p_5},{name:'slippers',image:imageImport.cate_shoes_p_6},{name:'party wear',image:imageImport.cate_shoes_p_7},{name:'falts',image:imageImport.cate_shoes_p_8},{name:'running',image:imageImport.cate_shoes_p_9},{name:'sandals',image:imageImport.cate_shoes_p_10},{name:'lofars',image:imageImport.cate_shoes_p_11},{name:'regular',image:imageImport.cate_shoes_p_12}]},
    {category_name:'beauty & care',category_image:[{name:'brush',image:imageImport.cate_beauty_p_1},{name:'foundation',image:imageImport.cate_beauty_p_2},{name:'maskaara',image:imageImport.cate_beauty_p_3},{name:'kajal',image:imageImport.cate_beauty_p_4},{name:'lip balm',image:imageImport.cate_beauty_p_13},{name:'facepack',image:imageImport.cate_beauty_p_6},{name:'touchup',image:imageImport.cate_beauty_p_7},{name:'lotion',image:imageImport.cate_beauty_p_8},{name:'shampoo',image:imageImport.cate_beauty_p_9},{name:'eyeliners',image:imageImport.cate_beauty_p_10},{name:'blusher',image:imageImport.cate_beauty_p_11},{name:'facecare',image:imageImport.cate_beauty_p_12}]},
    {category_name:'furniture',category_image:[{name:'sofas',image:imageImport.cate_furniture_p_1},{name:'office',image:imageImport.cate_furniture_p_2},{name:'home chair',image:imageImport.cate_furniture_p_3},{name:'beds',image:imageImport.cate_furniture_p_4},{name:'stool',image:imageImport.cate_furniture_p_11},{name:'living',image:imageImport.cate_furniture_p_6},{name:'beach',image:imageImport.cate_furniture_p_7},{name:'chair stool',image:imageImport.cate_furniture_p_8},{name:'wooden',image:imageImport.cate_furniture_p_9},{name:'graden',image:imageImport.cate_furniture_p_10},{name:'working',image:imageImport.cate_furniture_p_5},{name:'sitting',image:imageImport.cate_furniture_p_12}]},
    {category_name:'home decors',category_image:[{name:'frames',image:imageImport.cate_decoration_p_1},{name:'vaas',image:imageImport.cate_decoration_p_2},{name:'pots',image:imageImport.cate_decoration_p_3},{name:'lighting',image:imageImport.cate_decoration_p_4},{name:'vaas set',image:imageImport.cate_decoration_p_5},{name:'outers',image:imageImport.cate_decoration_p_6},{name:'stands',image:imageImport.cate_decoration_p_7},{name:'tables',image:imageImport.cate_decoration_p_8},{name:'glass pots',image:imageImport.cate_decoration_p_9},{name:'clocks',image:imageImport.cate_decoration_p_10},{name:'lamps',image:imageImport.cate_decoration_p_14},{name:'flowers',image:imageImport.cate_decoration_p_12}]},
    {category_name:'electronics',category_image:[{name:'heaphones',image:imageImport.cate_electronics_p_1},{name:'airpods',image:imageImport.cate_electronics_p_2},{name:'mobiles',image:imageImport.cate_electronics_p_3},{name:'laptops',image:imageImport.cate_electronics_p_4},{name:'tablets',image:imageImport.cate_electronics_p_5},{name:'coolers',image:imageImport.cate_electronics_p_6},{name:'speakers',image:imageImport.cate_electronics_p_7},{name:'psp',image:imageImport.cate_electronics_p_8},{name:'earbuds',image:imageImport.cate_electronics_p_9},{name:'vr box',image:imageImport.cate_electronics_p_10},{name:'fitbits',image:imageImport.cate_electronics_p_11},{name:'watch',image:imageImport.cate_electronics_p_12}]},
    {category_name:'fruits & veggies',category_image:[{name:'cabbages',image:imageImport.cate_fruits_p_1},{name:'dhaniya',image:imageImport.cate_fruits_p_2},{name:'green',image:imageImport.cate_fruits_p_3},{name:'broccli',image:imageImport.cate_fruits_p_4},{name:'apples',image:imageImport.cate_fruits_p_5},{name:'bananas',image:imageImport.cate_fruits_p_6},{name:'tomatos',image:imageImport.cate_fruits_p_7},{name:'avocado',image:imageImport.cate_fruits_p_8},{name:'oranges',image:imageImport.cate_fruits_p_9},{name:'strawberry',image:imageImport.cate_fruits_p_10},{name:'grappes',image:imageImport.cate_fruits_p_11},{name:'kiwis',image:imageImport.cate_fruits_p_12}]},
    {category_name:'stationery',category_image:[{name:'notebooks',image:imageImport.cate_stationery_p_1},{name:'pen holder',image:imageImport.cate_stationery_p_2},{name:'colors',image:imageImport.cate_stationery_p_3},{name:'drawing',image:imageImport.cate_stationery_p_4},{name:'sketch pen',image:imageImport.cate_stationery_p_5},{name:'rubber',image:imageImport.cate_stationery_p_6},{name:'shopner',image:imageImport.cate_stationery_p_7},{name:'board',image:imageImport.cate_stationery_p_8},{name:'clips',image:imageImport.cate_stationery_p_9},{name:'paper clip',image:imageImport.cate_stationery_p_10},{name:'clip set',image:imageImport.cate_stationery_p_11},{name:'thumb pin',image:imageImport.cate_stationery_p_12}]},
    {category_name:'baby & care',category_image:[{name:'toys',image:imageImport.cate_babycare_p_11},{name:'baby bowls',image:imageImport.cate_babycare_p_2},{name:'clothes',image:imageImport.cate_babycare_p_3},{name:'baby shoes',image:imageImport.cate_babycare_p_4},{name:'baby cart',image:imageImport.cate_babycare_p_5},{name:'milk bottle',image:imageImport.cate_babycare_p_6},{name:'teddy bear',image:imageImport.cate_babycare_p_7},{name:'soft toys',image:imageImport.cate_babycare_p_8},{name:'care set',image:imageImport.cate_babycare_p_9},{name:'rubic cube',image:imageImport.cate_babycare_p_10},{name:'baby food',image:imageImport.cate_babycare_p_1},{name:'aeroplane',image:imageImport.cate_babycare_p_12},]},
    {category_name:'gym accessories',category_image:[{name:'dumbbles',image:imageImport.cate_gym_p_1},{name:'accessories',image:imageImport.cate_gym_p_2},{name:'5lbs',image:imageImport.cate_gym_p_3},{name:'10lbs ',image:imageImport.cate_gym_p_4},{name:'weights',image:imageImport.cate_gym_p_5},{name:'sippers',image:imageImport.cate_gym_p_6},{name:'yog mats',image:imageImport.cate_gym_p_7},{name:'bottles',image:imageImport.cate_gym_p_8},{name:'machines',image:imageImport.cate_gym_p_9},{name:'plates',image:imageImport.cate_gym_p_10},{name:'shoes',image:imageImport.cate_shoes_p_2},{name:'sneaker',image:imageImport.cate_shoes_p_9},]},
    {category_name:'grocery',category_image:[{name:'milk',image:imageImport.cate_grocery_p_1},{name:'panty',image:imageImport.cate_grocery_p_2},{name:'massor',image:imageImport.cate_grocery_p_3},{name:'cherry',image:imageImport.cate_grocery_p_4},{name:'ground nut',image:imageImport.cate_grocery_p_5},{name:'peas',image:imageImport.cate_grocery_p_6},{name:'rice',image:imageImport.cate_grocery_p_7},{name:'curd',image:imageImport.cate_grocery_p_8},{name:'cashews',image:imageImport.cate_grocery_p_9},{name:'oils',image:imageImport.cate_grocery_p_10},{name:'cookies',image:imageImport.cate_grocery_p_11},{name:'dry oranges',image:imageImport.cate_grocery_p_12},]},
    {category_name:'sports',category_image:[{name:'accessories',image:imageImport.cate_sports_p_1},{name:'shoes',image:imageImport.cate_sports_p_2},{name:'cosco ball',image:imageImport.cate_sports_p_3},{name:'baseball',image:imageImport.cate_sports_p_4},{name:'soccer',image:imageImport.cate_sports_p_5},{name:'cock',image:imageImport.cate_sports_p_6},{name:'skateboard',image:imageImport.cate_sports_p_7},{name:'basketball',image:imageImport.cate_sports_p_8},{name:'rugby',image:imageImport.cate_sports_p_9},{name:'cricket ball',image:imageImport.cate_sports_p_10},{name:'football',image:imageImport.cate_sports_p_11},{name:'shoes',image:imageImport.cate_shoes_p_9},]},
    {category_name:'healthcare',category_image:[{name:'capsules',image:imageImport.cate_health_p_1},{name:'multivitamin',image:imageImport.cate_health_p_2},{name:'tablets',image:imageImport.cate_health_p_3},{name:'syringe',image:imageImport.cate_health_p_4},{name:'vitamin c',image:imageImport.cate_health_p_5},{name:'omega',image:imageImport.cate_health_p_6},{name:'injections',image:imageImport.cate_health_p_7},{name:'equipments',image:imageImport.cate_health_p_8},{name:'stethoscope',image:imageImport.cate_health_p_9},{name:'cup syrup',image:imageImport.cate_health_p_10},{name:'vitamin b',image:imageImport.cate_health_p_11},{name:'painkiller',image:imageImport.cate_health_p_13},]},
    {category_name:'other',category_image:[{name:'boot',image:imageImport.cate_shoes_p_1},{name:'panty',image:imageImport.cate_hot_p_3},{name:'shirts',image:imageImport.cate_women_p_2},{name:'polo shirt',image:imageImport.cate_mens_p_5},{name:'yoga bra',image:imageImport.cate_hot_p_1},{name:'pendant',image:imageImport.cate_jewellery_p_5},{name:'shirts',image:imageImport.cate_mens_p_7},{name:'party wear',image:imageImport.cate_shoes_p_7},{name:'earings',image:imageImport.cate_jewellery_p_1},{name:'watch',image:imageImport.cate_electronics_p_12},{name:'chair stool',image:imageImport.cate_furniture_p_8},{name:'jacket',image:imageImport.cate_mens_p_12},]},
]

// app cart screen data 
const appCartData = [
    {category_name:'garments store',products:[{name:'Mens Hoodie',image:imageImport.mens_p_3},{name:'orange swim suit',image:imageImport.hot_p_4}]},
    {category_name:'mens fashion',products:[{name:'Mens Hoodie',image:imageImport.mens_p_3},{name:'New Mens Jacket',image:imageImport.mens_p_11}]},
    {category_name:'womens fashion',products:[{name:'women red top',image:imageImport.women_p_11},{name:'women blue jacket',image:imageImport.women_p_6}]},
    {category_name:'womens innerwear',products:[{name:'yellow Bikini set',image:imageImport.hot_p_13},{name:'orange swim suit',image:imageImport.hot_p_4}]},
    {category_name:'jewellery',products:[{name:'diamond ring',image:imageImport.jewellery_p_8},{name:'kundan set',image:imageImport.jewellery_p_3}]},
    {category_name:'footwear',products:[{name:'red sneakers',image:imageImport.shoes_p_3},{name:'new formal shoes',image:imageImport.shoes_p_4}]},
    {category_name:'beauty & care',products:[{name:'red velvet lipstick',image:imageImport.beauty_p_10},{name:'New aura perfume',image:imageImport.beauty_p_12}]},
    {category_name:'furniture',products:[{name:'gray wooden chair',image:imageImport.furniture_p_10},{name:'double bed',image:imageImport.furniture_p_7}]},
    {category_name:'home decors',products:[{name:'new wooden pot',image:imageImport.decoration_p_2},{name:'New chair',image:imageImport.decoration_p_5}]},
    {category_name:'electronics',products:[{name:'New Air pods',image:imageImport.electronics_p_3},{name:'New Vr box',image:imageImport.electronics_p_6}]},
    {category_name:'fruits & veggies',products:[{name:'banana',image:imageImport.fruits_p_1},{name:'watermelon',image:imageImport.fruits_p_2}]},
    {category_name:'stationery',products:[{name:'blue ball pen',image:imageImport.stationery_p_1},{name:'4 notebooks set',image:imageImport.stationery_p_10}]},
    {category_name:'baby & care',products:[{name:'baby teddy bear',image:imageImport.baby_p4},{name:'baby cycle',image:imageImport.baby_p6}]},
    {category_name:'gym accessories',products:[{name:'yoga accessories',image:imageImport.gym_p11},{name:'10 lbs weights',image:imageImport.gym_p1}]},
    {category_name:'grocery',products:[{name:'badam',image:imageImport.grocery_p10},{name:'1 ltr milk',image:imageImport.grocery_p8}]},
    {category_name:'sports',products:[{name:'badmittion racket',image:imageImport.sports_p7},{name:'baseball ball',image:imageImport.sports_p11}]},
    {category_name:'healthcare',products:[{name:'syringe',image:imageImport.health_p11},{name:'capsules',image:imageImport.health_p12}]},
    {category_name:'other',products:[{name:'New Airpods',image:imageImport.electronics_p_3},{name:'new aura perfume',image:imageImport.beauty_p_12}]},
]

// app orders screen data
const appOrdersData =[
    {category_name:'garments store',order_1:[imageImport?.mens_p_1,imageImport?.mens_p_11,imageImport?.women_p_6],order_2:[imageImport?.hot_p_8,imageImport?.women_p_8,imageImport?.mens_p_5]},
    {category_name:'mens fashion',order_1:[imageImport?.mens_p_1,imageImport?.mens_p_11,imageImport?.mens_p_10],order_2:[imageImport?.mens_p_2,imageImport?.mens_p_5,imageImport?.mens_p_8]},
    {category_name:'womens fashion',order_1:[imageImport?.women_p_1,imageImport?.women_p_4,imageImport?.women_p_6],order_2:[imageImport?.women_p_7,imageImport?.women_p_8,imageImport?.women_p_4]},
    {category_name:'womens innerwear',order_1:[imageImport?.hot_p_3,imageImport?.hot_p_5,imageImport?.hot_p_7],order_2:[imageImport?.hot_p_8,imageImport?.hot_p_13,imageImport?.hot_p_12]},
    {category_name:'jewellery',order_1:[imageImport?.jewellery_p_3,imageImport?.jewellery_p_1,imageImport?.jewellery_p_2],order_2:[imageImport?.jewellery_p_5,imageImport?.jewellery_p_8,imageImport?.jewellery_p_9]},
    {category_name:'footwear',order_1:[imageImport?.shoes_p_10,imageImport?.shoes_p_9,imageImport?.shoes_p_12],order_2:[imageImport?.shoes_p_7,imageImport?.shoes_p_6,imageImport?.shoes_p_4]},
    {category_name:'beauty & care',order_1:[imageImport?.beauty_p_10,imageImport?.beauty_p_11,imageImport?.beauty_p_12],order_2:[imageImport?.beauty_p_9,imageImport?.beauty_p_8,imageImport?.beauty_p_7]},
    {category_name:'furniture',order_1:[imageImport?.furniture_p_1,imageImport?.furniture_p_3,imageImport?.furniture_p_4],order_2:[imageImport?.furniture_p_5,imageImport?.furniture_p_6,imageImport?.furniture_p_7]},
    {category_name:'home decors',order_1:[imageImport?.decoration_p_1,imageImport?.decoration_p_2,imageImport?.decoration_p_3],order_2:[imageImport?.decoration_p_4,imageImport?.decoration_p_5,imageImport?.decoration_p_6]},
    {category_name:'electronics',order_1:[imageImport?.electronics_p_1,imageImport?.electronics_p_2,imageImport?.electronics_p_3],order_2:[imageImport?.electronics_p_4,imageImport?.electronics_p_5,imageImport?.electronics_p_6]},
    {category_name:'fruits & veggies',order_1:[imageImport?.fruits_p_1,imageImport?.fruits_p_9,imageImport?.fruits_p_7],order_2:[imageImport?.fruits_p_4,imageImport?.fruits_p_5,imageImport?.fruits_p_8]},
    {category_name:'stationery',order_1:[imageImport?.stationery_p_1,imageImport?.stationery_p_2,imageImport?.stationery_p_3],order_2:[imageImport?.stationery_p_4,imageImport?.stationery_p_5,imageImport?.stationery_p_6]},
    {category_name:'baby & care',order_1:[imageImport?.baby_p1,imageImport?.baby_p6,imageImport?.baby_p7],order_2:[imageImport?.baby_p8,imageImport?.baby_p9,imageImport?.baby_p10]},
    {category_name:'gym accessories',order_1:[imageImport?.gym_p1,imageImport?.gym_p3,imageImport?.gym_p6],order_2:[imageImport?.gym_p8,imageImport?.gym_p9,imageImport?.gym_p10]},
    {category_name:'grocery',order_1:[imageImport?.grocery_p1,imageImport?.grocery_p8,imageImport?.grocery_p3],order_2:[imageImport?.grocery_p4,imageImport?.grocery_p5,imageImport?.grocery_p6]},
    {category_name:'sports',order_1:[imageImport?.sports_p1,imageImport?.sports_p2,imageImport?.sports_p3],order_2:[imageImport?.sports_p4,imageImport?.sports_p5,imageImport?.sports_p6]},
    {category_name:'healthcare',order_1:[imageImport?.health_p1,imageImport?.health_p2,imageImport?.health_p3],order_2:[imageImport?.health_p4,imageImport?.health_p5,imageImport?.health_p6]},
    {category_name:'other',order_1:[imageImport?.shoes_p_10,imageImport?.beauty_p_11,imageImport?.furniture_p_4],order_2:[imageImport?.jewellery_p_2,imageImport?.hot_p_8,imageImport?.women_p_8]},

]

  const store_type =[
    'garments store',
    'mens fashion',
    'womens fashion',
    'womens innerwear',
    'jewellery',
    'footwear',
    'beauty & care',
    'furniture',
    'home decors',
    'electronics',
    'fruits & veggies',
    'stationery',
    'baby & care',
    'gym accessories',
    'grocery',
    'sports',
    'healthcare',
    'other',
]

    const availablecolors=[
        'black',
        'silver',
        'gray',
        'white',
        'maroon',
        'red',	
        'purple',	
        'green',	
        'lime',	
        'olive',	
        'yellow',	
        'navy',
        'blue',	
        'teal',		
        'aqua',	
        'orange',	
        'aliceblue',	
        'antiquewhite',	
        'aquamarine',	
        'azure',	
        'beige',	
        'bisque',	
        'blanchedalmond',	
        'blueviolet',	
        'brown',	
        'burlywood',		
        'cadetblue',	
        'chartreuse',		
        'chocolate',	
        'coral',	
        'cornflowerblue',	
        'cornsilk',	
        'crimson',		
        'cyan',	
        'darkblue',	
        'darkcyan',	
        'darkgoldenrod',		
        'darkgray',	
        'darkgreen',		
        'darkkhaki',	
        'darkmagenta',	
        'darkolivegreen',	
        'darkorange',	
        'darkorchid',	
        'darkred',	
        'darksalmon',	
        'darkseagreen',	
        'darkslateblue',		
        'darkslategray',	
        'darkslategrey',	
        'darkturquoise',		
        'darkviolet',	
        'deeppink',	
        'deepskyblue',		
        'dimgray',		
        'dimgrey',		
        'dodgerblue',	
        'firebrick',		
        'floralwhite',		
        'forestgreen',	
        'gainsboro',	
        'ghostwhite',	
        'gold',	
        'goldenrod',		
        'greenyellow',		
        'grey',	
        'honeydew',		
        'hotpink',		
        'indianred',	
        'indigo',		
        'ivory',	
        'khaki',	
        'lavender',	
        'lavenderblush',	
        'lawngreen',	
        'lemonchiffon',	
        'lightblue',		
        'lightcoral',	
        'lightcyan',	
        'lightgray',	
        'lightgreen',		
        'lightpink',	
        'lightsalmon',		
        'lightseagreen',	
        'lightskyblue',	
        'lightsteelblue',	
        'lightyellow',	
        'limegreen',		
        'magenta',	
        'pink',	
        'plum',	
        'powderblue',	
        'rosybrown',		
        'royalblue',	
        'saddlebrown',		
        'salmon',	
        'sandybrown',	
        'seagreen',	
        'seashell',	
        'sienna',	
        'skyblue',	
        'slateblue',	
        'slategray',	
        'slategrey',	
        'snow',	
        'springgreen',	
        'steelblue',	
        'tan',	
        'thistle',	
        'tomato',	
        'turquoise',	
        'violet',	
        'wheat',				
          ]

const appTemplates=[
    'template 1',
    'template 2',
    'template 3',
    'template 4',
    'template 5',
]

//====== current app time =======
function currentTime() {
    let date = new Date(); 
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();
    let session = "AM";
  
    if(hh == 0){
        hh = 12;
    }
    if(hh > 12){
        hh = hh - 12;
        session = "PM";
     }
  
     hh = (hh < 10) ? "0" + hh : hh;
     mm = (mm < 10) ? "0" + mm : mm;
     ss = (ss < 10) ? "0" + ss : ss;
     
     let curTime = hh + ":" + mm +" " + session;
     setTime(curTime)
    //  console.log(time);
    let t = setTimeout(function(){ currentTime() }, 1000);
  }
  useEffect(()=>{

        currentTime();
  },[time])
  //====== current app time =======
  
 
//================ GET APP DETAILS= =============
    useEffect(()=>{
        setLoading(true)
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/adiogent/users/app/details/${authState?.user?.app_id}`, {headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
          },withCredentials:true})
        .then(res=>{
            console.log("res?.data.res?.data,res?.data",res?.data?.response?.app_logo)
            setAppDetails(res?.data?.response)
            if(!res?.data?.response?.app_logo && !res?.data?.response?.app_name && !res?.data?.response?.app_type){
                 setAppDetails(prev=>({...prev,app_color:'purple',app_type:'garments store',app_template:'template 3'}))
                 handleOpenVideoModal()
            }
            setFile(res?.data?.response?.app_logo)
            setLoading(false)
            
        })
        .catch(err=>{
            console.log(err);
        })
    },[render])

    console.log("filefilefilefilefilefilefilefilefilefile",file)

//############################# SAVE AND CANCEL MODAL FUNCTION #############################
const handleCloseSaveAndCancelModal=()=>{
    setOpenDeleteConfimModal(false); 
    // setIsOpen2(false)
  }
  //############################# SAVE AND CANCEL MODAL FUNCTION #############################


//   ============= HANDLE SUBMIT STORE MODAL FUNCTION =============
const handleOpenSubmitModal =async(e)=>{
    e.preventDefault()
    setOpenDeleteConfimModal(true)

}
//   ============= HANDLE SUBMIT STORE MODAL FUNCTION =============

//   ============= HANDLE SUBMIT STORE DETAILS FUNCTION =============
const handleSubmitAppDetails=async()=>{
    setButtonLoading(true)
    // ==== upload logo check ====
    // if(!file){
    //     toast.error("Upload Your App Logo!!")
    //     handleCloseSaveAndCancelModal(false)
    //     setButtonLoading(false)
    //     return
    // }
    // if(appDetails?.app_name != '' && appDetails?.app_color != '' && appDetails?.app_type != ''
    //  && appDetails?.address != '' && appDetails?.email != '' && file != null ){
    if(appDetails?.app_name != '' && appDetails?.app_color != '' && appDetails?.app_type != '' && appDetails?.app_template != ''
     && appDetails?.address != '' && appDetails?.email != ''  ){

    

         let logoImageToFirebase;
         if(file && !file?.image_url){
                if(appDetails?.app_logo){
                    deleteImageFromFirebase(appDetails?.app_logo?.path,appDetails?.app_logo?.image_name)
                }
             logoImageToFirebase= await uploadFileToFirebase(`/${process.env.REACT_APP_IMAGES_FOLDER_NAME}/logo/${authState?.user?.app_id}/`,file)
             // console.log(logoImageToFirebase,"logoImageToFirebase")
            }
            let data = {
                app_name:appDetails?.app_name,
                app_color:appDetails?.app_color,
                app_type:appDetails?.app_type,
                app_template:appDetails?.app_template,
                email:appDetails?.email,
                address:appDetails?.address,
                app_logo:logoImageToFirebase ? logoImageToFirebase :  appDetails?.app_logo,

            }
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/submit/app/details/adiogent/user/${authState?.user?.app_id}/${authState?.user?._id}`,{...data},{headers: {
                'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
              },withCredentials:true})
            .then(res=>{
                // console.log(res?.data)
                if(res?.data?.app_already_exists === true){
                    setMessage((prev)=>({...prev,type:"error",message:"App Name Already Exists!!"}))
                    setSnackbarOpen(true);
                    handleCloseSaveAndCancelModal(false)
                    setButtonLoading(false)
                    return
                }
                if(res?.data?.creditExpired === true){
                     toast.error('0 Credit Left!!');
                    handleCloseSaveAndCancelModal(false)
                    setButtonLoading(false)
                    setRender(prev=>!prev)
                    setOpenCreditExpireErrorModal(true)
                    
                }
                if(res?.data?.status === true){
                    handleOpenSubmitDetailsSuccessPopupModal()
                    getStepperDetails()
                    toast.success('Submitted Successfully!!');
                    setAppDetails({app_name:'',email:'',app_type:'',app_color:'',address:''})
                    // setFile(null)
                    handleCloseSaveAndCancelModal(false)
                    setButtonLoading(false)
                    setRender(prev=>!prev)
                    fetchAuthuser()
                }
                if(res?.data?.status === false){
                toast.error("Something went wrong !!")
                }
            })
            .catch(err=>{
                console.log(err)
                toast.error("Something went wrong !!")
            })

          
            
        }
        else{
            console.log("error")
            toast.error("Something went wrong !!")
            handleCloseSaveAndCancelModal(false)
            setButtonLoading(false)
       }
   


}
//   ============= HANDLE SUBMIT STORE DETAILS FUNCTION =============


//   ============= HANDLE FILE UPLOAD FUNCTION =============
const handleFileChange = (file) => {
    if(file?.length > 1)return toast.error("Only 1 Logo");
    if(file[0]?.type?.split('/')[0] != 'image')return toast.error('Upload Only image!!');
    setFile(file[0]);
    toast.success('Logo Uploaded successfully!!');
  };
//   ============= HANDLE FILE UPLOAD FUNCTION =============

//========= handleformDetil ========
// const handleChange=(e)=>{
//     setAppDetails
// }
// console.log("appDetails----",appDetails)
// console.log(authState)

//========= handleformDetil ========

// go to app single product screen
const handleGoToAppSingleProduct =(data)=>{
    setAppScreen('SINGLE_PRODUCT')
    console.log("datadatadatadatadatadatadatadatadatadatadata-----",data)
    setAppSingleProductScreen(data)
}

//############################# BUILD PUBLISH YOURSELF APP ERROR MODAL FUNCTION #############################
const handleCloseCreditExpireErrorModal=()=>{
    setOpenCreditExpireErrorModal(false); 
    // setIsOpen2(false)
  }
  //############################# BUILD PUBLISH YOURSELF APP ERROR MODAL FUNCTION #############################



  //############################# PAYMENT SUCCESS MODAL FUNCTION #############################
  const handleClosePaymentSuccessPopupModal=()=>{
    setOpenPaymentSuccessPopupModal(false); 
    // setIsOpen2(false)
    onYesFunction()
  }
const handleOpenPaymentSuccessPopupModal=()=>{
    setOpenPaymentSuccessPopupModal(true); 
    // setIsOpen2(false)
  }

const onYesFunction=()=>{
    setRender(prev=>!prev)
    setOpenPaymentSuccessPopupModal(false)
    // fetchAuthuser()
    // navigate('/dashboard/manage')
}
  //############################# PAYMENT SUCCESS MODAL FUNCTION #############################

  //############################# SUBMIT DETAILS SUCCESS MODAL FUNCTION #############################
  const handleCloseSubmitDetailsSuccessPopupModal=()=>{
    setOpenSubmitDetailSuccessPopupModal(false); 
    // setIsOpen2(false)
  }
const handleOpenSubmitDetailsSuccessPopupModal=()=>{
    setOpenSubmitDetailSuccessPopupModal(true); 
    // setIsOpen2(false)
  }

const onCLickGotoAppSigningYesFunction=()=>{
    setRender(prev=>!prev)
    setOpenSubmitDetailSuccessPopupModal(false)
    navigate('/dashboard/apppublish')
    // fetchAuthuser()
}
  //############################# SUBMIT DETAILS SUCCESS MODAL FUNCTION #############################


  // handle close video modal
  function handleCloseVideoModal(){
    setOpenVideoModal(false)
  }
  

  // handle open video modal
  function handleOpenVideoModal(){
    setOpenVideoModal(true)
  }
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };


  return (
    <div>
            <AppStepper/>
        {/*===== LOADING ====== */}
        <LoadingSpinner loading={loading} />
        {/*===== LOADING ====== */}
        <VideoModal title="Don't know how to start?" video_url="https://www.youtube.com/embed/fr3Zo9Z-J6o" isOpen={openVideoModal} handleClose={handleCloseVideoModal} /> 

 {/* #################### SANCKBAR MESSAGE ######################## */}
 <CustomizedSnackbars onOpen={snackbarOpen} type={message?.type} handleClose={handleCloseSnackbar}  message={message?.message} />
 
 {/* #################### SANCKBAR MESSAGE ######################## */}

 {/*===== Popup credit expire Error Modal ====== */}
 <PopupModal handleClose={handleCloseCreditExpireErrorModal}open={openCreditExpireErrorModal} data={<BuyCredit handleClose={handleCloseCreditExpireErrorModal} showConfirmPaymentModal={handleOpenPaymentSuccessPopupModal}   />} />
        {/*===== Popup  credit expire Error Modal ====== */}

 {/*===== Popup Modal  for resubmittion credit payment success====== */}
 <PopupModal handleClose={handleClosePaymentSuccessPopupModal} open={openPaymentSuccessPopupModal} data={<SuccessPayment onYes={onYesFunction} confirmBtnName='Okay, Got it' title='Congratulations!! ' message='Thank you for buying, Your resubmission credit has been increased !!'  />} />
        {/*===== Popup Modal for resubmittion credit payment success ====== */}
 {/*===== Popup Modal  for details submited payment success====== */}
 <PopupModal handleClose={handleCloseSubmitDetailsSuccessPopupModal} open={openSubmitDetailSuccessPopupModal} data={<BuildSuccess onYes={onCLickGotoAppSigningYesFunction} confirmBtnName='Go To App Publish' title='Congratulations!! ' message='Thank you, Your app will setup successfully. Now you can publish your app from app publish!!'  />} />
        {/*===== Popup Modal for details submited payment success ====== */}

        {/*=============== SAVE AND CANCEL MODAL============= */}
        <SaveAndCancelModal open={openDeleteConfimModal} title="Submit App Details" cancelBtnName='Discard' saveBtnName='Yes, i want' onYes={handleSubmitAppDetails} loading={buttonLoading} message="Are You Sure You Want To Submit?" handleClose={handleCloseSaveAndCancelModal}  />

        {/*=============== SAVE AND CANCEL MODAL============= */}
        <div className='price_plan_and_checkout mobile_app_page'>

<div className='currentplan_and_allplan store_setting_box'>
    <div className='currentplan setup_store_and_publishing_details'>
        <div className='store_setting_box_with_text'>
            <h4 className='card_heading'>Customize Your App</h4>
            <div className='currentplan_box setup_store_text'>
                <div className='currentplan_img_and_details'>
                    <div className='currentplan_img'>
                        <img src={Setting_icon} alt="" />
                    </div>
                    <div className='currentplan_details'>
                        <h4>Work with us.</h4>
                        <h2>Just create & Launch</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='publishing_details'>
            <h4 className='card_heading'>Read This</h4>
            <div className='note_box setup_store_text'>
                    <div className='currentplan_details publish_notice'>
                        <h4>Note:</h4>
                        <h2>Please customize your app carefully, After submitting the details you loose 1 resubmission credit.</h2>
                        {/* <h2>After submitting the details, your app will take up to 24 - 48 business hours to create your AAB.</h2> */}
                    </div>
            </div>
        </div>
    </div>
    {/* <iframe
  src="https://appetize.io/embed/kp2cs5s5dwxeqrsheudw5bvepi"
  width="378px" 
  height="800px" 
  frameborder="0" 
  scrolling="no"></iframe> */}

    <form onSubmit={handleOpenSubmitModal}  className='all_plan '>
     <div className="app_setting_form_heading_box" >
     <h4 className='card_heading-submit-detail'>Your App Details</h4>
    <Button variant="text" onClick={handleOpenVideoModal} className='need_help_btn' startIcon={<Iconify sx={{width:'18px',height:'18px'}} icon="logos:youtube-icon" />}>Need Help?</Button>

     </div>
       <div className='note-and-resubmission-box' >
      <div className='note-store-form' >
      <h5>Note :</h5>
        <h5> Fill the correct details </h5>  
      </div>
     <div className='resubmition-box' >
      <h5>Resubmission</h5>  
     <h5 className='resubmition-box' style={{color:editable_config.Main_Color }} >Credit :   {authState?.user?.resubmittion_count}/{authState?.user?.resubmittion_limit}</h5>
     </div>
       </div>
        <div className='store_Setting_details_field'>
                <div className='store_Setting_two_fields'>
                    <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                        <label className='store_form_label' htmlFor="">App Name* {message?.message &&  <span className='app_name_already_exist' >App Name Already Exists</span>} </label> 
                        <TextField 
                        InputProps={{startAdornment: <InputAdornment position="start"> <PhoneAndroidIcon sx={{fontSize:20}}   /></InputAdornment>,}}
                        required
                        value={appDetails?.app_name}
                        onChange={(e)=>setAppDetails(prev=>({...prev,app_name:e.target.value}))}
                        type="text"
                        // sx={"width:100%"} 
                        
                        className='product_form_input store_setting_input_field font-capitalize-case'  
                        id="outlined-basic" name="username"  placeholder="e.g Fashion Store" variant="outlined" />
                       
                    </div>

                    {/* <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                    <label className='store_form_label' htmlFor="">App Category*</label>
                        <TextField
                        labelId="demo-select-small"
                        required
                        InputProps={{startAdornment: <InputAdornment position="start"> <CategoryIcon sx={{fontSize:20}}  /></InputAdornment>,}}
                        value={appDetails?.app_type}
                        onChange={(e)=>setAppDetails(prev=>({...prev,app_type:e.target.value}))}
                        id="demo-simple-select"
                        className='store_setting_input_field'
                        style={{textTransform:'capitalize'}}
                        select
                        >
                        {store_type.map((value,index)=>(
                        <MenuItem style={{textTransform:'capitalize'}}  value={value} >{value}</MenuItem>
                        ))}
                     </TextField>
                    </div> */}
                    <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                        <label className='store_form_label' htmlFor="">Email*</label>
                        <TextField 
                        InputProps={{startAdornment: <InputAdornment position="start"><EmailIcon sx={{fontSize:20}} /></InputAdornment>,}}
                        required
                        value={appDetails?.email}
                        onChange={(e)=>setAppDetails(prev=>({...prev,email:e.target.value}))}
                        type="email"
                        sx={"width:100%"} 
                        className='product_form_input store_setting_input_field' 
                        id="outlined-basic" name="username"  placeholder="e.g store@fashion.com" variant="outlined" />
                    </div>
                </div>
                <div className='store_Setting_two_fields'>
                <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                    <label className='store_form_label' htmlFor="">App Category*</label>
                        <TextField
                        labelId="demo-select-small"
                        required
                        InputProps={{startAdornment: <InputAdornment position="start"> <CategoryIcon sx={{fontSize:20}}  /></InputAdornment>,}}
                        value={appDetails?.app_type}
                        onChange={(e)=>setAppDetails(prev=>({...prev,app_type:e.target.value}))}
                        id="demo-simple-select"
                        className='store_setting_input_field'
                        style={{textTransform:'capitalize'}}
                        select
                        >
                        {store_type.map((value,index)=>(
                        <MenuItem style={{textTransform:'capitalize'}}  value={value} >{value}</MenuItem>
                        ))}
                     </TextField>
                    </div>

                <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                    <label className='store_form_label' htmlFor="">App Color*</label>
                        <TextField
                        labelId="demo-select-small"
                        required
                        InputProps={{startAdornment: <InputAdornment position="start"> <ColorLensIcon sx={{fontSize:20}}  /></InputAdornment>,}}
                        value={appDetails?.app_color}
                        onChange={(e)=>setAppDetails(prev=>({...prev,app_color:e.target.value}))}
                        id="demo-simple-select"
                        className='store_setting_input_field'
                        style={{textTransform:'capitalize'}}
                        select
                        >
                            {availablecolors?.map((value,index)=>(
                                <MenuItem  value={value} >
                                    <div className='choose-color-style' >
                                    <span style={{backgroundColor:`${value}`,padding:'11px 24px',borderRadius:3,marginRight:5,border:'1px solid gray'}} ></span>
                                    <p key={index} style={{textTransform:'capitalize'}} value={value}>{value}</p>
                                    </div>
                                </MenuItem>

                            ))}
                            </TextField>
                    </div>
                    {/* <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                    <label className='store_form_label' htmlFor="">App Templates*</label>
                        <TextField
                        labelId="demo-select-small"
                        required
                        InputProps={{startAdornment: <InputAdornment position="start"> <AppShortcutIcon sx={{fontSize:20}}  /></InputAdornment>,}}
                        value={appDetails?.app_template}
                        onChange={(e)=>setAppDetails(prev=>({...prev,app_template:e.target.value}))}
                        id="demo-simple-select"
                        className='store_setting_input_field'
                        style={{textTransform:'capitalize'}}
                        select
                        >
                        {appTemplates.map((value,index)=>(
                        <MenuItem style={{textTransform:'capitalize'}}  value={value} >{value}</MenuItem>
                        ))}
                     </TextField>
                    </div> */}
                    

                </div>
                <div className='store_Setting_two_fields'>
                {/* <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                        <label className='store_form_label' htmlFor="">Email*</label>
                        <TextField 
                        InputProps={{startAdornment: <InputAdornment position="start"><EmailIcon sx={{fontSize:20}} /></InputAdornment>,}}
                        required
                        value={appDetails?.email}
                        onChange={(e)=>setAppDetails(prev=>({...prev,email:e.target.value}))}
                        type="email"
                        sx={"width:100%"} 
                        className='product_form_input store_setting_input_field' 
                        id="outlined-basic" name="username"  placeholder="e.g store@fashion.com" variant="outlined" />
                    </div> */}
                <div style={{width:'100%',marginRight:'10px'}} className='add_product_label_input'>
                        <label className='store_form_label' htmlFor="">Address*</label>
                        <TextField 
                        required
                        value={appDetails?.address}
                        onChange={(e)=>setAppDetails(prev=>({...prev,address:e.target.value}))}
                        type="text"
                        InputProps={{startAdornment: <InputAdornment position="start"><BusinessCenterIcon sx={{fontSize:20}}  /></InputAdornment>,}}
                        sx={"width:100%"} 
                        className='product_form_input store_setting_input_field' 
                        id="outlined-basic" name="username"  placeholder="e.g Fashion Store, Delhi - 110006" variant="outlined" />
                    </div>
                    </div>
                 
                <div className='upload_logo_box'>
                <label className='store_form_label' htmlFor="">Upload Logo (Optional)</label>
                <FileUploader
                  handleChange={handleFileChange}
                  name="file"
                  type={fileTypes}
                  className="file-upload"
                  multiple={true}
                  children={<LogoFileUploadDesgin uploadedImage={ file && !file?.image_url ?  URL?.createObjectURL(file) :
                    appDetails?.app_logo?.image_url ? appDetails?.app_logo?.image_url :
                   storelogo2 } />}
                />
                </div>


        </div>
    <div className='add_product_label_input' >
    <Button type='submit' className='product-btn' style={{padding:'14px 30px'}} variant="contained" startIcon={<Iconify icon="ic:baseline-check-circle" />}>Submit  Details</Button>
        </div>        
    </form>
    
</div>
{/* <div className='plan_choose_and_checkout status_checker_box'>
    <div className='status_of_app_and_dashboard'>
        <div className='app_and_dashboard_box'>
        <div className='heading_and_status'>
            <div className='status_and_heading'>
                <span className='coming_soon'>Coming Soon</span>
                <h4 className='card_heading'>Your Mobile App</h4>
            </div>
            <div className='check_link'>
                <p>View App ➡</p>
            </div>
        </div>
        <div className='dashboard_box mobile_app'>
            <img src={mobileapp} alt="" />
        </div>

        <div className='divider divider_of_status_box'></div>

        <div className='heading_and_status'>
            <div className='status_and_heading'>
                <span className='project_live'>Live</span>
                <h4 className='card_heading'>Your Dashboard</h4>
            </div>
            <div className='check_link'>
                <p>View Dashboard ➡</p>
            </div>
        </div>

        <div className='dashboard_box'>
            <img src={dashboard} alt="" />
        </div>
        </div>
    </div>

    
</div> */}
<div className='plan_choose_and_checkout status_checker_box store_setting'>
<p className='app_preview_glow_text' >App Preview</p>

{/*========= APP TEMPALTE 1 ========== */}
    {appDetails?.app_template == 'template 1' && 
            <AppTemplate1
            setAppScreen={setAppScreen}
            appScreen={appScreen}
            time={time}
            appHomeScreenCategoryData={appHomeScreenCategoryData}
            appproductData={appproductData}
            appDetails={appDetails}
            appOrdersData={appOrdersData}
            appBannerData={appBannerData}
            appCartData={appCartData}
            appCategoryScreenData={appCategoryScreenData}
            appSingleProductScreen={appSingleProductScreen}
            handleGoToAppSingleProduct={handleGoToAppSingleProduct} />

}

{/*=========== APP TEMPALTE 1 ============*/}
{/*========= APP TEMPALTE 2 ========== */}
    {appDetails?.app_template == 'template 2' && 
            <AppTemplate2
            setAppScreen={setAppScreen}
            appScreen={appScreen}
            time={time}
            appHomeScreenCategoryData={appHomeScreenCategoryData}
            appproductData={appproductData}
            appDetails={appDetails}
            appOrdersData={appOrdersData}
            appBannerData={appBannerData}
            appCartData={appCartData}
            appCategoryScreenData={appCategoryScreenData}
            appSingleProductScreen={appSingleProductScreen}
            handleGoToAppSingleProduct={handleGoToAppSingleProduct} />

}

{/*=========== APP TEMPALTE 2 ============*/}
{/*========= APP TEMPALTE 3 ========== */}
    {appDetails?.app_template == 'template 3' && 
            <AppTemplate3
            setAppScreen={setAppScreen}
            appScreen={appScreen}
            time={time}
            appHomeScreenCategoryData={appHomeScreenCategoryData}
            appproductData={appproductData}
            appDetails={appDetails}
            appOrdersData={appOrdersData}
            appBannerData={appBannerData}
            appCartData={appCartData}
            appCategoryScreenData={appCategoryScreenData}
            appSingleProductScreen={appSingleProductScreen}
            handleGoToAppSingleProduct={handleGoToAppSingleProduct} />

}

{/*=========== APP TEMPALTE 3 ============*/}
{/*========= APP TEMPALTE 4 ========== */}
    {appDetails?.app_template == 'template 4' && 
            <AppTemplate4
            setAppScreen={setAppScreen}
            appScreen={appScreen}
            time={time}
            appHomeScreenCategoryData={appHomeScreenCategoryData}
            appproductData={appproductData}
            appDetails={appDetails}
            appOrdersData={appOrdersData}
            appBannerData={appBannerData}
            appCartData={appCartData}
            appCategoryScreenData={appCategoryScreenData}
            appSingleProductScreen={appSingleProductScreen}
            handleGoToAppSingleProduct={handleGoToAppSingleProduct} />

}

{/*=========== APP TEMPALTE 4 ============*/}
{/*========= APP TEMPALTE 5 ========== */}
    {appDetails?.app_template == 'template 5' && 
            <AppTemplate5
            setAppScreen={setAppScreen}
            appScreen={appScreen}
            time={time}
            appHomeScreenCategoryData={appHomeScreenCategoryData}
            appproductData={appproductData}
            appDetails={appDetails}
            appOrdersData={appOrdersData}
            appBannerData={appBannerData}
            appCartData={appCartData}
            appCategoryScreenData={appCategoryScreenData}
            appSingleProductScreen={appSingleProductScreen}
            handleGoToAppSingleProduct={handleGoToAppSingleProduct} />

}

{/*=========== APP TEMPALTE 5 ============*/}
    
</div>

</div>
    </div>
  )
}

export default StoreSetting