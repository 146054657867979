import * as React from "react";
import { useState, useRef, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  Toolbar,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Button,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

import axios from "axios";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import LoadingSpinner from "../components/Spinner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Link, useNavigate } from "react-router-dom";
import OrderListToolbar from "../sections/@dashboard/orders/OrderListToolbar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Iconify from "../components/Iconify";
import { DateRangePicker } from "react-date-range";
import searchNotFound from "../assests/searchnotfound.gif";
import { addDays } from "date-fns";
import {
  convertDateForOrder,
  copyContentFunc,
  getGapBetweenDates,
} from "../global/globalFunctions";
import SideDrawer from "../global/Drawer";
import ViewOrder from "./SidebarPages/orderpage/ViewOrder";
import ConfimModal from "../global/Modals/ConfimModal";
import CsvDownloadButton from "react-json-to-csv";
import palette from "src/theme/palette";
import { editable_config } from "src/editable_config";
import CustomizedSnackbars from "src/global/Snackbar/CustomSnackbar";
import VideoModal from "src/global/Modals/VideoModal";
import noImage from "../assests/No_image.svg";
import Productcard from "src/components/offlineBilling/Productcard";
import ManageProductCard from "src/components/offlineBilling/ManageProductCard";
import ViewInvoiceOfflineBilling from "src/components/offlineBilling/ViewInvoiceOfflineBilling";
// const orderid = require("order-id")("key");
// const border_id = orderid.generate();

// import orderid from "order-id";
// const border_id = orderid("key").generate()

function createData(name, calories, fat, carbs, protein, amount, status) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    amount,
    status,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, "COD", 40, "pending"),
  createData("Donut", 452, 25.0, 51, "COD", 100, "cancel"),
  createData("Eclair", 262, 16.0, 24, "COD", 100, "delivered"),
  createData("Frozen yoghurt", 159, 6.0, 24, "COD", 100, "delivered"),
  createData("Gingerbread", 356, 16.0, 49, "COD", 10, "pending"),
  createData("Honeycomb", 408, 3.2, 87, "COD", 100, "pending"),
  createData("Ice cream sandwich", 237, 9.0, 37, "COD", 100, "pending"),
  createData("Jelly Bean", 375, 0.0, 94, "COD", 100, "processing"),
  createData("KitKat", 518, 26.0, 65, "COD", 20, "processing"),
  createData("Lollipop", 392, 0.2, 98, "COD", 100, "processing"),
  createData("Marshmallow", 318, 0, 81, "COD", 100, "pending"),
  createData("Nougat", 360, 19.0, 9, "COD", 100, "cancel"),
  createData("Oreo", 437, 18.0, 63, "COD", 100, "cancel"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "prodimg",
    disablePadding: false,
    label: "Image/Thumb",
    align: "left",
  },

  {
    id: "products",
    disablePadding: false,
    label: "Product Name",
    align: "left",
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
    align: "left",
  },

  {
    id: "Add Product",
    disablePadding: false,
    label: "Add Product",
    align: "right",
  },
];

const manageProductHeadCells = [
  {
    id: "prodimg",
    disablePadding: false,
    label: "Image/Thumb",
    align: "left",
  },

  {
    id: "products",
    disablePadding: false,
    label: "Product Name",
    align: "center",
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
    align: "center",
  },

  {
    id: "Quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "center",
  },

  {
    id: "Remove Product",
    disablePadding: false,
    label: "Remove Product",
    align: "right",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "none"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ textTransform: "uppercase" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function ManageProductEnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {manageProductHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "none"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ textTransform: "uppercase" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const ref2 = useRef(null);
  const [isOpen2, setIsOpen2] = useState(false);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {`All Orders (${props.orderCount})`}
        </Typography>
      )}

      <Tooltip title="Filter list">
        <>
          {numSelected > 0 && (
            // <Tooltip title="More">
            <IconButton>
              <MoreVertOutlinedIcon
                style={{ cursor: "pointer" }}
                ref={ref2}
                onClick={() => setIsOpen2(true)}
                fontSize="medium"
              />
              <Menu
                open={isOpen2}
                anchorEl={ref2.current}
                onClose={() => setIsOpen2(false)}
                PaperProps={{
                  sx: { width: 200, maxWidth: "100%" },
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem
                  sx={{ color: "text.secondary" }}
                  onClick={() => {
                    props.setOpenDeleteConfimModal(true);
                    setIsOpen2(false);
                  }}
                >
                  <ListItemIcon>
                    <Iconify
                      icon="eva:trash-2-outline"
                      width={24}
                      height={24}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Delete Order"
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </MenuItem>
              </Menu>
            </IconButton>
            // </Tooltip>
          )}
        </>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function OfflineBilling() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);

  const [filterName, setFilterName] = useState("");
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = useState("");
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [drawerEditOrders, setDrawerEditOrders] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderStatusFilter, setOrderStatusFilter] = useState([]);
  const [orderIdForEdit, setOrderIdForEdit] = useState("");
  const [ordersCount, setOrdersCount] = useState(0);
  const [allOrders, setAllOrders] = useState();
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [filters, setFilters] = useState({
    by_status: "all",
    recentDays: "All",
  });
  const [age, setAge] = React.useState("");
  const [openDeleteConfimModal, setOpenDeleteConfimModal] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const [orderDetail, setOrderDetail] = useState({});

  const currentDate = new Date().toLocaleString();

  const [searchProduct, setSearchProduct] = useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [productCount, setProductCount] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit, setLimit] = React.useState(5);

  const searchProductFunc = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/all/products/pos?page=1&search=${searchValue}&limit=${limit}`,
        {
          headers: {
            Authorization: `token ${editable_config.FRONTEND_VALIDATOR}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        setSearchProduct([...res?.data?.allProducts]);
        setProductCount(res?.data?.getProductsCount || 0);
        setTotalPages(res?.data?.pages);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadmore = async (page) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/all/products/pos?page=${
          page + 1
        }&search=${searchValue}&limit=${limit}`,
        {
          headers: {
            Authorization: `token ${editable_config.FRONTEND_VALIDATOR}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
        setSearchProduct([...searchProduct, ...res?.data?.allProducts]);
        setProductCount(res?.data?.getProductsCount || 0);
        setTotalPages(res?.data?.pages);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [addedProduct, setAddedProd] = useState([]);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    pin: "",
    address: "",
    online: true,
  });

  console.log("addedProduct =>>>>>>>>", addedProduct);

  const pdfExportComponent = useRef(null);

  const createOrderCreate = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/website/front/create/new/order/offline/billing`;

      const res = axios.post(
        url,
        { addedProduct, userData },
        { withCredentials: true }
      );

      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleExportWithComponent = async (event) => {
    await createOrderCreate();

    pdfExportComponent.current.save();
  };

  return (
    <>
      <LoadingSpinner loading={loading} />

      <div className="custom-conatiner offlineBilling">
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Paper className="fullArea" elevation={3}>
            <div
              className="flex-justify-between "
              style={{
                width: "100%",
                paddingRight: 20,
                paddingLeft: 20,
                paddingTop: 25,
                paddingBottom: 10,
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {`Offline Billing`}
              </Typography>
              <Button
                className="add_banner_btn"
                variant="contained"
                style={{ width: "20%" }}
                component="label"
                startIcon={<VerticalAlignBottomIcon />}
                onClick={handleExportWithComponent}
              >
                Generate Bill
              </Button>
            </div>

            <div className="dataMain">
              <div className="formData">
                <h4>User Detail</h4>

                <form className="formUser">
                  <div
                    style={{ width: "100%", paddingTop: 0 }}
                    className="add_product_label_input"
                  >
                    <FormControlLabel
                      checked={userData.online}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return {
                            ...prev,
                            online: !userData.online,
                          };
                        })
                      }
                      control={<Switch />}
                      label="Cash / Online"
                    />
                  </div>
                  <div
                    style={{ width: "100%", paddingTop: 0 }}
                    className="add_product_label_input"
                  >
                    <label htmlFor="">Customer Name </label>
                    <TextField
                      required
                      fullWidth
                      className="product_form_input"
                      id="outlined-basic"
                      name="username"
                      placeholder="Customer Name "
                      variant="outlined"
                      value={userData.name}
                      onChange={(e) => {
                        setUserData((prev) => {
                          return { ...prev, name: e.target.value };
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="add_product_label_input"
                  >
                    <label htmlFor="">Customer Email </label>
                    <TextField
                      required
                      fullWidth
                      className="product_form_input"
                      id="outlined-basic"
                      name="username"
                      placeholder="Customer Email "
                      variant="outlined"
                      value={userData.email}
                      onChange={(e) => {
                        setUserData((prev) => {
                          return { ...prev, email: e.target.value };
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="add_product_label_input"
                  >
                    <label htmlFor="">Customer Phone Number </label>
                    <TextField
                      required
                      fullWidth
                      className="product_form_input"
                      id="outlined-basic"
                      name="username"
                      placeholder="Customer Phone Number "
                      variant="outlined"
                      value={userData.phone}
                      onChange={(e) => {
                        setUserData((prev) => {
                          return { ...prev, phone: e.target.value };
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="add_product_label_input"
                  >
                    <label htmlFor="">Customer Country/State </label>
                    <TextField
                      required
                      fullWidth
                      className="product_form_input"
                      id="outlined-basic"
                      name="username"
                      placeholder="Customer Country/State "
                      variant="outlined"
                      value={userData.country}
                      onChange={(e) => {
                        setUserData((prev) => {
                          return { ...prev, country: e.target.value };
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="add_product_label_input"
                  >
                    <label htmlFor="">Customer Postcode/ZIP</label>
                    <TextField
                      required
                      fullWidth
                      className="product_form_input"
                      id="outlined-basic"
                      name="username"
                      placeholder="Customer Postcode/ZIP"
                      variant="outlined"
                      value={userData.pin}
                      onChange={(e) => {
                        setUserData((prev) => {
                          return { ...prev, pin: e.target.value };
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="add_product_label_input"
                  >
                    <label htmlFor="">Customer Address</label>
                    <TextField
                      required
                      fullWidth
                      className="product_form_input"
                      id="outlined-basic"
                      name="username"
                      placeholder="Customer Address"
                      variant="outlined"
                      value={userData.address}
                      onChange={(e) => {
                        setUserData((prev) => {
                          return { ...prev, address: e.target.value };
                        });
                      }}
                    />
                  </div>
                </form>
              </div>

              <div className="addProduct">
                <h4>All Product ({productCount})</h4>

                <div className="products">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setCurrentPage(1);
                      searchProductFunc();
                    }}
                    style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <div
                      style={{
                        width: "100%",
                        paddingTop: 0,
                      }}
                      className="add_product_label_input"
                    >
                      <label htmlFor="">Enter Product Name/Code </label>
                      <TextField
                        required
                        fullWidth
                        className="product_form_input"
                        id="outlined-basic"
                        name="username"
                        placeholder="Product Name/Code"
                        variant="outlined"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                      />
                    </div>
                    <Button
                      sx={{ paddingY: 2, paddingX: 5 }}
                      variant="contained"
                      type="submit"
                    >
                      Search
                    </Button>
                  </form>

                  <div className="productsListing">
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 800 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          rowCount={orderDetail?.products?.length}
                        />
                        <TableBody>
                          {searchProduct?.map((row, index) => {
                            return (
                              <Productcard
                                key={row?._id}
                                data={row}
                                setAddedProd={setAddedProd}
                                addedProduct={addedProduct}
                              />
                            );
                          })}
                          {!searchProduct.length > 0 && (
                            <TableCell colSpan={9}>
                              <div className="search-not-found">
                                <img
                                  className="search-not-found-img"
                                  src={searchNotFound}
                                  alt="searchNotFound"
                                />
                                <Typography
                                  variant="h6"
                                  id="tableTitle"
                                  component="div"
                                >
                                  {searchValue?.length <= 0
                                    ? "Search Product"
                                    : "Product Not Found"}
                                </Typography>
                              </div>{" "}
                            </TableCell>
                          )}
                        </TableBody>
                      </Table>

                      {totalPages > currentPage ? (
                        <div
                          className="loadmore"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                        >
                          <Button
                            onClick={async () => {
                              await setCurrentPage(currentPage + 1);
                              loadmore(currentPage);
                            }}
                          >
                            Load More
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>

            <div className="manageProducts">
              <div className="productsListing">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 800 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <ManageProductEnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      rowCount={orderDetail?.products?.length}
                    />
                    <TableBody>
                      {addedProduct?.map((row, index) => {
                        return (
                          <ManageProductCard
                            key={index}
                            data={row}
                            setAddedProd={setAddedProd}
                            addedProduct={addedProduct}
                          />
                        );
                      })}
                      {!addedProduct.length > 0 && (
                        <TableCell colSpan={9}>
                          <div className="search-not-found">
                            <img
                              className="search-not-found-img"
                              src={searchNotFound}
                              alt="searchNotFound"
                            />
                            <Typography
                              variant="h6"
                              id="tableTitle"
                              component="div"
                            >
                              Add Product{" "}
                            </Typography>
                          </div>{" "}
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div
              className="mainInvoice"
              style={{
                background: "#fff",
                marginTop: "40px",
                paddingBottom: 50,
              }}
            >
              <PDFExport
                scale={0.6}
                ref={pdfExportComponent}
                margin={10}
                paperSize="a4"
              >
                <ViewInvoiceOfflineBilling
                  userData={userData}
                  orderDetail={addedProduct}
                  // border_id={border_id}
                />
              </PDFExport>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 22,
                }}
              >
                <Button
                  onClick={handleExportWithComponent}
                  variant="contained"
                  startIcon={<Iconify icon="basil:invoice-solid" />}
                >
                  Download Invoice
                </Button>
              </div>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  );
}
