
import React,{useState,useRef} from 'react'
import {MenuItem,OutlinedInput,Chip,InputLabel,Checkbox,ListItemText,FormControl,Select, InputAdornment, Tooltip, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from '../../../global/Snackbar/CustomSnackbar';
import Iconify from 'src/components/Iconify';
import {Button,IconButton} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import VideoModal from 'src/global/Modals/VideoModal';
import QRCode from "react-qr-code";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { editable_config } from 'src/editable_config';

function QrCodeGenerator() {
    const [qrCodeValue, setQrCodeValue] = useState('')
    const [showAppQR, setShowAppQR] = useState(false)
    const [message ,setMessage] = useState({type:"",message:""})
    const [ loading, setLoading  ] =useState(false)
    const [snackbarOpen,setSnackbarOpen ] = useState(false)
    const [ render, setRender ] = useState(false)
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const {authState} = UseContextState()
    const navigate = useNavigate()
    const pdfExportComponent = useRef(null);

    const handleExportWithComponent = event => {
      pdfExportComponent.current.save();
    };

    // ##################### SNACK BAR FUNCTIONs ##################
const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };
  // ##################### SNACK BAR FUNCTIONs ##################

    // handle close video modal
    function handleCloseVideoModal(){
        setOpenVideoModal(false)
      }
      
    
      // handle open video modal
      function handleOpenVideoModal(){
        setOpenVideoModal(true)
      }
const handleDisplayAppQR = ()=>{
    setShowAppQR(true)
}
  return (
    <div className='custom-conatiner'>
    <VideoModal title='Learn How To Create App QR?' video_url="https://www.youtube.com/embed/IvKHkM17rvQ" isOpen={openVideoModal} handleClose={handleCloseVideoModal} /> 

          {/* #################### LOADING SPINNER ######################## */}
      <Backdrop
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    {/* #################### LOADING SPINNER ######################## */}

        {/* #################### SANCKBAR MESSAGE ######################## */}
        <CustomizedSnackbars onOpen={snackbarOpen} type={message?.type} handleClose={handleCloseSnackbar}  message={message?.message} />
 
 {/* #################### SANCKBAR MESSAGE ######################## */}
 <div className="banner-image-guide-box order_page_video order_view_page_video">
 <Button onClick={handleOpenVideoModal} variant="text" className='image-guide-btn-text' startIcon={<Iconify icon="logos:youtube-icon" />}>Learn How it Works</Button>
</div>
<Paper elevation={4} >
<div className='product-conatiner'>
<div className='inner-page-heading-box' >
        <IconButton sx={{color:'black'}}  onClick={()=>navigate(-1)} >
        <Iconify icon="material-symbols:arrow-back-rounded" />
        </IconButton>
     <div>
     <h2>QR Code Generator</h2>
      {/* <p>  Add your products for your app</p> */}
     </div>
      </div>
      <div className="qr_code_main_box">
      <div className='qr_inner_box' >
     <div className='flex'>
      <div className='qr_code_feild' >
               <label className='invoice_input_label'>Enter Your App Share Link</label>
                <TextField  fullWidth value={qrCodeValue} onChange={(e)=>setQrCodeValue(e.target.value)}  placeholder='https://play.google.com/store/apps/details?id=com.yourapp' type='text'   className='invoice_input_fields' variant="outlined" />
        </div>
        <Button onClick={handleDisplayAppQR} disabled={qrCodeValue ? false : true} variant="contained" sx={{px:5,py:1.9,ml:1.5}}  startIcon={<Iconify icon="fluent:qr-code-28-regular" />}>Generate</Button>
      </div>
      {showAppQR &&
          <div>
          <div>
          <PDFExport scale={0.6} ref={pdfExportComponent}  margin={10} paperSize='a4'  >
           <QRCode 
            size={256}
           value={qrCodeValue}
           />
            </PDFExport>
           </div>
           <Button  variant="contained"  onClick={handleExportWithComponent}  startIcon={<Iconify icon="material-symbols:download" />}>Download QR</Button>
     
          </div>

      }
   
     </div>
      </div>
</div>
</Paper>
        
    </div>
  )
}

export default QrCodeGenerator