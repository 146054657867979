import React, { useState,useCallback,useEffect, useRef } from 'react'
import {Avatar, Button, Typography} from '@mui/material';
import Iconify from 'src/components/Iconify';
import planimg from '../assests/shapes.png'
import Radio from '@mui/material/Radio';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import useRazorpay from "react-razorpay";
import LoadingSpinner from 'src/components/Spinner';
import palette from 'src/theme/palette';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PopupModal from 'src/global/Modals/PopupModal';
import SuccessPayment from './modal_components/SuccessPayment';
import { useNavigate } from 'react-router-dom';
import { editable_config } from 'src/editable_config';


function PlanDetails() {
    const {authState,fetchAuthuser}= UseContextState()
    const [selectedPlan, setSelectedPlan] = useState();
    const [planDetails, setPlanDetails] = useState()
    const [selectedRadioBtn, setSelectedRadioBtn] = React.useState('3 month');
    const [ openPopupModal, setOpenPopupModal ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ render, setRender ] = useState(false)
    const Razorpay = useRazorpay();
    const navigate = useNavigate()
    const scrollRef = useRef()

    const planName = authState?.user?.plan_details?.plan_name 
    const date1 = new Date()
    const date2 = new Date(authState?.user?.plan_details?.renew_date)
    const daysLeft = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10); 



const contentForPlans={
    plan_1:{
        content1:'Up To 500 Product Upload',
        content2:'Payment Integration + COD',
        content3:'OTP Verification',
        content4:'1 Core CPU',
        content5:'512 MB RAM',
    },
    plan_2:{
        content1:'Up To 1000 Product Upload',
        content2:'Payment Integration + COD',
        content3:'OTP Verification',
        content4:'1.5 Core CPU',
        content5:'1GB RAM',
    },
    plan_3:{
        content1:'Unlimited Product Upload',
        content2:'Payment Integration + COD',
        content3:'OTP Verification',
        content4:'2 Core CPU',
        content5:'2GB RAM',
    },
}

    const getPlanOneDetails = async()=>{
        setLoading(true)
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/adiogents/plan/details`,{headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
          },withCredentials:true})
        .then(async res=>{
            console.log(" await setSelectedPlan(res.data?.plan_details?.plan_1)",res?.data)
            await setPlanDetails(res.data?.plan_details)
            //   await setSelectedPlan(res.data?.plan_details?.plan_1)
            if(authState?.user?.plan_details?.plan_name === 'free') {
                setSelectedPlan(res.data?.plan_details?.plan_1)
            }
            if(authState?.user?.plan_details?.plan_name === res.data?.plan_details?.plan_1?.name) {
                setSelectedPlan(res.data?.plan_details?.plan_1)
            }
            if(authState?.user?.plan_details?.plan_name === res.data?.plan_details?.plan_2?.name) {
                setSelectedPlan(res.data?.plan_details?.plan_2)
            }
            if(authState?.user?.plan_details?.plan_name === res.data?.plan_details?.plan_3?.name) {
                setSelectedPlan(res.data?.plan_details?.plan_3)
            }

              setLoading(false)
            
        })
        .catch(err=>{
            setLoading(false)
            console.log(err)
            // dispatch({type:"ERROR",payload:"Not Authenticated"})
        })
    }


    // get all plan detail 
    useEffect(()=>{
        getPlanOneDetails()
    },[render])

    // console.log("planDetails",planDetails)
    // console.log( "selectedMonth",selectedMonth)
    // console.log( "selectedRadioBtn",selectedRadioBtn)
    console.log( "selectedPlan",selectedPlan)
    // console.log( "AUTH STATE PLAN DETAILS",authState)
    // handle selected plan 
    const handleSelectPlan = (value)=>{
        console.log(value)
        setSelectedPlan(value)
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const handleChangeMonth = (event) => {
        // console.log(event);
        setSelectedRadioBtn(event.target.value);
      };


 
    //========= HANDLE PAY NOW BUTTON CLICK ==========
 const handleSubmitAndCheckout = useCallback(async(choosedPlan,duration) => {
    setLoading(true)
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/calulate/plan/price/for/razorpay/payment`,{duration:duration,selected_plan:choosedPlan},{headers: {
        'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
      },withCredentials:true})
    .then(res=>{
        // console.log(res?.data)
        if(res?.data?.status == true){
        setLoading(false)
        const options = {
        key:`${res?.data?.razorpay_key_id}`,
        amount: res?.data?.response.amount,
        currency: res?.data?.response.currency,
        name: "Adiogent Pvt Ltd",
        description: "Adiogent Transaction",
        // image: "https://example.com/your_logo",
        order_id: res?.data?.response.id,
        handler: async(razorpayData) => {
        //   console.log("resresresresresresresresres",razorpayData);
        setLoading(true)
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/razorpay/payment/plan/purchased/from/adiogent/success/${authState?.user?.app_id}`,{...razorpayData,...res?.data?.buying_plan},{headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
          },withCredentials:true})
          .then(res=>{
            console.log(res?.data)
            if(res?.data?.status ===true){
                setLoading(false)
                setRender(prev=>!prev)
                handleOpenPopupModal()
                
            }
          })
          .catch(err=>{
            console.log(err)
            setLoading(false)
          })
        },
        prefill: {
          name: "",
          email: authState?.user?.email,
          contact: authState?.user?.phone_number,
        },
        theme: {
          color: palette.primary.main,
        },
      };
  
      const rzpay = new Razorpay(options);
      rzpay.open()
        }
    })
    .catch(err=>{
        console.log(err);
        setLoading(false)
    })


  }, [Razorpay]);
//========= HANDLE PAY NOW BUTTON CLICK ==========

  //############################# PAYMENT SUCCESS MODAL FUNCTION #############################
const handleClosePopupModal=()=>{
    setOpenPopupModal(false); 
    // setIsOpen2(false)
    onYesFunction()
  }
const handleOpenPopupModal=()=>{
    setOpenPopupModal(true); 
    // setIsOpen2(false)
  }

const onYesFunction=()=>{
    fetchAuthuser()
    navigate('/dashboard/manage')
}
  //############################# PAYMENT SUCCESS MODAL FUNCTION #############################


  return (
    <div>
          {/*===== LOADING ====== */}
          <LoadingSpinner loading={loading} />
        {/*===== LOADING ====== */}
        {/*===== Popup Modal ====== */}
        <PopupModal handleClose={handleClosePopupModal}open={openPopupModal} data={<SuccessPayment onYes={onYesFunction} confirmBtnName='Go To Dashboard' title='Congratulations!! ' message='Thank you, your plan has been upgraded !!'  />} />
        {/*===== Popup Modal ====== */}
       
        <div className='price_plan_and_checkout current_plan_and_checkout_page'>
            <div className='currentplan_and_allplan'>
                <div className='currentplan'>
                    <h4 className='card_heading'>Current Plan</h4>
                    
                        <div className='currentplan_box'>
                            <div className='currentplan_img_and_details'>
                                <div className='currentplan_img'>
                                    <img src={planimg} alt="" />
                                </div>
                                <div className='currentplan_details'>
                                    <h4 className='font-capitalize-case' >{authState?.user?.plan_details?.plan_name} Plan</h4>
        {/* <Typography  variant="h6" >
        {daysLeft == 1 ? `Expired Tomorrow!!` : daysLeft == 0  ? `Expired Today!!` : daysLeft < 0 ? `Expired!!` :`Expired Within ${daysLeft } Day!!` } 
    </Typography> */}


            { (planName !='free' && daysLeft < 6) ? 
         <Typography  variant="h5" >
         {daysLeft == 1 ? `Expired Tomorrow!!` : daysLeft == 0  ? `Expired Today!!` : daysLeft < 0 ? `Expired!!` :`Expired Within ${daysLeft } Day!!` } 
             </Typography>
        :
             <h4><span className='font-capitalize-case' > For {authState?.user?.plan_details?.duration?.name}</span></h4>
        // <Typography variant="h5" color={'#212b36'} className='font-capitalize-case' >
        //   👋 Hi {authState?.user?.app_name} User, Welcome back
        // </Typography> 
        }


                                    {/* <h4><sup>₹</sup>0<span> Lifetime</span></h4> */}
                                </div>
                            </div>
                            {/* <div className='renew_btn'>
                                <button  >Renew Plan</button>
                            </div> */}
                        </div>

                        
                </div>

                <div className='all_plan'>
                    <h4 className='card_heading'>Choose Plan To Upgrade</h4>

                    <div className='allplan_cards'>
                        <div className= {selectedPlan?.name === planDetails?.plan_1?.name ? 'pricecard_box first_price_card  selected-payment ' : 'pricecard_box first_price_card' } >
                            <h5 className='font-capitalize-case' >{planDetails?.plan_1?.name} Plan</h5>
                            <h2><sup>₹</sup>{planDetails?.plan_1?.month_3?.price}<span>/month</span></h2>
                            <ul>
                                <li>{contentForPlans.plan_1.content1}</li>
                                <li>{contentForPlans.plan_1.content2}</li>
                                <li>{contentForPlans.plan_1.content3}</li>
                                <li>{contentForPlans.plan_1.content4}</li>
                                <li>{contentForPlans.plan_1.content5}</li>
                            </ul>
                            {authState?.user?.plan_details?.plan_name == planDetails?.plan_2?.name || authState?.user?.plan_details?.plan_name == planDetails?.plan_3?.name ? 
                           
                           <Button disabled
                        //    onClick={()=>handleSelectPlan(planDetails?.plan_1)} 
                           className='product-btn' variant="contained"
                            startIcon={<Iconify icon="icon-park-outline:buy" />}>Choose Plan</Button>

                            :
                            <Button 
                            onClick={()=>handleSelectPlan(planDetails?.plan_1)} 
                            className='product-btn' variant="contained"
                             startIcon={<Iconify icon="icon-park-outline:buy" />}>Choose Plan</Button>
                        
                        } 
                           
                           
                        </div>

                        <div className={selectedPlan?.name === planDetails?.plan_2?.name ? 'pricecard_box second_price_card selected-payment ' : 'pricecard_box second_price_card' } >
                        <h5 className='font-capitalize-case' >{planDetails?.plan_2?.name} Plan</h5>
                            <h2><sup>₹</sup>{planDetails?.plan_2?.month_3?.price}<span>/month</span></h2>
                            <ul>
                            <li>{contentForPlans.plan_2.content1}</li>
                                <li>{contentForPlans.plan_2.content2}</li>
                                <li>{contentForPlans.plan_2.content3}</li>
                                <li>{contentForPlans.plan_2.content4}</li>
                                <li>{contentForPlans.plan_2.content5}</li>
                            </ul>

                            {authState?.user?.plan_details?.plan_name == planDetails?.plan_3?.name ?
                             <Button disabled
                            //  onClick={()=>handleSelectPlan(planDetails?.plan_2)} 
                              className='product-btn' variant="contained"
                              startIcon={<Iconify icon="icon-park-outline:buy" />}>Choose Plan</Button>
                        
                            :
                            <Button disabled={authState?.user?.plan_details?.plan_name == planDetails?.plan_3?.name ? true : false } 
                            onClick={()=>handleSelectPlan(planDetails?.plan_2)}  className='product-btn' variant="contained"
                             startIcon={<Iconify icon="icon-park-outline:buy" />}>Choose Plan</Button>

                        }
                           
                        </div>

                        <div className={selectedPlan?.name === planDetails?.plan_3?.name ? 'pricecard_box first_price_card selected-payment ' : 'pricecard_box first_price_card' } >
                        <h5 className='font-capitalize-case' >{planDetails?.plan_3?.name} Plan</h5>
                            <h2><sup>₹</sup>{planDetails?.plan_3?.month_3?.price}<span>/month</span></h2>
                            <ul>
                            <li>{contentForPlans.plan_3.content1}</li>
                                <li>{contentForPlans.plan_3.content2}</li>
                                <li>{contentForPlans.plan_3.content3}</li>
                                <li>{contentForPlans.plan_3.content4}</li>
                                <li>{contentForPlans.plan_3.content5}</li>
                            </ul>
                            <Button onClick={()=>handleSelectPlan(planDetails?.plan_3)}  className='product-btn' variant="contained" startIcon={<Iconify icon="icon-park-outline:buy" />}>Choose Plan</Button>
                        </div>
                    </div>


                    
                </div>
            </div>

            <div className='plan_choose_and_checkout' ref={scrollRef} >
                <h4 className='card_heading'>Checkout</h4>
                <div className='choose_plan_period_box'>
                    <div className='all_plan_periods'>
                        <div className='choose_plan_features'>
                        <h4 className='card_heading'>Choose A Period</h4>    
                        </div>    
                        <div className='plan_period_option'>
                            <div className='choose_plan_detail'>
                                <div className='radio_btn_and_plan'>
                                {/* <input type="radio" /> */}
                                <Radio
                                size='small'
                                    checked={selectedRadioBtn === '1 month'}
                                    onChange={handleChangeMonth}
                                    value='1 month'
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '1 MONTH' }}
                                />
                                <p>1 month plan</p>
                                </div>
                            </div>
                            <div className='choose_plan_price'>
                                <p><sup>₹</sup>{selectedPlan?.month_1?.price}<span className='choose_plan_month_number_text'>x1</span></p>
                            </div>
                        </div>

                        <div className='plan_period_option'>
                            <div className='choose_plan_detail'>
                                <div className='radio_btn_and_plan'>
                                {/* <input type="radio" /> */}
                                <Radio
                                size='small'
                                    checked={selectedRadioBtn === '3 month'}
                                    onChange={handleChangeMonth}
                                    value='3 month'
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '3 MONTH' }}
                                />
                                <p>3 month plan</p>
                                </div>
                            </div>
                            <div className='choose_plan_price'>
                                <p><sup>₹</sup>{selectedPlan?.month_3?.price}<span className='choose_plan_month_number_text'>x3</span></p>
                            </div>
                        </div>

                        <div className='plan_period_option'>
                            <div className='choose_plan_detail'>
                                <div className='radio_btn_and_plan'>
                                {/* <input type="radio" /> */}
                                <Radio
                                size='small'
                                    checked={selectedRadioBtn === '6 month'}
                                    onChange={handleChangeMonth}
                                    value='6 month'
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '6 MONTH' }}
                                />
                                <p>6 month plan</p>
                                </div>
                            </div>
                            <div className='choose_plan_price'>
                                <p><sup>₹</sup>{selectedPlan?.month_6?.price}<span className='choose_plan_month_number_text'>x6</span></p>
                            </div>
                        </div>

                        <div className='plan_total_price'>
                            <div className='prices_and_plan_detail plan_price_with_month'>
                                <p className='font-capitalize-case' >{selectedPlan?.name} - {selectedRadioBtn}</p>
                                <p><sup>₹</sup>
                                { selectedRadioBtn === '1 month' ? 
                                    parseInt(selectedPlan?.month_1?.price)  *  selectedRadioBtn[0] 
                                    :
                                    selectedRadioBtn === '3 month' ?
                                    parseInt(selectedPlan?.month_3?.price)  * selectedRadioBtn[0]
                                    :
                                    selectedRadioBtn === '6 month' ?
                                    parseInt(selectedPlan?.month_6?.price) * selectedRadioBtn[0] 
                                    : 0}
                                </p>
                            </div>
                            <div className='prices_and_plan_detail plan_discount'>
                                <p>You Saved</p>
                                <p><sup>₹</sup>{
                                selectedRadioBtn === '1 month' ? 
                                    selectedPlan?.month_1?.discount     
                                    :
                                    selectedRadioBtn === '3 month' ?
                                    selectedPlan?.month_3?.discount 
                                    :
                                    selectedRadioBtn === '6 month' ?
                                    selectedPlan?.month_6?.discount 
                                    : 0
                                
                                }</p>
                            </div>
                            <div className='prices_and_plan_detail plan_discount texes_and_fee'>
                                <p>Taxes & Fees    <Tooltip title="Taxes & fees include 18% Gst and 2% service charges." arrow >
                                        <IconButton size='small' >
                                        <Iconify icon="ion:help-circle-outline" />
                                        </IconButton>
                                        </Tooltip> 
                                </p>
                                <p><sup>₹</sup> 
                                { selectedRadioBtn === '1 month' ? 
                                    Math.round((parseInt(selectedPlan?.month_1?.price) * selectedRadioBtn[0])  *  (planDetails?.gst / 100) )
                                    :
                                    selectedRadioBtn === '3 month' ?
                                    Math.round((parseInt(selectedPlan?.month_3?.price) * selectedRadioBtn[0])  * (planDetails?.gst / 100))
                                    :
                                    selectedRadioBtn === '6 month' ?
                                   Math.round((parseInt(selectedPlan?.month_6?.price) * selectedRadioBtn[0]) * (planDetails?.gst / 100) )
                                    : 0}
                                
                                </p>
                            </div>

                            <div className='divider'></div>

                            <div className='prices_and_plan_detail plan_price_with_month final_total_price'>
                                <p>Total</p>
                                <p><sup>₹</sup>
                                {/*============ TOTAL PRICE WITH GST============ */}
                                {
                                selectedRadioBtn === '1 month' ? 
                                parseInt(selectedPlan?.month_1?.price)  *  selectedRadioBtn[0]  +  Math.round((parseInt(selectedPlan?.month_1?.price) * selectedRadioBtn[0])  *  (planDetails?.gst / 100) )
                                :
                                selectedRadioBtn === '3 month' ?
                                parseInt(selectedPlan?.month_3?.price)  * selectedRadioBtn[0] +  Math.round((parseInt(selectedPlan?.month_3?.price) * selectedRadioBtn[0])  * (planDetails?.gst / 100))
                                :
                                selectedRadioBtn === '6 month' ?
                                parseInt(selectedPlan?.month_6?.price) * selectedRadioBtn[0] +  Math.round((parseInt(selectedPlan?.month_6?.price) * selectedRadioBtn[0]) * (planDetails?.gst / 100) )
                                : 0
                                
                               }
                                {/*============ TOTAL PRICE WITH GST============ */}
                                
                                </p>
                            </div>
                            <div className='read_term_and_condition_para'>
                                <div className='term_and_condition_checkbox'>
                                {/* <input type="checkbox" /> */}
                                </div>
                                <p>By checking out you agree with our <a href={editable_config.TermAndServiceLink} target='_blank' ><span style={{color:palette.primary.main}} >Terms of Service</span></a>. We will process your personal data for the fulfillment of your order and other purposes as per our <a href={editable_config.Privacy_Policy_Link} target='_blank' ><span style={{color:palette.primary.main}} >Privacy Policy</span>.</a></p>
                            </div>
                            <div className='procced_and_checkout_button'>
                <Button onClick={()=>handleSubmitAndCheckout(selectedPlan,selectedRadioBtn)} style={{padding:'13px 20px'}} fullWidth className='product-btn' variant="contained" startIcon={<Iconify icon="wpf:security-checked" />}>Submit Secure Payment</Button>
                </div>
                            {/* <div className='read_term_and_condition_para'>
                                <div className='term_and_condition_checkbox'>
                                <input type="checkbox" />
                                </div>
                                <p>By checking out you agree with our Terms of Service. We will process your personal data for the fulfillment of your order and other purposes as per our Privacy Policy.</p>
                            </div> */}
                        </div>

                        
                    </div>
                </div>

                {/* <div className='procced_and_checkout_button'>
                <Button  className='product-btn' variant="contained" startIcon={<Iconify icon="fa-brands:telegram-plane" />}>procced & Checkout</Button>
                </div> */}
                
            </div>

        </div>
    </div>
  )
}

export default PlanDetails