import React,{useEffect} from 'react'
import {useNavigate,Navigate,useLocation,Outlet} from "react-router-dom"
import DashboardLayout from '../layouts/dashboard'
import { UseContextState } from "../global/GlobalContext/GlobalContext"

function ProtectedRoute({Component,user}) {
    const {authState} = UseContextState()
    const navigate = useNavigate()
    const location = useLocation()
    const authUser = authState.isAuthenticated;

    //====== renew plan account var =======
  const planName = authState?.user?.plan_details?.plan_name 
  const date1 = new Date()
  const date2 = new Date(authState?.user?.plan_details?.renew_date)
  const daysLeft = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10) ; 
  // const daysLeft = parseInt(authState?.user?.plan_details?.renew_date) - parseInt(authState?.user?.plan_details?.purchased_date) 
  console.log("daysLeft=>>>>",daysLeft,'planName=>',planName)
//====== renew plan account var =======
    
    // return authUser? <Outlet/>:<Navigate to="/login" state={{from:location}} replace  />

    if(authState && daysLeft < 0){
        console.log("<Navigate to=/dashboard/plandetails")
        return (
             <Navigate to="/upgrade/plan" state={{from:location}} replace  />
        )

    }else{
        console.log("<Navigate to=/login")
        return (
            authUser  ? <Outlet/>:<Navigate to="/login" state={{from:location}} replace  />
        )
    }



 

}

export default ProtectedRoute