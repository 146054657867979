import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import planimg from '../assests/shapes.png';
import storelogo from "../assests/uploadimg.png";
import storelogo2 from "../assests/uploadlogo.png";
import Setting_icon from "../assests/settings icon.png"
import LoadingSpinner from 'src/components/Spinner';
import Checkbox from '@mui/material/Checkbox';
import Setting_icon2 from "../assests/settings.png"
import { FileUploader } from "react-drag-drop-files";
import { InputAdornment,Container,TextField,Button, MenuItem, ListItemText, Select, Divider } from '@mui/material';
import { editable_config } from 'src/editable_config';
import SaveAndCancelModal from 'src/global/Modals/SaveAndCancelModal';
import FileUploadDesign from 'src/components/common/FileUploadDesign';
import { toast, ToastContainer } from 'react-toastify';
import { uploadFileToFirebase } from 'src/global/globalFunctions';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import CategoryIcon from '@mui/icons-material/Category';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import palette from 'src/theme/palette';
import PopupModal from 'src/global/Modals/PopupModal';
import { LoadingButton } from '@mui/lab';
import BuildSuccess from './modal_components/BuildSuccess';
import PublishingError from './modal_components/PublishingError';
import AppStepper from '../layouts/dashboard/AppStepper';


function AppPublish() {
  const [ openAlreadyPublishedModal, setOpenAlreadyPublishedModal ] = useState(false)
  const [ alreadyBuildCreated, setAlreadyBuildCreated ] = useState(false)
  const [ openBuildAppSuccessModal, setOpenBuildAppSuccessModal ] = useState(false)
  const [ openLetAdiogentHelpSuccessModal, setOpenLetAdiogentHelpSuccessModal ] = useState(false)
  const [ openBuildAppErrorModal, setOpenBuildAppErrorModal ] = useState(false)
  const [ openLetAdiogentHelpErrorModal, setOpenLetAdiogentHelpErrorModal ] = useState(false)
  const [ buttonLoading, setButtonLoading ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ buildBtnloading, setBuildBtnloading ] = useState(false)
  const [ render, setRender ] = useState(false)
  const [ publishYourselfStepCount, setPublishYourselfStepCount ] = useState(1);
  const [ publishYourselfTotalSteps, setPublishYourselfTotalSteps ] = useState(2);
  const [ letAdiogentHelpStepCount, setLetAdiogentHelpStepCount ] = useState(1);
  const [ letAdiogentHelpTotalSteps, setLetAdiogentHelpTotalSteps ] = useState(2);
  const [showPublishByYourself, setShowPublishByYourself] = useState(false)
  const [appDetails , setAppDetails ] = useState()
  const {authState,getStepperDetails} = UseContextState()
  const navigate = useNavigate()
  
  const CREATE_A_DEVELOPER_LINK='https://accounts.google.com/ServiceLogin?service=androiddeveloper&passive=true&continue=https%3A%2F%2Fplay.google.com%2Fconsole%2Fdeveloper%2F&_ga=2.240746627.1407979560.1685599131-1672660874.1657865775'

//================ GET APP DETAILS= =============
useEffect(()=>{
    setLoading(true)
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/adiogent/users/app/details/${authState?.user?.app_id}`,{headers: {
        'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
      },withCredentials:true})
    .then(res=>{
        console.log("app publish page -----",res?.data?.response)
        setAppDetails(res?.data?.response)
        setLoading(false)
    })
    .catch(err=>{
        console.log(err);
    })
},[render])
//================ GET APP DETAILS= =============




//=========================== ON YES APP RESUBMIT SUCCESS ======================
const handleReSubmitAppPublish=async()=>{
    setOpenAlreadyPublishedModal(false)
    await handleLetAdiogentHelpSubmitBtn()

}
//=========================== ON YES APP RESUBMIT SUCCESS ======================

//=============== LET ADIOGENT HELP SUBMIT BUTTON FUNCTIN ==================
const handleLetAdiogentHelpSubmitBtn = ()=>{
    if(appDetails?.app_name && appDetails?.app_type &&  appDetails?.app_color && appDetails?.app_color ){
        setBuildBtnloading(true)
        setTimeout(async()=>{
            let data={
                app_publish_type:'let adiogent help',
                app_details:appDetails,
                plan_details:authState?.user?.plan_details
            }
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/submit/adiogent/users/app/publish/details/${authState?.user?.app_id}`,{...data},{headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
          },withCredentials:true})
            .then(res=>{
                // console.log(res?.data)
                if(res?.data?.status){
                    setOpenLetAdiogentHelpSuccessModal(true)
                    setBuildBtnloading(false)
                    toast.success("App Details Submited!!")
                    getStepperDetails()

                }
            })
            .catch(err=>{
                console.log(err)
                setBuildBtnloading(false)
                toast.error("App Publish Details Failed!!")
            })
            // setOpenLetAdiogentHelpSuccessModal(true)
            // setBuildBtnloading(false)
            
        },1000)
    }else{
        setOpenLetAdiogentHelpErrorModal(true)
    }

}
//=============== LET ADIOGENT HELP SUBMIT BUTTON FUNCTIN ==================

//=========================== ON YES APP RE BUILD CREATE SUCCESS ======================
const handleRebuildAppCreate=async()=>{
    setAlreadyBuildCreated(false)
    await buildAppButton()

}
//=========================== ON YES APP RE BUILD CREATE SUCCESS ======================


//  =-========== build App Button function ===============
const buildAppButton = async()=>{
    // console.log("{app_name:'',email:'',app_type:'',app_color:'',address:''}")
    // {app_name:'',email:'',app_type:'',app_color:'',address:''}
    if(appDetails?.app_name && appDetails?.app_type &&  appDetails?.app_color && appDetails?.app_color){
        setBuildBtnloading(true)
        setTimeout(async()=>{
            let data={
                app_publish_type:'publish by yourself',
                app_details:appDetails,
                plan_details:authState?.user?.plan_details
            }
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/submit/adiogent/users/app/publish/details/${authState?.user?.app_id}`,{...data},{headers: {
            'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
          },withCredentials:true})
            .then(res=>{
                // console.log(res?.data)
                if(res?.data?.status){
                    setOpenBuildAppSuccessModal(true)
                    setBuildBtnloading(false)
                    toast.success("App Build Successfully!!")
                    getStepperDetails()
                }
            })
            .catch(err=>{
                console.log(err)
                setBuildBtnloading(false)
                toast.error("App Build Failed!!")
            })
            // setOpenBuildAppSuccessModal(true)
            // setBuildBtnloading(false)
            
        },4000)

    }else{
        console.log("{app_name:'',email:'',app_type:'',app_color:'',address:''}");
        setOpenBuildAppErrorModal(true)
    }

}
//  =-========== build App Button function ===============

//############################# APP ALREADY PUBLISHED MODAL FUNCTION #############################
const handleCloseAlreadyPublishedModal=()=>{
    setOpenAlreadyPublishedModal(false); 
    // setIsOpen2(false)
  }
  //############################# APP ALREADY PUBLISHED MODAL FUNCTION #############################

  //############################# APP ALREADY BUILD CREATED  MODAL FUNCTION #############################
const handleCloseAlreadyBuildCreatedModal=()=>{
    setAlreadyBuildCreated(false); 
    // setIsOpen2(false)
  }
  //############################# APP ALREADY BUILD CREATED  MODAL FUNCTION #############################

//############################# BUILD PUBLISH YOURSELF APP SUCCESS MODAL FUNCTION #############################
const handleClosePublishYourselfSuccessModal=()=>{
    setOpenBuildAppSuccessModal(false); 
    // setIsOpen2(false)
  }
  //############################# BUILD PUBLISH YOURSELF APP SUCCESS MODAL FUNCTION #############################

//############################# BUILD LET ADIOGENT APP SUCCESS MODAL FUNCTION #############################
const handleCloseLetAdiogentHelpSuccessModal=()=>{
    setOpenLetAdiogentHelpSuccessModal(false); 
    // setIsOpen2(false)
  }
  //############################# BUILD LET ADIOGENT HELP APP SUCCESS MODAL FUNCTION #############################

//############################# BUILD PUBLISH YOURSELF APP ERROR MODAL FUNCTION #############################
const handleClosePublishYourselfErrorModal=()=>{
    setOpenBuildAppErrorModal(false); 
    // setIsOpen2(false)
  }
  //############################# BUILD PUBLISH YOURSELF APP ERROR MODAL FUNCTION #############################

//############################# BUILD LET ADIOGENT HELP APP ERROR MODAL FUNCTION #############################
const handleCloseLetAdiogentHelpErrorModal=()=>{
    setOpenLetAdiogentHelpErrorModal(false); 
    // setIsOpen2(false)
  }
  //############################# BUILD LET ADIOGENT HELP APP ERROR MODAL FUNCTION #############################
console.log("appDetails----",appDetails)
console.log(authState)

//========= handleformDetil ========

// ========== PUBLISH YOURSELF NEXT BUTTON FUNC ===========
const publishYourselfNextBtn=()=>{
    if(publishYourselfStepCount == 2) return
    setPublishYourselfStepCount(publishYourselfStepCount + 1)
}
// ========== PUBLISH YOURSELF NEXT BUTTON FUNC ===========
// ========== PUBLISH YOURSELF BACK BUTTON FUNC ===========
const publishYourselfBackBtn=()=>{
    if(publishYourselfStepCount == 1) return
    setPublishYourselfStepCount(publishYourselfStepCount - 1)
}
// ========== PUBLISH YOURSELF BACK BUTTON FUNC ===========

// ========== LET ADIOGENT HELP NEXT BUTTON FUNC ===========
const letAdiogentHelpNextBtn=()=>{
    if(letAdiogentHelpStepCount == 2) return
    setLetAdiogentHelpStepCount(letAdiogentHelpStepCount + 1)
}
// ========== LET ADIOGENT HELP NEXT BUTTON FUNC ===========
// ========== LET ADIOGENT HELP BACK BUTTON FUNC ===========
const letAdiogentHelpBackBtn=()=>{
    if(letAdiogentHelpStepCount == 1) return
    setLetAdiogentHelpStepCount(letAdiogentHelpStepCount - 1)
}
// ========== LET ADIOGENT HELP BACK BUTTON FUNC ===========


  return (
    <>
    <AppStepper/>
        {/*===== LOADING ====== */}
        <LoadingSpinner loading={loading} />
        {/*===== LOADING ====== */}
            {/* ALREADY APP PUBLISHED MODAL */}
        <SaveAndCancelModal open={openAlreadyPublishedModal} title="Resubmit Warning!!" onYes={handleReSubmitAppPublish} cancelBtnName="No, I Don't"  saveBtnName='Resubmit'  loading={buttonLoading} message="Your app has been already published, Are you sure you want to resubmit?" handleClose={handleCloseAlreadyPublishedModal}  />
            {/* ALREADY APP PUBLISHED MODAL */}

            {/* ALREADY BUILD APP CREATED MODAL */}
        <SaveAndCancelModal open={alreadyBuildCreated} title="Rebuild App Warning!!" onYes={handleRebuildAppCreate} cancelBtnName="No, I Don't"  saveBtnName='Rebuild App'  loading={buttonLoading} message="Your app has been already build, Are you sure you want to rebuild again?" handleClose={handleCloseAlreadyBuildCreatedModal}  />
            {/* ALREADY BUILD APP CREATED MODAL */}

                {/*===== Popup pusblish yourself success Modal ====== */}
                <PopupModal handleClose={handleClosePublishYourselfSuccessModal}open={openBuildAppSuccessModal} data={<BuildSuccess onYes={()=>navigate('/dashboard/manage')} confirmBtnName='Go To Dashboard' title='Congratulations!!' 
                message='Thank you, Your app is started building succesfully!!, it will take 24 working hours to complete. you will receive your app to your email.
                building it multiple times will not change its queue position or priority.'
                />} />
        {/*===== Popup pusblish yourself success Modal ====== */}
                {/*===== Popup let adiogent help success Modal ====== */}
                <PopupModal handleClose={handleCloseLetAdiogentHelpSuccessModal}open={openLetAdiogentHelpSuccessModal} data={<BuildSuccess onYes={()=>navigate('/dashboard/manage')} confirmBtnName='Go To Dashboard' title='Congratulations!!' 
                message='Thank you, Your app will take 24 working hours to publish. We will receive your app publish request.
                Publishing it multiple times will not change its queue position or priority.'
                />} />
        {/*===== Popup let adiogent help success Modal ====== */}

                {/*===== Popup publish by yourself Error Modal ====== */}
                <PopupModal handleClose={handleClosePublishYourselfErrorModal}open={openBuildAppErrorModal} data={<PublishingError onYes={()=>navigate('/dashboard/appsetting')} confirmBtnName='Go To App Setting' title='App Building Failed!!'   />} />
        {/*===== Popup  publish by yourself Error Modal ====== */}

                {/*===== Popup LET ADIOGENT HELP ERROR Modal ====== */}
                <PopupModal handleClose={handleCloseLetAdiogentHelpErrorModal}open={openLetAdiogentHelpErrorModal} data={<PublishingError onYes={()=>navigate('/dashboard/appsetting')} confirmBtnName='Go To App Setting' title='App Building Failed!! '   />} />
        {/*===== Popup  LET ADIOGENT HELP ERROR Modal ====== */}

        <div className='price_plan_and_checkout'>

<div className='app-publish-page-style store_setting_box'>


    <div  >
        <Paper elevation={4} sx={{borderRadius:'20px'}} >
        <div className='app-publish-tabs' >
            <p onClick={()=>setShowPublishByYourself(false)}  className='let-adiogent-help-tab' style={ showPublishByYourself ?{borderRadius: '20px 0px 0px 0px',border:`0.5px solid ${palette.primary.main}`,color:palette.primary.main}:  {borderRadius: '20px 0px 0px 0px',border:`0.5px solid ${palette.primary.main}`,backgroundColor:palette.primary.main}  } >Let Adiogent Help You</p>
            <p onClick={()=>setShowPublishByYourself(true)}  className='publish-your-self-tab'style={showPublishByYourself ?{borderRadius: '0px 20px 0px 0px',border:`0.5px solid ${palette.primary.main}`,color:'white',backgroundColor:palette.primary.main}:  {borderRadius: '0px 20px 0px 0px',border:`0.5px solid ${palette.primary.main}`,color:palette.primary.main}} >Publish By Yourself</p>
        </div>
           {showPublishByYourself 

            ?
            <div className='app-publish-main-box' >
                 {/*====== Publish  By Yourself container ======= */}
            <h2  className='app_publish_heading'>Publish By Yourself</h2>
            <div className='app-publish-inner-main-box' >
                <div className='app-publish-inner-1-div' >
                    <div>
                    <Paper elevation={4} sx={{borderRadius:'20px'}}  >
                        <div className='innerheading-tab-box' >
                            {publishYourselfStepCount == 1 && <p className='inner-heading' style={{fontWeight:800}} >Create Google Developer Account</p> }
                            {publishYourselfStepCount == 2 && <p className='inner-heading' style={{fontWeight:800}} >Download App Build</p> }
                            <p className='inner-heading' >Step {publishYourselfStepCount}/{publishYourselfTotalSteps}</p>
                        </div>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        {/*====== FIRST STEP MAIN CONTENT ======= */}
                        {publishYourselfStepCount == 1 &&
                        <div className='inner-content-main' >
                        <p> ➞ You must own a Google Developer account to submit your mobile app to the Google Play Store.
                             Login to your Google Developer account and click on Next</p>
                        <p className='content-top-padding' > ➞  If you don’t have a developer account, 
                            follow this tutorial to  
                            <a style={{color:palette.primary.main}}  href={CREATE_A_DEVELOPER_LINK} target='_blank' >
                                <span> create your own Developer account</span></a>
                                </p>

                    </div>
                        }
                       
                         {/*====== FIRST STEP MAIN CONTENT ======= */}
                        {/*====== SECOND STEP MAIN CONTENT ======= */}
                        {publishYourselfStepCount == 2 &&
                        <div className='inner-content-main' >
                        <p> ➞ Click on the buid app button to build the .AAB file for your app.</p>
                        {/* <div style={{paddingTop:10}} >
                        <LoadingButton
                           onClick={buildAppButton}
                            loading={buildBtnloading}
                            loadingPosition="start"
                            startIcon={<Iconify icon="basil:app-store-solid" />} 
                            variant="contained"
                        >
                            <span>Build App</span>
                        </LoadingButton>
                        </div> */}

                        <p className='content-top-padding' > ➞ Once the app has been successfully build, You will receive your app file on email.</p>
                        <p className='content-top-padding' > ➞ Now, You can upload your .AAB file to your google play console account.</p>
                    </div>
                        }
                       
                         {/*====== SECOND STEP MAIN CONTENT ======= */}
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <div className='bottom-tab-next-back-btn' >
                     <Button  className='product-btn' onClick={publishYourselfBackBtn} variant="text" startIcon={<Iconify icon="material-symbols:line-start-arrow-notch-rounded" />}>Back</Button>
                    {publishYourselfStepCount === publishYourselfTotalSteps ?
                
                <div style={{paddingTop:0}} >
                <LoadingButton
                   onClick={authState?.user?.app_published ?  ()=>setAlreadyBuildCreated(true) : buildAppButton}
                    loading={buildBtnloading}
                    loadingPosition="start"
                    startIcon={<Iconify icon="basil:app-store-solid" />} 
                    variant="contained"
                >
                    <span>Build App</span>
                </LoadingButton>
                </div>
                :
                
                    <Button  className='product-btn' onClick={publishYourselfNextBtn}  variant="contained" endIcon={<Iconify icon="material-symbols:line-end-arrow-notch-rounded" />}>Next</Button>
                }
                          
                        </div>
                    </Paper>
                   
                    </div>    
                </div>
                
            </div>
            <div className='app-publish-need-help'  ><p>Need help? <a href={editable_config.App_Publish_Support_Link} target="_blank" rel="noopener noreferrer"><span style={{color:palette.primary.main,cursor:'pointer'}} >Connect with us</span></a></p></div>
 {/*====== Publish  By Yourself container ======= */}

            </div>

            :
            <div className='app-publish-main-box' >
                 {/*====== Let Adiogent Help You ======= */}

            <h2 className='app_publish_heading'>Let Adiogent Help You</h2>
            <div className='app-publish-inner-main-box' >
                <div className='app-publish-inner-1-div' >
                    <div>
                    <Paper elevation={4} sx={{borderRadius:'20px'}}  >
                        <div className='innerheading-tab-box' >
                           {letAdiogentHelpStepCount == 1 &&  <p className='inner-heading' style={{fontWeight:800}} >Create Google Developer Account</p> }
                           {letAdiogentHelpStepCount == 2 &&  <p className='inner-heading' style={{fontWeight:800}} >Provide Publishing Access</p> }
                            <p className='inner-heading' >Step {letAdiogentHelpStepCount}/{letAdiogentHelpTotalSteps}</p>
                        </div>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                       {letAdiogentHelpStepCount == 1 && 
                        <div className='inner-content-main' >
                        {/*====== LET ADIOGENT HELP STEP 1 MAIN CONTENT ======= */}
                        <p> ➞ To submit your mobile app to Google Play Store,
                             you must have a Google Developer account. 
                             If you own a Google Developer account, click on Next</p>
                        <p className='content-top-padding' > ➞  If you don’t have a developer account, 
                            follow this tutorial to 
                            <a style={{color:palette.primary.main}} href={CREATE_A_DEVELOPER_LINK} target='_blank' >
                                <span> create your own Developer account</span></a>
                                </p>
                        {/*====== LET ADIOGENT HELP STEP 1 MAIN CONTENT ======= */}
                    </div>
                       }
                       {letAdiogentHelpStepCount == 2 &&
                        <div className='inner-content-main' >
                            {/*====== LET ADIOGENT HELP STEP 2 MAIN CONTENT ======= */}
                        <p > ➞ Go to <a href="https://play.google.com/console/u/0/signup" target="_blank" rel="noopener noreferrer"><span style={{color:palette.primary.main}} >Google Play Console</span></a> and log in to your developer account.</p>
                        <p className='content-top-padding' > ➞  Click on <strong>Users and Permissions</strong> in the left side menu.
                                </p>
                        <p className='content-top-padding'  > ➞ Click on the <strong>Invite New Users</strong> Button on the right side.</p>
                        <p className='content-top-padding' > ➞  Enter <span style={{color:palette.primary.main}} >adiogentapp@gmail.com</span>  in the email address field.</p>
                        {/* <p className='content-top-padding'  > ➞ Under Account Permissions, add your desired app to grant permissions.</p> */}
                        <p className='content-top-padding' > ➞  Click on the <strong>Account Permissions</strong> tab and check the <strong>Admin (All Permissions)</strong> checkbox, click on <strong>Invite User</strong> and then click on <strong>Send Invitation</strong>.
                                </p>
                        <p className='content-top-padding'  >  ➞ Under Users and Permissions, you can check the invitation status. Note: The invitation status changes once your invitation is accepted.</p>
                        
                        <div className='read_term_and_condition_para'>
                                <div className='term_and_condition_checkbox'>
                        <p className='content-top-padding' >
                             {/* <Checkbox size='small' defaultChecked />  */}
                             By submitting this, you agree to provide publishing access to your Google Developer account and to submit the app to Google Play Store in accordance with Adiogent <a href={editable_config.TermAndServiceLink} target='_blank' ><span style={{color:palette.primary.main}} >Term & Condition</span></a> and <a href={editable_config.Privacy_Policy_Link} target='_blank' ><span style={{color:palette.primary.main}} >Privacy Policy</span></a>
                                </p>
                             </div>
                    </div>
                            
                                {/*====== LET ADIOGENT HELP STEP 2 MAIN CONTENT ======= */}
                 
                    </div>
                       }
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <div className='bottom-tab-next-back-btn' >
                     <Button  onClick={letAdiogentHelpBackBtn} className='product-btn'  variant="text" startIcon={<Iconify icon="material-symbols:line-start-arrow-notch-rounded" />}>Back</Button>
                     {letAdiogentHelpStepCount === letAdiogentHelpTotalSteps  ?
                    
                    // <Button onClick={handleLetAdiogentHelpSubmitBtn} className='product-btn'  variant="contained" startIcon={<Iconify icon="ic:baseline-check-circle" />}>Submit</Button>
                    <div style={{paddingTop:0}} >
                    <LoadingButton
                       onClick={authState?.user?.app_published ? ()=>setOpenAlreadyPublishedModal(true) : handleLetAdiogentHelpSubmitBtn}
                        loading={buildBtnloading}
                        loadingPosition="start"
                        startIcon={<Iconify icon="ion:logo-google-playstore" />} 
                        variant="contained"
                    >
                        <span>Publish My App</span>
                    </LoadingButton>
                    </div>
                    :

                    <Button onClick={letAdiogentHelpNextBtn} className='product-btn'  variant="contained" endIcon={<Iconify icon="material-symbols:line-end-arrow-notch-rounded" />}>Next</Button>
                    }
                          
                        </div>
                    </Paper>
                   
                    </div>
                </div>
            </div>
            <div className='app-publish-need-help'  ><p>Need help? <a href={editable_config.App_Publish_Support_Link} target="_blank" ><span style={{color:palette.primary.main,cursor:'pointer'}} >Connect with us</span></a> </p></div>
 {/*============= Let Adiogent Help You===== ======= */}
            
            </div>
            
           }
        </Paper>
    </div>
</div>
{/* video div */}
            <div className='app-publish-page-style video-main-box' >
            <Paper elevation={4} sx={{borderRadius:'20px'}} >
                <div className='video-box' >
                {showPublishByYourself 
                
                ?
               <div>
                 {/* PUBLISH BY YOURSELF VIDEOS */}
            <iframe className='app_publish_video' width="100%" height="517" style={{borderRadius:'20px'}}  src="https://www.youtube.com/embed/fm2sHM36Naw" allowFullScreen  title="YouTube video player" frameborder="0" ></iframe>
                 {/* PUBLISH BY YOURSELF VIDEOS */}
               </div>
               :
               <div>
                {/* LET ADIOGENT HELP VIDEOS */}
                {letAdiogentHelpStepCount == 1 &&
                    <iframe className='app_publish_video' width="100%" height="517" style={{borderRadius:'20px'}}  src="https://www.youtube.com/embed/fm2sHM36Naw" allowFullScreen  title="YouTube video player" frameborder="0" ></iframe>
    
                   }
                   {letAdiogentHelpStepCount == 2 &&
                   <iframe className='app_publish_video' width="100%" height="517" style={{borderRadius:'20px'}}  src="https://www.youtube.com/embed/b58PsHZ1ins" allowFullScreen  title="YouTube video player" frameborder="0" ></iframe>
                   }
                {/* LET ADIOGENT HELP VIDEOS */}

               </div>
                
                }
                 
              
               
                {/* <iframe className='app_publish_video' width="100%" height="517" style={{borderRadius:'20px'}}  src="https://www.youtube.com/embed/b58PsHZ1ins" allowFullScreen  title="YouTube video player" frameborder="0" ></iframe> */}
                </div>
        </Paper>
            </div>
{/* video div */}
</div>
    </>
  )
}

export default AppPublish